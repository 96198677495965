import { FC, useState, useRef, useEffect } from 'react'
import {
  Box,
  BoxProps,
  Skeleton,
  SkeletonCircle,
  Grid,
  GridProps,
  Flex,
  Text,
  Avatar,
  Tooltip,
  AspectRatio,
} from '@bounty/web-components'
import { ContentLibraryResponse } from '../../generated/backendGraphql'
import { CLOUDINARY_URL, S3_BUCKET_URL } from '../../config/env'
import { prettyViews } from '@bounty/utils'
import { PlayFillIcon } from '@bounty/icons'
import {
  ContentLibraryFavoriteButton,
  ContentLibraryItemDrawer,
} from './ContentLibraryItemDrawer'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom'

export const ContentLibraryItemLoading: FC<{ sentryRef?: any }> = ({
  sentryRef,
}) => {
  return (
    <Box ref={sentryRef}>
      <Skeleton height="400px" width="100%" mb="4" />
      <Flex justifyContent="space-between">
        <Skeleton height="20px" width="90px" />
        <SkeletonCircle size="25px" />
      </Flex>
    </Box>
  )
}

export const ContentLibraryItemsLoading: FC<{ sentryRef: any }> = ({
  sentryRef,
}) => {
  return (
    <>
      <ContentLibraryItemLoading sentryRef={sentryRef} />
      <ContentLibraryItemLoading />
      <ContentLibraryItemLoading />
      <ContentLibraryItemLoading />
      <ContentLibraryItemLoading />
      <ContentLibraryItemLoading />
      <ContentLibraryItemLoading />
    </>
  )
}

export type ContentLibraryItemProps = BoxProps & {
  item: ContentLibraryResponse['items'][0]
  onFavoriteClicked: (item: ContentLibraryResponse['items'][0]) => void
  shouldPlayVideo: boolean
}

// https://stackoverflow.com/questions/6877403/how-to-tell-if-a-video-element-is-currently-playing/6877530#6877530
const isVideoPlaying = (video: HTMLVideoElement) =>
  !!(
    video.currentTime > 0 &&
    !video.paused &&
    !video.ended &&
    video.readyState > 2
  )

export const ContentLibraryItem: FC<ContentLibraryItemProps> = ({
  item,
  onFavoriteClicked,
  shouldPlayVideo,
  ...rest
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const navigate = useNavigate()

  useEffect(() => {
    if (!videoRef.current) return

    if (shouldPlayVideo) {
      videoRef.current?.play()
    } else {
      if (isVideoPlaying(videoRef.current) === false) return

      videoRef?.current?.pause()
    }
  }, [shouldPlayVideo])

  const onOpen = () => {
    navigate(`./video/${item.id}`)
  }

  return (
    <Box position="relative" isolation="isolate" {...rest}>
      <ContentLibraryFavoriteButton
        isFavorited={item.isFavorited}
        position="absolute"
        right="10px"
        top="10px"
        zIndex={10}
        onClick={() => onFavoriteClicked(item)}
      />

      <Box
        data-testid="content-library-item"
        mb="2"
        cursor="pointer"
        onClick={onOpen}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onOpen()
          }
        }}
      >
        <AspectRatio ratio={0.56}>
          <video
            poster={`${CLOUDINARY_URL}/video_id_${item.videoId}.jpg`}
            autoPlay={false}
            loop={true}
            ref={videoRef}
            muted
            preload="none"
          >
            <source
              src={`${S3_BUCKET_URL}/${item.videoId}.mp4`}
              type="video/mp4"
            ></source>
          </video>
        </AspectRatio>
      </Box>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <PlayFillIcon mr="1" />
          <Text fontSize="xl" fontWeight="semibold">
            {prettyViews(item.viewCount)}
          </Text>
        </Flex>
        <Tooltip label={item.profileName} placement="top">
          <Avatar
            size="sm"
            name={item.profileName}
            src={`${CLOUDINARY_URL}/user_id_${item.userId}.jpg`}
          />
        </Tooltip>
      </Flex>
    </Box>
  )
}

type ContentLibraryItemsProps = GridProps & {
  items?: readonly ContentLibraryItemProps['item'][]
  loading: boolean
  hasNextPage: boolean
  error: boolean
  onLoadMore: () => void
  onFavoriteClicked: ContentLibraryItemProps['onFavoriteClicked']
}

export const ContentLibraryItemsComponent: FC<ContentLibraryItemsProps> = ({
  items,
  loading,
  hasNextPage,
  error,
  onLoadMore,
  onFavoriteClicked,
  ...rest
}) => {
  const [playVideoId, setPlayVideoId] = useState<string | null>(null)
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 200px 0px',
  })

  if (!!items && items.length === 0) {
    return <Text>No content found for search.</Text>
  }

  return (
    <>
      <Grid
        templateColumns={[
          'repeat(1, 1fr)',
          'repeat(2, 1fr)',
          'repeat(3, 1fr)',
          'repeat(4, 1fr)',
          'repeat(5, 1fr)',
        ]}
        gap={6}
        {...rest}
      >
        {!items
          ? null
          : items.map((item) => (
              <ContentLibraryItem
                shouldPlayVideo={playVideoId === item.id}
                onMouseEnter={() => setPlayVideoId(item.id)}
                onMouseLeave={() => setPlayVideoId(null)}
                onFavoriteClicked={onFavoriteClicked}
                key={item.id}
                item={item}
              />
            ))}
        {(loading || hasNextPage) && (
          <ContentLibraryItemsLoading sentryRef={sentryRef} />
        )}
      </Grid>
      <Outlet />
    </>
  )
}

export const ContentLibraryItems: FC<ContentLibraryItemsProps> = (props) => {
  const navigate = useNavigate()

  return (
    <Routes>
      <Route path="/" element={<ContentLibraryItemsComponent {...props} />}>
        <Route
          path="/video/:tikTokVideoId"
          element={
            <ContentLibraryItemDrawer
              onFavoriteClicked={props.onFavoriteClicked}
              onClose={() => navigate('/content-library')}
              isOpen
            />
          }
        />
      </Route>
    </Routes>
  )
}
