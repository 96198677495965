import { FC } from 'react'
import {
  BoxProps,
  Heading,
  Text,
  Link,
  Button,
  ContentBox,
} from '@bounty/web-components'

type SnippetPreviewProps = BoxProps

export const ConciergeInstall: FC<SnippetPreviewProps> = (props) => {
  return (
    <ContentBox position="relative" px="10" height="100%" py="8" {...props}>
      <Text
        color="green.600"
        opacity="0.6"
        fontSize="2xl"
        fontWeight="bold"
        marginBottom="2"
      >
        Option 2
      </Text>
      <Heading as="h3" size="lg" mb="6">
        Free Concierge Installation
      </Heading>
      <Button
        as={Link}
        variant="outline"
        href={'https://usemotion.com/meet/bounty-matt/bounty-onboarding'}
        _hover={{
          textDecoration: 'none',
        }}
        size="lg"
        isExternal
      >
        Contact Us
      </Button>
    </ContentBox>
  )
}
