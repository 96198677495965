import { FC, ReactNode } from 'react'
import {
  Box,
  Text,
  BoxProps,
  Link,
  LinkPropsExternal,
  LinkPropsInternal,
} from './Chakra'
import { useLocation } from 'react-router-dom'
import { AppRoute } from './types'
import { Logo, BetaLogo } from './Logo'
import { isString } from '@bounty/utils'

// Replace wildcard routes to go the base and let the child router handle the
// redirect to a certain path.
const cleanTo = (to: string) => {
  return to.replace(new RegExp(`[*]$`, 'i'), '')
}

type LeftNavItemContentsProps = { isActive: boolean; icon: ReactNode }

const LeftNavItemContents: FC<LeftNavItemContentsProps> = ({
  isActive,
  children,
  icon,
}) => {
  return (
    <Box
      width="200px"
      px="4"
      py="2"
      transition="background 250ms"
      background={
        isActive
          ? 'linear-gradient(90.68deg, #95F2DC -2.88%, #AEF197 106.42%)'
          : 'transparent'
      }
      _hover={{
        background: isActive
          ? 'linear-gradient(90.68deg, #AEF197 -2.88%, #95F2DC 106.42%)'
          : 'gray.100',
      }}
      fontWeight={isActive ? 'bold' : 'normal'}
    >
      <Text>
        <Text as="span" mr="2">
          {icon}
        </Text>{' '}
        {children}
      </Text>
    </Box>
  )
}

const LeftNavItemExternal: FC<
  LinkPropsExternal & Omit<LeftNavItemContentsProps, 'isActive'>
> = ({ icon, children, ...rest }) => {
  return (
    <Link {...rest} _hover={{ textDecoration: 'none' }}>
      <LeftNavItemContents isActive={false} icon={icon}>
        {children}
      </LeftNavItemContents>
    </Link>
  )
}

const LeftNavItemInternal: FC<
  LinkPropsInternal & Omit<LeftNavItemContentsProps, 'isActive'>
> = ({ icon, to, children, ...rest }) => {
  const location = useLocation()

  const cleanedTo = isString(to) ? cleanTo(to) : ''
  const isActive =
    to === '/'
      ? location.pathname === cleanedTo
      : location.pathname.startsWith(cleanedTo)

  return (
    <Link {...rest} to={to} _hover={{ textDecoration: 'none' }}>
      <LeftNavItemContents isActive={isActive} icon={icon}>
        {children}
      </LeftNavItemContents>
    </Link>
  )
}

export const LeftNav: FC<
  {
    logoLinkTo: string
    showBetaLogo?: boolean
    assumeSpacing?: boolean
    centerComponent?: ReactNode
    items: AppRoute[]
  } & BoxProps
> = ({ items, showBetaLogo = false, logoLinkTo, ...rest }) => {
  return (
    <Box {...rest}>
      <Box mb="40px" pl="10">
        <Link isExternal={false} to={logoLinkTo}>
          {showBetaLogo ? (
            <BetaLogo style={{ width: '97px' }} />
          ) : (
            <Logo style={{ width: '97px' }} />
          )}
        </Link>
      </Box>
      {items.map((item) => {
        return item.isExternal === true ? (
          <LeftNavItemExternal
            key={item.name}
            icon={item.icon}
            isExternal={item.isExternal}
            href={item.href}
          >
            {item.name}
          </LeftNavItemExternal>
        ) : (
          <LeftNavItemInternal
            key={item.name}
            to={item.displayPath}
            icon={item.icon}
            isExternal={item.isExternal}
          >
            {item.name}
          </LeftNavItemInternal>
        )
      })}
    </Box>
  )
}
