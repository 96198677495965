import { FC, useState } from 'react'
import {
  Flex,
  Box,
  Text,
  Button,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Tooltip,
} from '@bounty/web-components'
import { useMutationBackend } from '../../apollo/backend/hooks'
import { UpdateContentPricingMultiplierDocument } from '../../generated/backendGraphql'
import { CreatorPayoutSimulator } from './CreatorPayoutSimulator'

const styles = {
  sliderText: {
    color: '#BBBBBB',
  },
  sliderLabel: {
    right: {
      color: '#734AE8',
    },
  },
}

const CpmSlider: FC<{
  cpmValue: number
  onChange: (cpm: number) => void
}> = (props) => {
  const [sliderValue, setSliderValue] = useState(props.cpmValue)
  const [showTooltip, setShowTooltip] = useState(false)
  const TICKS = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]

  return (
    <Slider
      id="slider"
      defaultValue={props.cpmValue}
      min={5}
      max={20}
      colorScheme="darkBlack"
      value={sliderValue}
      onChange={(v) => {
        setSliderValue(v)
        props.onChange(v)
      }}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      pt={2}
      pb={3}
    >
      {TICKS.map((val, idx) => {
        return (
          <SliderMark
            key={val}
            value={val}
            mt="3"
            ml={idx === 0 ? '0' : TICKS.length - 1 === idx ? '-7' : '-2.5'}
            fontSize="md"
            color={styles.sliderText.color}
          >
            ${val}
          </SliderMark>
        )
      })}
      <SliderTrack>
        <SliderFilledTrack />
      </SliderTrack>
      <Tooltip
        hasArrow
        bg="black"
        color="white"
        placement="top"
        isOpen={showTooltip}
        label={`$${sliderValue}`}
      >
        <SliderThumb />
      </Tooltip>
    </Slider>
  )
}

export const BASE_CPM = 10

export const convertMultiplierToCpm = (multiplier: number) => {
  // e.g. 70 -->  $7.0
  // e.g. 110 -> $11
  // multiplier / base_cpm ($10 now) = cpm value to display
  return Math.round(multiplier / BASE_CPM)
}

export const convertCpmToMultiplier = (cpm: number) => {
  // $10 -> 100
  // $7 -> 70
  // $12 -> 120
  const multiplier = Math.round(cpm * BASE_CPM)
  return multiplier
}

export const InitialCpm: FC<{
  initialCurrentMultiplierValue: number
}> = (props) => {
  const cpmForStore = convertMultiplierToCpm(
    props.initialCurrentMultiplierValue,
  )
  const [currentCpm, setCurrentCpm] = useState(cpmForStore)
  const [updateContentPricing, { loading: contentPricingLoading }] =
    useMutationBackend(UpdateContentPricingMultiplierDocument)
  const isDirty = cpmForStore !== currentCpm

  return (
    <Flex flexDir="column" width="100%" data-testid="initial-cpm-box">
      <Flex flexDir="row" justifyContent={'space-between'} width="100%">
        <Box>
          <Text fontWeight={'bold'}>Starting CPM</Text>
          <Text
            fontSize="xl"
            padding="3"
            textAlign={'center'}
            backgroundColor="rgba(0,0,0,0.1)"
            borderRadius="5px"
            data-testid="initial-cpm-current-cpm-display"
          >
            ${currentCpm}
          </Text>
        </Box>
        <Button
          size="lg"
          colorScheme="darkBlack"
          isLoading={contentPricingLoading}
          disabled={!isDirty || contentPricingLoading}
          onClick={async () => {
            const multiplier = convertCpmToMultiplier(currentCpm)
            await updateContentPricing({
              variables: {
                contentPricingMultiplier: multiplier,
              },
            })
          }}
        >
          Apply Changes
        </Button>
      </Flex>
      <Flex pt={3} pb={6} width="100%" flexDirection={'column'}>
        <CpmSlider
          cpmValue={currentCpm}
          onChange={(newCpm) => {
            setCurrentCpm(newCpm)
          }}
        />

        <Flex
          flexDirection={'row'}
          width="100%"
          justifyContent={'space-between'}
          pt={10}
          pb={10}
        >
          <Text color="gray.600">Expect less content</Text>
          <Text>Default</Text>
          <Text color={styles.sliderLabel.right.color}>
            Expect more content
          </Text>
        </Flex>
      </Flex>
      <CreatorPayoutSimulator multiplier={convertCpmToMultiplier(currentCpm)} />
    </Flex>
  )
}
