import { FC, useMemo, Fragment, useCallback } from 'react'
import { CellRendererSelectorFunc, ColDef } from 'ag-grid-community'
import { useMutationBackend, useQueryBackend } from '../../apollo/backend/hooks'
import {
  CreatorsTableQuery,
  CreatorsTableDocument,
  AddFavoriteToCreatorDocument,
  RemoveFavoriteFromCreatorDocument,
} from '../../generated/backendGraphql'
import { Avatar, Text, Link, Tooltip, IconButton } from '@bounty/web-components'
import { CLOUDINARY_URL } from '../../config/env'
import { Grid } from '../../components/Grid/Grid'
import { useAnalytics } from '@bounty/hooks'
import { useAuthState } from '../../hooks/useAuth'
import { StarFillIcon, StarIcon } from '@bounty/icons'

export type CreatorsGridProps = unknown

export type CreatorItem = CreatorsTableQuery['storeCreators'][0]
type Order = CreatorsTableQuery['storeCreators'][0]['orders'][0]
type CellRendererParams = Parameters<CellRendererSelectorFunc<CreatorItem>>[0]

export const CreatorsTable: FC<CreatorsGridProps> = () => {
  const { shopifyStoreUrl } = useAuthState()
  const { track } = useAnalytics()

  const [addFavoriteToCreator] = useMutationBackend(
    AddFavoriteToCreatorDocument,
  )
  const [removeFavoriteFromCreator] = useMutationBackend(
    RemoveFavoriteFromCreatorDocument,
  )

  const onFavoriteClicked = useCallback(
    async (creator: CreatorItem) => {
      const variables = {
        userId: creator.id,
      }
      if (creator.starred) {
        removeFavoriteFromCreator({
          variables,
          optimisticResponse: {
            __typename: 'Mutation',
            removeFavoriteFromCreator: true,
          },
          update(cache) {
            cache.modify({
              id: cache.identify(creator),
              fields: {
                starred() {
                  return false
                },
              },
            })
          },
        })
      } else {
        addFavoriteToCreator({
          variables,
          optimisticResponse: {
            __typename: 'Mutation',
            addFavoriteToCreator: true,
          },
          update(cache) {
            cache.modify({
              id: cache.identify(creator),
              fields: {
                starred() {
                  return true
                },
              },
            })
          },
        })
      }
    },
    [addFavoriteToCreator, removeFavoriteFromCreator],
  )

  const columnDefs = useMemo(
    (): ColDef<CreatorItem>[] => [
      {
        headerName: '',
        width: 30,
        flex: 0,
        field: 'starred',
        suppressMovable: true,
        cellRenderer: (params: CellRendererParams) => {
          return (
            <Tooltip hasArrow label="Favorite creator">
              <IconButton
                aria-label="Favorite button"
                variant="unstyled"
                minWidth="auto"
                minHeight="auto"
                _focus={{
                  boxShadow: 'none',
                }}
                height="auto"
                onClick={() => {
                  onFavoriteClicked(params.data!)
                }}
                icon={
                  params.data!.starred ? (
                    <StarFillIcon boxSize="20px" />
                  ) : (
                    <StarIcon boxSize="20px" />
                  )
                }
              />
            </Tooltip>
          )
        },
        resizable: false,
      },
      {
        headerName: '',
        field: 'cloudinaryId',
        width: 80,
        flex: 0,
        resizable: false,
        suppressMovable: true,
        cellRenderer: (params: CellRendererParams) => {
          return (
            <Avatar
              as={Link}
              name={params.data!.profileName}
              isExternal
              href={`https://www.tiktok.com/${params.data!.profileName}`}
              boxSize="40px"
              src={`${CLOUDINARY_URL}/${params.value}.jpg`}
            />
          )
        },
      },
      {
        headerName: 'Creator',
        field: 'profileName',
        cellRenderer: (params: CellRendererParams) => {
          return (
            <Link
              isExternal
              colorScheme="black"
              fontWeight="medium"
              href={`https://www.tiktok.com/${params.data!.profileName}`}
              textDecoration="underline"
              onClick={() => {
                track('Creator Table Profile Name Link Clicked', {
                  tikTokUsername: params.data!.profileName,
                })
              }}
            >
              {params.data!.profileName}
            </Link>
          )
        },
      },
      {
        headerName: 'Name',
        field: 'name',
        valueFormatter: (x) => x.value ?? '--',
      },
      {
        headerName: 'Orders',
        field: 'orders',
        cellRenderer: (params: CellRendererParams) => {
          if (params.value.length === 0) return 'N/A'
          return params.value.map((order: Order, i: number) => {
            return (
              <Fragment key={order.id}>
                <Link
                  isExternal
                  textDecoration="underline"
                  href={`https://${shopifyStoreUrl}/admin/orders/${order.shopifyOrderId}`}
                  onClick={() => {
                    track('User Table Order Clicked', {
                      link: `https://${shopifyStoreUrl}/admin/orders/${order.shopifyOrderId}`,
                    })
                  }}
                >
                  {order.shopifyOrderNumber}
                </Link>
                {i < params.value.length - 1 ? <Text as="span">, </Text> : null}
              </Fragment>
            )
          })
        },
      },

      {
        headerName: 'Bounties',
        field: 'numBounties',
      },
      {
        headerName: 'Rating',
        field: 'averageRating',
        valueFormatter: (x) => x.value ?? 'N/A',
      },
      {
        headerName: 'Sign up date',
        field: 'createdAt',
        cellRenderer: (params: CellRendererParams) => {
          return params.data!.oldestOrderDate
            ? new Date(params.data!.oldestOrderDate).toLocaleDateString()
            : new Date(params.data!.createdAt).toLocaleDateString()
        },
      },
    ],
    [track, shopifyStoreUrl, onFavoriteClicked],
  )

  const { data: { storeCreators = [] } = {} } = useQueryBackend(
    CreatorsTableDocument,
  )

  return (
    <Grid<CreatorItem> rowData={[...storeCreators]} columnDefs={columnDefs} />
  )
}
