import { FC } from 'react'
import { Box, BoxProps, Heading, HeadingProps } from './Chakra'
import { Helmet } from 'react-helmet-async'
import { useBounty } from './BountyProvider'

export type PageProps = {
  /**
   * Makes page extend full width of the viewport
   *
   * @default false
   */
  fluid?: boolean

  /**
   * Adds some spacing to the bottom of the page for the content to breath against the
   * bottom viewport
   *
   * @default true
   */
  addBottomSpacing?: boolean

  /**
   * Adds some spacing to the top of the page for the content to breath against the
   * top viewport
   *
   * @default true
   */
  addTopSpacing?: boolean

  /**
   * Name of the page used for title page and page analytics
   */
  name: string

  /**
   * Make the layout fixed height and flexbox based for things like dynamic height scrolling
   */
  fixedHeight?: 'desktopOnly' | 'all' | 'none'
  'data-testid'?: string
} & BoxProps

export const Page: FC<PageProps> = ({
  children,
  fluid,
  addBottomSpacing = true,
  addTopSpacing = true,
  name,
  fixedHeight = 'none',
  ...rest
}) => {
  const { appName, topNavHeight, mobileNavHeight } = useBounty()

  return (
    <>
      <Helmet defer={false} title={appName} titleTemplate={`%s | ${appName}`}>
        <title>{name}</title>
      </Helmet>
      <Box
        data-element-name="page"
        px={['1rem', '2rem', '3rem', '4rem']}
        width="100%"
        marginBottom={addBottomSpacing ? '6rem' : '0'}
        pt={addTopSpacing ? '2.5rem' : '0'}
        {...(fluid
          ? {}
          : {
              maxWidth: '1200px',
              mx: 'auto',
            })}
        {...(fixedHeight !== 'none'
          ? {
              display: 'flex',
              flexDirection: 'column',
              flex: '1',
              minHeight: '0',
              height:
                fixedHeight === 'desktopOnly'
                  ? [`auto`, `auto`, `auto`, `calc(100vh - ${topNavHeight})`]
                  : [
                      `calc(100vh - ${mobileNavHeight})`,
                      `calc(100vh - ${mobileNavHeight})`,
                      `calc(100vh - ${mobileNavHeight})`,
                      `calc(100vh - ${topNavHeight})`,
                    ],
            }
          : {})}
        {...rest}
      >
        {children}
      </Box>
    </>
  )
}

export type PageHeaderProps = HeadingProps

export const PageHeader: FC<PageHeaderProps> = ({ children, ...rest }) => {
  return (
    <Heading as="h1" size="lg" mb="6" {...rest}>
      {children}
    </Heading>
  )
}
