import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ProductSnippetInstaller } from './ProductPlacement/ProductSnippetInstaller'
import { SnippetsOverview } from './SnippetsOverview'
import { SnippetCustomize } from './SnippetCustomize'

export type SnippetsProps = unknown

export const Snippets: FC<SnippetsProps> = () => {
  return (
    <Routes>
      <Route path="overview" element={<SnippetsOverview />} />
      <Route path="/:placement/customize" element={<SnippetCustomize />} />
      <Route path="product/install" element={<ProductSnippetInstaller />} />
      <Route element={<Navigate to="overview" replace />} path="*" />
    </Routes>
  )
}
