export type BountyPlanCode =
  | 'V2_ENTERPRISE_PLAN'
  | 'V2_PRO_PLAN'
  | 'V2_BASIC_PLAN'

export const BOUNTY_FEATURES = [
  {
    name: 'UGC Automation',
    code: 'UGC_AUTOMATION',
    availableOn: ['V2_ENTERPRISE_PLAN', 'V2_PRO_PLAN', 'V2_BASIC_PLAN'],
  },
  {
    name: 'Tax Compliance and Management',
    code: 'TAX_COMPLIANCE_AND_MANAGEMENT',
    availableOn: ['V2_ENTERPRISE_PLAN', 'V2_PRO_PLAN', 'V2_BASIC_PLAN'],
  },
  {
    name: 'FTC Compliance',
    code: 'FTC_COMPLIANCE',
    availableOn: ['V2_ENTERPRISE_PLAN', 'V2_PRO_PLAN', 'V2_BASIC_PLAN'],
  },
  {
    name: 'Spark Ads Manager',
    code: 'SPARK_ADS_MANAGER',
    availableOn: ['V2_ENTERPRISE_PLAN', 'V2_PRO_PLAN', 'V2_BASIC_PLAN'],
  },
  {
    name: 'Content Management',
    code: 'CONTENT_MANAGEMENT',
    availableOn: ['V2_ENTERPRISE_PLAN', 'V2_PRO_PLAN', 'V2_BASIC_PLAN'],
  },
  {
    name: 'Automated Order SMS Flow',
    code: 'AUTOMATED_ORDER_SMS_FLOW',
    availableOn: ['V2_ENTERPRISE_PLAN', 'V2_PRO_PLAN', 'V2_BASIC_PLAN'],
  },
  {
    name: 'Notification Preferences',
    code: 'NOTIFICATION_PREFERENCES',
    availableOn: ['V2_ENTERPRISE_PLAN', 'V2_PRO_PLAN', 'V2_BASIC_PLAN'],
  },
  {
    name: 'Custom Inspiration Videos',
    code: 'CUSTOM_INSPIRATION_VIDEOS',
    availableOn: ['V2_ENTERPRISE_PLAN', 'V2_PRO_PLAN'],
  },
  {
    name: 'Auto-Tagging Content',
    code: 'AUTO_TAGGING_CONTENT',
    availableOn: ['V2_ENTERPRISE_PLAN', 'V2_PRO_PLAN'],
  },
  {
    name: 'No Branding on Widget',
    code: 'NO_BRANDING_ON_WIDGET',
    availableOn: ['V2_ENTERPRISE_PLAN', 'V2_PRO_PLAN'],
  },
  // {
  //   name: 'Embedded Video Widget',
  //   code: 'EMBEDDED_VIDEO_WIDGET',
  //   availableOn: ['V2_ENTERPRISE_PLAN', 'V2_PRO_PLAN'],
  // },
  // {
  //   name: 'Product Seeding',
  //   code: 'PRODUCT_SEEDING',
  //   availableOn: ['V2_ENTERPRISE_PLAN', 'V2_PRO_PLAN'],
  // },
  {
    name: 'Post Purchase Survey Integration',
    code: 'POST_PURCHASE_SURVEY_INTEGRATION',
    availableOn: ['V2_ENTERPRISE_PLAN'],
  },
  // {
  //   name: 'Product Launch Campaigns',
  //   code: 'PRODUCT_LAUNCH_CAMPAIGNS',
  //   availableOn: ['V2_ENTERPRISE_PLAN'],
  // },
  {
    name: 'Dedicated Customer Success Manager',
    code: 'DEDICATED_CUSTOMER_SUCCESS_MANAGER',
    availableOn: ['V2_ENTERPRISE_PLAN'],
  },
  {
    name: 'Onboarding/Implementation Manager',
    code: 'ONBOARDING_IMPLEMENTATION_MANAGER',
    availableOn: ['V2_ENTERPRISE_PLAN'],
  },
  {
    name: 'Custom Branded Hashtag',
    code: 'CUSTOM_BRANDED_HASHTAG',
    availableOn: ['V2_ENTERPRISE_PLAN'],
  },

  // {
  //   name: 'Organizational Rollup',
  //   code: 'ORGANIZATIONAL_ROLLUP',
  //   availableOn: ['V2_ENTERPRISE_PLAN'],
  // },
  // {
  //   name: 'API Access',
  //   code: 'API_ACCESS',
  //   availableOn: ['V2_ENTERPRISE_PLAN'],
  // },
  // { name: 'Bounty+', code: 'BOUNTY_PLUS', availableOn: ['V2_ENTERPRISE_PLAN'] },
] as const

export type BountyFeatureCode = typeof BOUNTY_FEATURES[number]['code']

export const isFeatureAvailableForPlan =
  (code: BountyFeatureCode) => (plan: BountyPlanCode) => {
    const maybeFeature = BOUNTY_FEATURES.find((b) => b.code === code)

    if (!maybeFeature) {
      throw new Error('Feature not found for code: ' + code)
    }

    // @ts-expect-error const assertions turn into read only arrays and it's telling us plan isn't always assignable. That's what this function tests.
    return maybeFeature.availableOn.includes(plan)
  }
