import { BoxProps } from '@bounty/web-components'
import { useParams } from 'react-router-dom'
import { useQueryBackend } from '../../../apollo/backend/hooks'
import { ProductInstructionEditDocument } from '../../../generated/backendGraphql'
import {
  formatInstructionForForm,
  ProductInstructionBase,
} from './ProductInstructionBase'

type ProductInstructionEditProps = BoxProps

export const ProductInstructionEdit = ({
  ...rest
}: ProductInstructionEditProps) => {
  const params = useParams()

  if (params.productInstructionId == null) {
    throw new Error(
      `Cannot edit product instruction if no product id is given!`,
    )
  }

  const { data, loading } = useQueryBackend(ProductInstructionEditDocument, {
    variables: {
      id: params.productInstructionId,
    },
  })

  return (
    <ProductInstructionBase
      variant={'update'}
      isDefault={false}
      isLoading={loading}
      {...(data != null
        ? {
            formProps: {
              defaultValues: formatInstructionForForm(
                data.storeProductInstruction,
              ),
            },
          }
        : {})}
      {...rest}
    />
  )
}
