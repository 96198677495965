import { BoxProps } from '@bounty/web-components'
import { ProductInstructionBase } from './ProductInstructionBase'
import {
  DEFAULT_PRODUCT_FEATURES,
  DEFAULT_PRODUCT_HEADLINE,
} from '@bounty/constants'

type ProductInstructionCreatePropsDefault = BoxProps

export const ProductInstructionCreateDefault = ({
  ...rest
}: ProductInstructionCreatePropsDefault) => {
  return (
    <ProductInstructionBase
      variant={'create'}
      isDefault={true}
      isLoading={false}
      formProps={{
        defaultValues: {
          name: 'Default',
          headline: DEFAULT_PRODUCT_HEADLINE,
          features: DEFAULT_PRODUCT_FEATURES,
        },
      }}
      {...rest}
    />
  )
}
