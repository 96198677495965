import { FC } from 'react'
import {
  Box,
  BoxProps,
  SkeletonCircle,
  Skeleton,
  Flex,
  HStack,
  Avatar,
  Text,
  Link,
  useTheme,
  VStack,
} from '@bounty/web-components'
import { useQueryBackend } from '../apollo/backend/hooks'
import {
  RecentActivityDocument,
  RecentActivityQuery,
  ActivityFeedEntryType,
} from '../generated/backendGraphql'
import { UnreachableCaseError } from '@bounty/utils'
import TimeAgo from 'react-timeago'
import { CLOUDINARY_URL } from '../config/env'

const getPrettyNameForActivityType = (type: ActivityFeedEntryType) => {
  switch (type) {
    case 'SIGNUP':
      return 'New Creator'
    case 'SPARK_CODE_COMPLETED':
      return 'New Spark Code'
    case 'BOUNTY_APPROVED':
      return 'New Bounty'
    default:
      throw new UnreachableCaseError(type)
  }
}

type RecentActivityItemProps = BoxProps & {
  activity: RecentActivityQuery['activityFeed']['activities'][0]
}

export const RecentActivityItem: FC<RecentActivityItemProps> = ({
  activity,
  ...rest
}) => {
  const theme = useTheme()

  return (
    <HStack
      width="100%"
      height="100%"
      borderBottom="1px solid"
      borderColor="gray.200"
      minHeight="90px"
      px="2"
      spacing="3"
      alignContent="center"
      {...rest}
    >
      <Box flexShrink={0}>
        <Avatar
          boxSize="40px"
          src={`${CLOUDINARY_URL}/${activity.user?.cloudinaryId}.jpg`}
        />
      </Box>
      <Box minWidth="0" flex="1" flexShrink={0}>
        {activity.bounty?.tikTokVideo?.videoUrl ? (
          <Text fontSize="sm" isTruncated>
            <Link
              fontWeight="semibold"
              textDecoration="underline"
              isExternal
              href={activity.bounty.tikTokVideo.videoUrl}
            >
              View Bounty
            </Link>
          </Text>
        ) : null}
        {activity.user?.tikTokUsername ? (
          <Text fontSize="lg" fontWeight="semibold" isTruncated>
            <Link
              color="inherit"
              isExternal
              href={`https://www.tiktok.com/${activity.user.tikTokUsername}`}
            >
              {activity.user.tikTokUsername}
            </Link>
          </Text>
        ) : null}
      </Box>
      <Flex flex="1">
        <Text fontSize="md" textAlign="center">
          {getPrettyNameForActivityType(activity.type)}
        </Text>
      </Flex>
      <Box flex="0.5">
        <TimeAgo
          // @ts-ignore
          style={{ fontSize: theme.fontSizes.xs }}
          date={activity.relevantDate}
        />
      </Box>
    </HStack>
  )
}

export const RecentActivityItemSkeleton: FC<BoxProps> = ({ ...rest }) => {
  return (
    <HStack
      borderBottom="1px solid"
      borderColor="gray.200"
      minHeight="90px"
      px="2"
      spacing="3"
      alignContent="center"
      {...rest}
    >
      <Box flexShrink={0}>
        <SkeletonCircle boxSize="12px" />
      </Box>
      <Box flexShrink={0}>
        <SkeletonCircle boxSize="40px" />
      </Box>
      <Box minWidth="0" flex="1" flexShrink={0}>
        <Skeleton mb="4" height="1rem" />
        <Skeleton width="100%" height="1rem" />
      </Box>
      <Flex flexShrink={0} flex="1">
        <Skeleton width="100%" height="1rem" />
      </Flex>
      <Box flexShrink={0} flex="0.5">
        <Skeleton width="100%" height="1rem" />
      </Box>
    </HStack>
  )
}

type RecentActivityProps = BoxProps

export const RecentActivity: FC<RecentActivityProps> = ({ ...rest }) => {
  const { data, loading } = useQueryBackend(RecentActivityDocument)

  return (
    <VStack width="100%" justifyContent="flex-start" minHeight="0">
      <Text
        width="100%"
        borderBottom="1px solid"
        borderColor="gray.300"
        fontSize="lg"
        fontWeight="semibold"
        pb={4}
      >
        Creators Stream
      </Text>
      <VStack
        width="100%"
        display="flex"
        flexDirection="column"
        minHeight="0"
        overflowY="auto"
        py="0"
        px="2"
        {...rest}
      >
        {loading || !data ? (
          <Box width="100%">
            <RecentActivityItemSkeleton />
            <RecentActivityItemSkeleton />
            <RecentActivityItemSkeleton />
            <RecentActivityItemSkeleton />
            <RecentActivityItemSkeleton />
            <RecentActivityItemSkeleton />
            <RecentActivityItemSkeleton />
            <RecentActivityItemSkeleton />
          </Box>
        ) : data.activityFeed.activities.length === 0 ? (
          <Box p="4">
            <Text>
              As creators sign up and create content you will see it appear
              here.
            </Text>
          </Box>
        ) : (
          data.activityFeed.activities.map((activity) => (
            <RecentActivityItem
              key={`${activity.relevantDate}-${activity.user?.id}-${activity.type}-${activity.bounty?.id}`}
              activity={activity}
            />
          ))
        )}
      </VStack>
    </VStack>
  )
}
