import { PageHeader, Flex, Box, PageSkeleton } from '@bounty/web-components'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Page } from '../../components/Page'
import { IndividualEmbedTogglerSwitch } from './IndividualEmbedToggler'
import { ProductSnippetPreview } from './ProductPlacement/ProductSnippetPreview'
import { ThankYouSnippetPreview } from './ThankYouPlacement/ThankYouSnippetPreview'
import { NotificationEmailPreview } from './NotificationEmailPlacement/NotificationEmailPlacementPreview'
import {
  UserExperience,
  UserExperienceButton,
  UserExperienceButtonContainer,
  UserExperienceHeading,
  UserExperienceInfoContainer,
  UserExperiencePreview,
  UserExperienceText,
} from './UserExperience'
import { SnippetOverviewDocument } from '../../generated/backendGraphql'
import { useQueryBackend } from '../../apollo/backend/hooks'

export type SnippetsOverviewProps = unknown

export const SnippetsOverview: FC<SnippetsOverviewProps> = () => {
  const { data, loading } = useQueryBackend(SnippetOverviewDocument)

  if (loading || !data) return <PageSkeleton />
  const {
    currentStore: { embeds },
  } = data

  /**
   * Note these embeds will have the same css variables appending to the document so they'll conflict
   * causing some things not to work property. The only way to fix this without altering all the variables is
   * the shadow dom
   */
  const productEmbed = embeds.find((e) => e.code === 'PRODUCT') || {
    configuration: { meta: {} },
  }
  const thankYouEmbed = embeds.find((e) => e.code === 'THANKYOU') || {
    configuration: { meta: {} },
  }

  return (
    <Page
      name="User Experiences"
      alignItems="center"
      data-testid="user-experiences-page"
    >
      <Flex alignItems="center" mb="8">
        <PageHeader mb="0" mr="6">
          User Experiences
        </PageHeader>
      </Flex>
      <Box>
        <UserExperience>
          <UserExperienceInfoContainer>
            <UserExperienceHeading>Product Page</UserExperienceHeading>
            <UserExperienceText>
              Our highest visibility placement. Maximize the number of creators
              you activate, and help convert potential creators who haven’t yet
              decided to buy.
            </UserExperienceText>
            <Box>
              <IndividualEmbedTogglerSwitch
                prettyName="Product Page Widget"
                code={'PRODUCT'}
              />
            </Box>
            <UserExperienceButtonContainer>
              <UserExperienceButton as={Link} to="../product/install">
                Installation Guide
              </UserExperienceButton>
              <UserExperienceButton
                as={Link}
                to="../product/customize"
                variant="outline"
                ml="4"
              >
                Customize
              </UserExperienceButton>
            </UserExperienceButtonContainer>
          </UserExperienceInfoContainer>
          <UserExperiencePreview>
            <ProductSnippetPreview
              p={['2', '2', '2', '4']}
              configProps={productEmbed.configuration?.meta}
            />
          </UserExperiencePreview>
        </UserExperience>
        <UserExperience>
          <UserExperienceInfoContainer>
            <UserExperienceHeading>Thank You Page</UserExperienceHeading>
            <UserExperienceText>
              Only visible to customers after they complete a purchase. Won’t
              convert potential buyers like the product page experience can. We
              recommend using both placements together for maximum creator
              activation.
            </UserExperienceText>
            <Box>
              <IndividualEmbedTogglerSwitch
                prettyName="Thank You Page Widget"
                code={'THANKYOU'}
              />
            </Box>
            <UserExperienceButtonContainer>
              <UserExperienceButton
                as={'a'}
                target="_blank"
                rel="noopener noreferrer"
                href="https://bountyapp.notion.site/Thank-You-Page-dca3224a2dd34ef88db0c4466d8bd904"
              >
                Installation Guide
              </UserExperienceButton>
              <UserExperienceButton
                as={Link}
                to="../thank-you/customize"
                variant="outline"
                ml="4"
              >
                Customize
              </UserExperienceButton>
            </UserExperienceButtonContainer>
          </UserExperienceInfoContainer>
          <UserExperiencePreview>
            <ThankYouSnippetPreview
              p={['2', '2', '2', '4']}
              configProps={thankYouEmbed.configuration?.meta}
            />
          </UserExperiencePreview>
        </UserExperience>
        <UserExperience>
          <UserExperienceInfoContainer>
            <UserExperienceHeading>Landing Page</UserExperienceHeading>
            <UserExperienceText>
              A page that lives on your store that you can drive traffic to.
              This placement works best when paired with campaigns surrounding
              Bounty via email or social.
            </UserExperienceText>
            <Box>
              <IndividualEmbedTogglerSwitch
                prettyName="Landing Page"
                code={'LANDINGPAGE'}
              />
            </Box>
            <UserExperienceButtonContainer>
              <UserExperienceButton
                as={'a'}
                target="_blank"
                rel="noopener noreferrer"
                href="https://bountyapp.notion.site/Landing-Page-335d4c6c625143dfa49775dafdba5498"
              >
                Installation Guide
              </UserExperienceButton>
              <UserExperienceButton
                as={Link}
                to="../landing-page/customize"
                variant="outline"
                ml="4"
              >
                Customize
              </UserExperienceButton>
            </UserExperienceButtonContainer>
          </UserExperienceInfoContainer>
          <UserExperiencePreview>
            Click customize to see preview.
          </UserExperiencePreview>
        </UserExperience>
        <UserExperience>
          <UserExperienceInfoContainer>
            <UserExperienceHeading>Notification Emails</UserExperienceHeading>
            <UserExperienceText>
              Add Bounty to order confirmation, shipping confirmation, and other
              notifications emails. Great for converting customers that haven't
              signed up yet and reminding those that have to create a Bounty.
            </UserExperienceText>
            <UserExperienceButtonContainer>
              <UserExperienceButton
                as={'a'}
                target="_blank"
                rel="noopener noreferrer"
                href="https://bountyapp.notion.site/Shopify-Notification-Emails-98e71813104044eea15efc35124c2ea7"
              >
                Installation Guide
              </UserExperienceButton>
            </UserExperienceButtonContainer>
          </UserExperienceInfoContainer>
          <UserExperiencePreview>
            <NotificationEmailPreview p={['2', '2', '2', '4']} />
          </UserExperiencePreview>
        </UserExperience>
      </Box>
    </Page>
  )
}
