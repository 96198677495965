import { PageSkeleton } from '@bounty/web-components'
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useLazyQueryShopifyAdmin } from '../../apollo/shopify-admin/hooks'
import { ProductPickerItemProps } from '../../components/ProductPicker/ProductPicker'
import { GetCurrentProductsDocument } from '../../generated/shopifyGraphql'

export type WelcomeState = {
  budgetedAmount?: number
  productsToCreate: Array<ProductPickerItemProps['product']>
  isShopifyProductEntitiesLoading: boolean
}
const WelcomeStateContext = createContext<WelcomeState | undefined>(undefined)

export type WelcomeDispatch = {
  setWelcome: React.Dispatch<React.SetStateAction<WelcomeState>>
  setBudgetAmount: (budgetedAmount: number) => void
  setProductsToCreate: (
    productsToCreate: Array<ProductPickerItemProps['product']>,
  ) => void
}
export const WelcomeDispatchContext = createContext<
  WelcomeDispatch | undefined
>(undefined)

export type WelcomeProviderProps = unknown

export const WelcomeProvider: FC<WelcomeProviderProps> = ({ children }) => {
  const [value, setWelcome] = useState<WelcomeState>({
    productsToCreate: [],
    isShopifyProductEntitiesLoading: true,
  })
  const [query, { data: shopifyProductEntities }] = useLazyQueryShopifyAdmin(
    GetCurrentProductsDocument,
  )

  const setBudgetAmount: WelcomeDispatch['setBudgetAmount'] = useCallback(
    (budgetedAmount) => {
      setWelcome((x) => ({ ...x, budgetedAmount }))
    },
    [setWelcome],
  )

  const setProductsToCreate: WelcomeDispatch['setProductsToCreate'] =
    useCallback(
      (productsToCreate) => {
        setWelcome((x) => ({ ...x, productsToCreate }))
      },
      [setWelcome],
    )

  useEffect(() => {
    if (!shopifyProductEntities) return
    if (shopifyProductEntities.products.edges.length === 0) {
      setWelcome((x) => ({
        ...x,
        isShopifyProductEntitiesLoading: false,
      }))
      return
    }

    setWelcome((x) => {
      // We don't want to update this from a query if it has already been ran
      if (x.productsToCreate.length > 0) return x

      return {
        ...x,
        isShopifyProductEntitiesLoading: false,
        productsToCreate: shopifyProductEntities.products.edges.map(
          (x) => x.node,
        ),
      }
    })
  }, [shopifyProductEntities, setWelcome])

  return (
    <WelcomeStateContext.Provider value={value}>
      <WelcomeDispatchContext.Provider
        value={{ setWelcome, setBudgetAmount, setProductsToCreate }}
      >
        {children}
      </WelcomeDispatchContext.Provider>
    </WelcomeStateContext.Provider>
  )
}

export const useWelcomeState = (): WelcomeState => {
  const context = useContext(WelcomeStateContext)

  if (!context) {
    throw new Error('useWelcomeState must be used within a WelcomeProvider.')
  }

  return context
}

export const useWelcomeDispatch = (): WelcomeDispatch => {
  const context = useContext(WelcomeDispatchContext)

  if (!context) {
    throw new Error('useWelcomeDispatch must be used within a WelcomeProvider.')
  }

  return context
}

export const useWelcome = (): [WelcomeState, WelcomeDispatch] => {
  return [useWelcomeState(), useWelcomeDispatch()]
}
