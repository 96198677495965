import { HStack, StackProps, Text } from '@chakra-ui/layout'
import { FC } from 'react'
import { prettyFloatingDate } from '@bounty/utils'

export type DateRangeDisplayProps = StackProps & {
  start: Date
  end: Date
}

export const DateRangeDisplay: FC<DateRangeDisplayProps> = ({
  start,
  end,
  ...rest
}) => {
  return (
    <HStack {...rest}>
      <Text fontSize="sm" color="gray.600">
        {prettyFloatingDate(start.toISOString())} &#10230;{' '}
        {prettyFloatingDate(end.toISOString())}
      </Text>
    </HStack>
  )
}
