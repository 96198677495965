import { FC, Fragment } from 'react'
import {
  MarkCodeNotWorkingDocument,
  SparkAdCodeRequestPartsFragment,
  SparkAdCodeRequestStatus,
} from '../../generated/backendGraphql'
import { useMutationBackend } from '../../apollo/backend/hooks'
import {
  VStack,
  HStack,
  Text,
  Box,
  Button,
  useClipboard,
} from '@bounty/web-components'
import {
  prettyCurrency,
  UnreachableCaseError,
  prettyFloatingDate,
} from '@bounty/utils'
import { subDays, isAfter } from 'date-fns'

type TimelineItemProps = {
  isLast: boolean
  isWaiting?: boolean
}
const TimelineItem: FC<TimelineItemProps> = ({
  isLast,
  isWaiting,
  children,
}) => {
  const borderStyle = isWaiting
    ? '2px dashed black'
    : isLast
    ? '2px solid white'
    : '2px solid black'
  return (
    <HStack
      width="100%"
      pb={9}
      alignItems="flex-start"
      borderLeft={borderStyle}
    >
      <Box
        width="24px"
        backgroundColor="black"
        height="24px"
        ml="-13px"
        flexShrink={0}
        mr={4}
        borderRadius="full"
      ></Box>
      {children}
    </HStack>
  )
}

const getLogForStatus = (
  sparkCodeRequest: SparkAdCodeRequestPartsFragment,
  status: SparkAdCodeRequestStatus,
) => sparkCodeRequest.requestLog.find((l) => l.status === status)

type OfferSentProps = {
  sparkCodeRequest: SparkAdCodeRequestPartsFragment
}
const OfferSent: FC<OfferSentProps> = ({ sparkCodeRequest }) => {
  const message = getLogForStatus(sparkCodeRequest, 'CREATED')?.message
  const date =
    getLogForStatus(sparkCodeRequest, 'CREATED')?.createdAt ?? new Date()
  return (
    <VStack width="100%" spacing={4} alignItems="flex-start">
      <HStack width="100%" justifyContent="space-between">
        <Text fontSize="xl" fontWeight="bold">
          Spark Code offer sent
        </Text>
        <Text color="gray.500">{prettyFloatingDate(date)}</Text>
      </HStack>
      <Text maxW={400}>
        Waiting on a response from the creator. We will notify you when the
        Spark Code has been received within 48 hours.
      </Text>
      <HStack
        width="100%"
        spacing={4}
        justifyContent="flex-start"
        border="1px solid"
        borderColor="gray.300"
        borderRadius="md"
        p={4}
        alignItems="flex-start"
      >
        <VStack alignItems="flex-start">
          <Text>Amount</Text>
          <Text fontWeight="semibold">
            {prettyCurrency(sparkCodeRequest.amount)}
          </Text>
        </VStack>
        <VStack alignItems="flex-start">
          <Text>Duration</Text>
          <Text fontWeight="semibold">30d</Text>
        </VStack>
        {!!message && (
          <VStack alignItems="flex-start">
            <Text>Message</Text>
            <Text fontWeight="semibold">{message}</Text>
          </VStack>
        )}
      </HStack>
    </VStack>
  )
}

type OfferAcceptedProps = {
  sparkCodeRequest: SparkAdCodeRequestPartsFragment
}
const OfferAccepted: FC<OfferAcceptedProps> = ({ sparkCodeRequest }) => {
  const confirmed = getLogForStatus(sparkCodeRequest, 'CODE_CONFIRMED')
  const accepted = getLogForStatus(sparkCodeRequest, 'CREATOR_ACCEPTED')

  const code = (confirmed ? confirmed.message : accepted?.message) ?? ''
  const date = confirmed
    ? confirmed.createdAt
    : accepted?.createdAt ?? new Date()

  const [markCodeNotWorking, { loading }] = useMutationBackend(
    MarkCodeNotWorkingDocument,
    {
      variables: { requestId: sparkCodeRequest.id },
      update(cache, { data }) {
        if (!data) return
        cache.modify({
          id: cache.identify(data.markCodeNotWorking),
          fields: {
            requestLog(previous) {
              const codeNotWorking = data.markCodeNotWorking.requestLog.find(
                (r) => r.status === 'CODE_NOT_WORKING',
              )
              return [...previous, codeNotWorking]
            },
          },
        })
      },
    },
  )

  const showNotWorkingButton =
    isAfter(new Date(sparkCodeRequest.updatedAt), subDays(new Date(), 2)) &&
    sparkCodeRequest.status === 'CREATOR_ACCEPTED'

  const { hasCopied, onCopy } = useClipboard(code)
  return (
    <VStack alignItems="flex-start" spacing={4}>
      <HStack justifyContent="space-between" width="100%">
        <Text fontSize="xl" fontWeight="bold">
          Offer accepted!
        </Text>
        <Text color="gray.500">{prettyFloatingDate(date)}</Text>
      </HStack>
      <Text maxW={400}>
        Your Spark code is ready to use! You have 48 hours from receipt to
        report problems with the code. After that period, we will charge your
        account to pay the creator.
      </Text>
      <HStack>
        <Button colorScheme="darkBlack" onClick={onCopy} px={6}>
          {hasCopied ? 'Copied' : 'Copy'}
        </Button>
        <Text
          border="1px solid"
          p={2}
          borderColor="gray.300"
          borderRadius="md"
          style={{ lineBreak: 'anywhere' }}
        >
          {code}
        </Text>
      </HStack>
      {showNotWorkingButton && (
        <Button
          variant="link"
          disabled={loading}
          onClick={async () => await markCodeNotWorking()}
          colorScheme="orange"
          color="orange.600"
        >
          The code is not working
        </Button>
      )}
    </VStack>
  )
}

const OfferWaiting = () => {
  return (
    <VStack width="100%" spacing={4} alignItems="flex-start">
      <Text fontSize="xl" fontWeight="bold">
        Waiting for a response...
      </Text>
    </VStack>
  )
}

const OfferRejected: FC<{
  sparkCodeRequest: SparkAdCodeRequestPartsFragment
}> = ({ sparkCodeRequest }) => {
  const date =
    getLogForStatus(sparkCodeRequest, 'CREATOR_REJECTED')?.createdAt ??
    new Date()
  return (
    <VStack spacing={4} alignItems="flex-start">
      <HStack justifyContent="space-between" width="100%">
        <Text fontSize="xl" fontWeight="bold">
          No response
        </Text>
        <Text color="gray.500">{prettyFloatingDate(date)}</Text>
      </HStack>
      <Text maxW={400}>
        We could not receive a Spark Code from the creator. We'll let you know
        if anything changes.
      </Text>
    </VStack>
  )
}

const CodeNotWorking: FC<{
  sparkCodeRequest: SparkAdCodeRequestPartsFragment
}> = ({ sparkCodeRequest }) => {
  const code =
    getLogForStatus(sparkCodeRequest, 'CREATOR_ACCEPTED')?.message ?? ''
  return (
    <VStack spacing={4} alignItems="flex-start">
      <Text fontSize="xl" fontWeight="bold">
        Spark Code marked not working
      </Text>
      <Text maxW={400}>
        We will contact the creator to try and receive the correct code.
      </Text>
      <HStack>
        <Text fontWeight="semibold">Previous code:</Text>
        <Text
          border="1px solid"
          p={2}
          borderColor="gray.300"
          borderRadius="md"
          style={{ lineBreak: 'anywhere' }}
        >
          {code}
        </Text>
      </HStack>
    </VStack>
  )
}

const OfferCompleted: FC<{
  sparkCodeRequest: SparkAdCodeRequestPartsFragment
}> = ({ sparkCodeRequest }) => {
  const date =
    getLogForStatus(sparkCodeRequest, 'CREATED')?.createdAt ?? new Date()
  return (
    <VStack spacing={4} alignItems="flex-start" width="100%">
      <HStack justifyContent="space-between" width="100%">
        <Text fontSize="xl" fontWeight="bold">
          Spark Code request completed
        </Text>
        <Text color="gray.500">{prettyFloatingDate(date)}</Text>
      </HStack>
      <Text maxW={400}>
        Your Spark Code request has been completed successfully.
      </Text>
    </VStack>
  )
}

type SparkCodeRequestTimelineProps = {
  sparkCodeRequest: SparkAdCodeRequestPartsFragment
}
export const SparkCodeRequestTimeline: FC<SparkCodeRequestTimelineProps> = ({
  sparkCodeRequest,
}) => {
  type TimelineItemTypes =
    | 'CREATED'
    | 'REJECTED'
    | 'ACCEPTED'
    | 'CODE_NOT_WORKING'
    | 'COMPLETED'
  const buildTimeline = (): TimelineItemTypes[] => {
    switch (sparkCodeRequest.status) {
      case 'CREATED':
      case 'CREATOR_NOTIFIED':
        return ['CREATED']
      case 'CREATOR_REJECTED':
        return ['CREATED', 'REJECTED']
      case 'CREATOR_ACCEPTED':
        return ['CREATED', 'ACCEPTED']
      case 'CODE_NOT_WORKING':
        return ['CREATED', 'CODE_NOT_WORKING']
      case 'CODE_CONFIRMED':
        return ['CREATED', 'ACCEPTED']
      case 'PAID_OUT':
        return ['CREATED', 'ACCEPTED', 'COMPLETED']
      default:
        throw new UnreachableCaseError(sparkCodeRequest.status)
    }
  }
  const timeline = buildTimeline()

  return (
    <VStack data-testid="spark-code-timeline" spacing={0}>
      {timeline.map((r, index) => {
        const isLast = timeline.length - 1 === index
        switch (r) {
          case 'CREATED':
            return (
              <Fragment key={r}>
                <TimelineItem isLast={false}>
                  <OfferSent sparkCodeRequest={sparkCodeRequest} />
                </TimelineItem>
                {isLast && (
                  <TimelineItem isLast={false} isWaiting={true}>
                    <OfferWaiting />
                  </TimelineItem>
                )}
              </Fragment>
            )
          case 'ACCEPTED':
            return (
              <TimelineItem key={r} isLast={isLast}>
                <OfferAccepted sparkCodeRequest={sparkCodeRequest} />
              </TimelineItem>
            )
          case 'CODE_NOT_WORKING':
            return (
              <Fragment key={r}>
                <TimelineItem isLast={false}>
                  <CodeNotWorking sparkCodeRequest={sparkCodeRequest} />
                </TimelineItem>
                <TimelineItem isLast={false} isWaiting={true}>
                  <OfferWaiting />
                </TimelineItem>
              </Fragment>
            )
          case 'REJECTED':
            return (
              <TimelineItem key={r} isLast={isLast}>
                <OfferRejected sparkCodeRequest={sparkCodeRequest} />
              </TimelineItem>
            )
          case 'COMPLETED':
            return (
              <TimelineItem key={r} isLast={isLast}>
                <OfferCompleted sparkCodeRequest={sparkCodeRequest} />
              </TimelineItem>
            )
          default:
            throw new UnreachableCaseError(r)
        }
      })}
    </VStack>
  )
}
