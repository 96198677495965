import { EmbedConfigEntry } from '@bounty/types'
import { isNil } from '@bounty/utils'

export const getStoreEmbeds = <T extends { embeds?: any }>(store: T) => {
  if (isNil(store.embeds)) return []

  return store.embeds as EmbedConfigEntry[]
}

export const isProductScriptTagActivated = (embeds: EmbedConfigEntry[]) => {
  return embeds.some(
    (embed) => embed.code === 'PRODUCT' && isNil(embed.scriptTagId) === false,
  )
}
