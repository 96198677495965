import PicoSanity from 'picosanity'
import groq from 'groq'

interface ClientConfig {
  projectId: string
  dataset: string
  apiVersion?: string
  token?: string
  useCdn?: boolean
  withCredentials?: boolean
}

type PortableText = any

export type SanityScope = 'creators' | 'brands' | 'marketingSite'

export type GetBrandsAndCategoriesResponse = {
  brands: {
    _id: string
    isFeatured: boolean
    showProducts: boolean
    showTopPosts: boolean
    storeURL: string
    shopifyURL: string
    description: PortableText
    title: string
    brandImage: string
    featuredImages: string[]
    slug: string
    category: { title: string; slug: string }
    subCategory: { title: string; slug: string }
  }[]
  categories: { title: string; _id: string; slug: string; order: number }[]
}

export type GetBrandResponse = {
  isFeatured: boolean
  showProducts: boolean
  showTopPosts: boolean
  storeURL: string
  shopifyURL: string
  description: PortableText
  title: string
  _id: string
  isEnabled: boolean
  brandImage: string
  featuredImages: string[]
  slug: string
  category: { title: string; slug: string }
  subCategory: { title: string; slug: string }
  tags: { title: string; slug: string }[]
}

export type GetFaqsResponse = {
  title: string
  body: PortableText[]
}[]

export type GetBlogsResponse = {
  blogs: {
    _id: string
    title: string
    description: any[]
    publishedAt: string
    slug: string
    mainImage: string
    categories: { title: string; slug: string }[]
    authors: {
      title: string
      image: string
      slug: string
    }[]
  }[]
  categories: Array<{
    _id: string
    title: string
    slug: string
  }>
}

export type GetBlogResponse = {
  _id: string
  title: string
  description: string
  publishedAt: string
  body: any[]
  slug: string
  mainImage: string
  categories: { title: string; slug: string }[]
  authors: {
    title: string
    position: string
    image: string
    slug: string
  }[]
}

export class Sanity {
  config: ClientConfig
  client: ReturnType<typeof PicoSanity>
  scope: SanityScope

  constructor(projectId: string, dataset: string, scope: SanityScope) {
    this.config = {
      apiVersion: '2021-10-21',
      // Find these in your ./studio/sanity.json file
      dataset,
      projectId,
      useCdn: true,
      // We want only query the public data for the front facing website. Credentials are needed when hosting a Sanity Studio
      withCredentials: false,
    }

    this.client = new PicoSanity(this.config)
    this.scope = scope
  }

  getClient() {
    return this.client
  }

  static getPortableTextGroq(key = 'body') {
    return `
        ${key}[]{
          ...,
          _type == "figure" => {
            "asset": asset->url,
            "type": @.reference->_type
          },
          _type == "grid" => {
            "items": items[]{
              ...,
              _type == "figure" => {
                "asset": asset->url,
                "type": @.reference->_type
              },
            },
            "type": @.reference->_type
          },
          markDefs[]{
            ...,
            _type == "internalLink" => {
              "slug": @.reference->slug.current,
              "type": @.reference->_type
            },
          },
        }
        `
  }

  getShopsAndCategories() {
    return this.getClient().fetch<GetBrandsAndCategoriesResponse>(groq`{
      "brands": *[_type == "brand" && isEnabled == true] | order(orderRank asc) {
        _id,
        isFeatured,
        "brandImage": brandImage.asset->url + "?w=200&h=200&auto=format",
        title,
        "slug": slug.current,
        "featuredImages": featuredImages[].asset->{
          "url": url + "?w=400&h=400&auto=format&q=50"
        }.url,
        shopifyURL,
        category->{
          title,
          "slug": slug.current
        },
        subCategory->{
          title,
          "slug": slug.current
        },
        ${Sanity.getPortableTextGroq('description')}
      },
      "categories": *[_type == "brandCategory" && !defined(parent)] | order(orderRank asc) {
        _id,
        title,
        order,
        "slug": slug.current,
      }
    }`)
  }

  async getBrand(shop: string) {
    const data = await this.getClient().fetch<
      GetBrandResponse[]
    >(groq`*[slug.current == "${shop}"]{
      _id,
      isFeatured,
      "brandImage": brandImage.asset->url + "?w=300&h=300&auto=format&q=50",
      title,
      "slug": slug.current,
      "featuredImages": featuredImages[].asset->{
        "url": url + "?w=800&h=800&auto=format&q=50"
      }.url,
      showProducts,
      storeURL,
      shopifyURL,
      showTopPosts,
      isFeatured,
      isEnabled,
      category->{
        title,
        "slug": slug.current
      },
      subCategory->{
        title,
        "slug": slug.current
      },
      tags[]->{
        title,
        "slug": slug.current
      },
      ${Sanity.getPortableTextGroq('description')}
    }`)

    if (!data || data.length === 0) {
      throw new Error(`No brand found for ${shop}`)
    }

    return data[0]
  }

  getFaqs() {
    return this.getClient().fetch<GetFaqsResponse>(groq`
    *[_type == "frequentlyAskedQuestion" && isEnabled == true] | order(orderRank asc) {
        title,
        ${Sanity.getPortableTextGroq()}
      }
    `)
  }

  getBlogs() {
    return this.getClient().fetch<GetBlogsResponse>(groq`{
      "blogs": *[_type == "post" && isEnabled == true && publishedAt <= now() && "${this.scope}" in scopes] | order(publishedAt desc) {
        _id,
        title,
        "slug": slug.current,
        description,
        authors[]->{
          title,
          "image": image.asset->url,
          "slug": slug.current
        },
        "mainImage": mainImage.asset->url,
        categories[]->{
          title,
          "slug": slug.current
        },
        publishedAt
      },
      "categories": *[_type == "blogCategory"] | order(title asc) {
        _id,
        title,
        order,
        "slug": slug.current,
      }
    }`)
  }

  async getBlog(blogParam: string) {
    const content = await this.getClient().fetch<
      GetBlogResponse[]
    >(groq`*[slug.current == "${blogParam}" && isEnabled == true && publishedAt <= now() && "${
      this.scope
    }" in scopes]{
    _id,
    title,
    "slug": slug.current,
    description,
    authors[]->{
      title,
      position,
      "image": image.asset->url,
      "slug": slug.current
    },
    "mainImage": mainImage.asset->url,
    categories[]->{
      title,
      "slug": slug.current
    },
    publishedAt,
    ${Sanity.getPortableTextGroq()}
  }`)

    if (content.length === 0) {
      throw new Error(`Could not find blog for ${blogParam}.`)
    }

    return content[0]
  }
}
