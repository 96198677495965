// Wrappers of common components to make them framer-motion friendly
import { HTMLMotionProps, motion } from 'framer-motion'
import { ContentBox, ContentBoxProps } from './ContentBox'
import { Box, BoxProps, Avatar, AvatarProps } from './Chakra'

export type MotionBoxProps = Omit<
  BoxProps,
  'transition' | 'style' | 'onDragStart' | 'onDragEnd' | 'onDrag'
> &
  Pick<
    HTMLMotionProps<'div'>,
    | 'style'
    | 'animate'
    | 'transition'
    | 'initial'
    | 'exit'
    | 'variants'
    | 'onAnimationStart'
    | 'onDragStart'
    | 'onDragEnd'
    | 'onDrag'
  >
// @ts-ignore
export const MotionBox = motion<MotionBoxProps>(Box)

export type MotionContentBoxProps = Omit<
  ContentBoxProps,
  'transition' | 'style' | 'onDragStart' | 'onDragEnd' | 'onDrag'
> &
  Pick<
    HTMLMotionProps<'div'>,
    | 'style'
    | 'animate'
    | 'transition'
    | 'initial'
    | 'exit'
    | 'variants'
    | 'onAnimationStart'
    | 'onDragStart'
    | 'onDragEnd'
    | 'onDrag'
  >
// @ts-ignore
export const MotionContentBox = motion<MotionContentBoxProps>(ContentBox)

export type MotionAvatarProps = Omit<
  AvatarProps,
  'transition' | 'style' | 'onDragStart' | 'onDragEnd' | 'onDrag'
> &
  Pick<
    HTMLMotionProps<'div'>,
    | 'style'
    | 'animate'
    | 'transition'
    | 'initial'
    | 'exit'
    | 'variants'
    | 'onAnimationStart'
    | 'onDragStart'
    | 'onDragEnd'
    | 'onDrag'
  >
// @ts-ignore
export const MotionAvatar = motion<MotionAvatarProps>(Avatar)
