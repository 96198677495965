import * as z from 'zod'

export const BountyModerationApproveSchema = z
  .object({
    bountyId: z.string(),
    feedbackMessage: z.string(),
    feedbackRating: z.number().int().min(0).max(10),
  })
  .strict()

export type BountyModerationApproveSchemaBody = z.infer<
  typeof BountyModerationApproveSchema
>

export const BountyModerationRejectSchema = z
  .object({
    bountyId: z.string(),
    feedbackMessage: z.string(),
    feedbackRating: z.number().int().min(0).max(10),
  })
  .strict()

export type BountyModerationRejectSchemaBody = z.infer<
  typeof BountyModerationApproveSchema
>
