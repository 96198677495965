import { FC } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { PageSkeleton } from './PageSkeleton'

export type RequireAuthProps = {
  isAuthed: boolean
  isLoading: boolean
  /**
   * Where to go if auth check fails
   *
   * @default '/login'
   */
  navToOnAuthFailure?: string
}

export const RequireAuth: FC<RequireAuthProps> = ({
  children,
  isAuthed,
  isLoading,
  navToOnAuthFailure = '/login',
}) => {
  const location = useLocation()

  if (isLoading) return <PageSkeleton />

  if (isAuthed === false) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate state={{ from: location }} to={navToOnAuthFailure} />
  }

  // TS yells when it's just children although that's valid
  return <>{children}</>
}
