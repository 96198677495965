import { FC, ComponentProps } from 'react'
import { MobileNav } from './MobileNav'
import { TopNav } from './TopNav'
import { LeftNav } from './LeftNav'
import { AppRoute } from './types'
import { Flex, Box } from './Chakra'
import { Outlet } from 'react-router-dom'
import { useBounty } from './BountyProvider'

export type InteriorAppWrapperProps = {
  leftNavItems?: AppRoute[]
  logoLinkTo?: string
  showBetaLogo?: boolean
  mobileNavProps?: Partial<
    Omit<
      ComponentProps<typeof MobileNav>,
      'mainItems' | 'secondaryItems' | 'mobileNavHeight'
    >
  >
  topNavProps?: Partial<Omit<ComponentProps<typeof TopNav>, 'topNavHeight'>>
  leftNavProps?: Omit<ComponentProps<typeof LeftNav>, 'items'>
}

export const InteriorAppWrapper: FC<InteriorAppWrapperProps> = ({
  leftNavItems,
  logoLinkTo = '/',
  showBetaLogo = false,
  mobileNavProps,
  topNavProps,
}) => {
  const { topNavHeight, mobileNavHeight } = useBounty()
  return (
    <>
      <MobileNav
        display={['block', 'block', 'block', 'none']}
        mainItems={leftNavItems}
        logoLinkTo={logoLinkTo}
        showBetaLogo={showBetaLogo}
        mobileNavHeight={mobileNavHeight}
        bottomNavMenuComponent={topNavProps?.rightComponent}
        topNavMenuComponent={
          topNavProps?.leftComponent || topNavProps?.centerComponent ? (
            <Box>
              <Flex justifyContent="center" mb="6">
                {topNavProps?.leftComponent}
              </Flex>
              <Flex justifyContent="center">
                {topNavProps?.centerComponent}
              </Flex>
            </Box>
          ) : null
        }
        {...mobileNavProps}
      />
      <TopNav
        display={['none', 'none', 'none', 'flex']}
        position="sticky"
        top="0"
        backgroundColor="white"
        zIndex="10"
        topNavHeight={topNavHeight}
        {...topNavProps}
      />

      <Flex>
        {leftNavItems && (
          <LeftNav
            logoLinkTo={logoLinkTo}
            showBetaLogo={showBetaLogo}
            display={['none', 'none', 'none', 'block']}
            position="sticky"
            top={topNavHeight}
            backgroundColor="white"
            zIndex="10"
            items={leftNavItems}
          />
        )}
        <Outlet />
      </Flex>
    </>
  )
}
