import {
  StackProps,
  Metric,
  Stack,
  Text,
  Tooltip,
} from '@bounty/web-components'
import { FC } from 'react'
import { useQueryBackend } from '../../apollo/backend/hooks'
import { MetricsDocument } from '../../generated/backendGraphql'
import { prettyPercentage } from '@bounty/utils'

type MetricsProps = StackProps & {
  start: Date
  end: Date
  comparisonStartDate: Date
  comparisonEndDate: Date
}

export const Metrics: FC<MetricsProps> = (props) => {
  const { data, loading } = useQueryBackend(MetricsDocument, {
    variables: {
      params: {
        startDate: props.start,
        endDate: props.end,
        comparisonStartDate: props.comparisonStartDate,
        comparisonEndDate: props.comparisonEndDate,
      },
    },
  })

  return (
    <Stack spacing={3} direction={['column', 'column', 'row']} {...props}>
      <Metric
        isLoading={loading || !data}
        title="New Assets"
        value={data?.storeStats.assets}
      >
        <MetricComparison
          comparison={prettyPercentage(
            data?.storeStats.assetsPercentChange ?? 0,
            { showPlus: true },
          )}
        />
      </Metric>

      <Metric
        isLoading={loading || !data}
        title="New Creators"
        value={data?.storeStats.creators}
      >
        <MetricComparison
          comparison={prettyPercentage(
            data?.storeStats.creatorsPercentChange ?? 0,
            { showPlus: true },
          )}
        />
      </Metric>
    </Stack>
  )
}

interface MetricComparisonProps {
  comparison: string
}

export const MetricComparison: FC<MetricComparisonProps> = ({ comparison }) => {
  return (
    <Tooltip label="Compared to last 30 days">
      <Text fontSize="large" fontWeight="semibold">
        {comparison === '+100000%' ? (
          <Text as="span" fontSize="26px">
            ∞
          </Text>
        ) : (
          comparison
        )}
      </Text>
    </Tooltip>
  )
}
