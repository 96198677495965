import { FC } from 'react'
import {
  Flex,
  Box,
  Text,
  Skeleton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  List,
  ListItem,
  HStack,
} from '@bounty/web-components'
import { useQueryBackend } from '../../apollo/backend/hooks'
import { PayoutSimulatorDocument } from '../../generated/backendGraphql'
import { prettyCurrency, prettyViews } from '@bounty/utils'

const calculate15dayCPM = (payment: number, viewsOnComplete: number) => {
  const fifteenDayViews = 0.02 * 15 * viewsOnComplete + viewsOnComplete
  return (payment / fifteenDayViews) * 1000
}

const ExamplePayoutCard: FC<{
  views: number
  payment: number
}> = (props) => {
  const cpm = (props.payment / props.views) * 1000
  const fifteenDayCpm = calculate15dayCPM(props.payment, props.views)
  return (
    <Box
      width="100%"
      borderColor="gray.100"
      borderWidth="1px"
      borderRadius="5"
      borderStyle="solid"
      p={5}
      m={5}
      data-testid="payout-simulator-payout-card"
    >
      <Flex justifyContent={'space-between'} width="100%" p={3}>
        <Text>Views (48hrs)</Text>
        <Text
          backgroundColor={'gray.200'}
          borderRadius={'3'}
          data-testid="simulator-card-views"
        >
          {prettyViews(props.views)}
        </Text>
      </Flex>
      <Flex justifyContent={'space-between'} p={3} width="100%">
        <Text>Total CPM (48hrs)</Text>
        <Text>{prettyCurrency(cpm, { precision: 2 })}</Text>
      </Flex>
      <Flex justifyContent={'space-between'} p={3} width="100%">
        <Text>Estimated CPM (15 days)*</Text>
        <Text>{prettyCurrency(fifteenDayCpm, { precision: 2 })}</Text>
      </Flex>
      <Flex justifyContent={'space-between'} p={3} width="100%">
        <Text>Total Spend</Text>
        <Text>{prettyCurrency(props.payment, { precision: 2 })}</Text>
      </Flex>
    </Box>
  )
}

export const CreatorPayoutSimulator: FC<{
  multiplier: number
}> = (props) => {
  const exampleViews = [1500, 56000, 994230]
  const { data, loading } = useQueryBackend(PayoutSimulatorDocument, {
    variables: {
      multiplier: props.multiplier,
      views: exampleViews,
    },
  })

  if (loading || !data) {
    return <Skeleton height="290px" />
  }

  // TODO: https://github.com/bounty-app/platform/issues/1050
  return (
    <Flex
      width="100%"
      flexDirection={'column'}
      minHeight={'290px'}
      data-testid="payout-simulator-box"
    >
      <Text fontSize="lg" fontWeight={'bold'} pb={5}>
        Example Payouts To Creators
      </Text>
      <Tabs>
        <TabList>
          <Tab backgroundColor={'gray.100'}>Payouts</Tab>
          <Tab backgroundColor={'gray.100'}>Payout Tiers</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Flex
              flexDirection={'row'}
              justifyContent="space-between"
              width="100%"
            >
              {data.payoutSimulator.viewPayments.map((entry, i) => {
                return (
                  <ExamplePayoutCard
                    key={i}
                    views={entry.views}
                    payment={entry.amount}
                  />
                )
              })}
            </Flex>
            <Text>
              *After 48 hours, we continue tracking the free views you receive
              to show how your CPMs continue to drop over time. This is an
              estimate of what you can expect based on what we see across the
              platform.
            </Text>
          </TabPanel>
          <TabPanel>
            <List mb="6">
              {data.payoutSimulator.payoutTiers.map((entry, i) => {
                return (
                  <ListItem key={i}>
                    <HStack justifyContent={'space-between'} width={'50%'}>
                      <Text>
                        {prettyViews(entry.from)}-{prettyViews(entry.to)} views:
                      </Text>

                      <Text>${entry.cpm} CPM</Text>
                    </HStack>
                  </ListItem>
                )
              })}
            </List>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  )
}
