import { FC, useEffect } from 'react'
import decode from 'jwt-decode'
import { isNil } from '@bounty/utils'

const zendeskScriptId = 'ze-snippet'

type ZendeskProps = {
  localstorageKey?: string
  isAuthed: boolean
}

const getZendeskToken = (localstorageKey = 'authToken') => {
  const maybeToken = localStorage.getItem(localstorageKey)

  if (isNil(maybeToken)) return

  const decoded = decode<{ data: { zendeskToken?: string } }>(maybeToken)
  return decoded.data.zendeskToken
}

function loadZendeskScript() {
  const script = document.createElement('script')
  script.src =
    'https://static.zdassets.com/ekr/snippet.js?key=2bca879e-d5e9-45ff-9a74-d97dcb96be00'
  script.id = zendeskScriptId
  document.body.appendChild(script)

  return new Promise<void>((res, rej) => {
    script.onload = function () {
      res()
    }
    script.onerror = function () {
      rej()
    }
  })
}

const attemptZendeskLogin = (zendeskToken?: string) => {
  if (isNil(zendeskToken) === true) return

  // The callback only gets called once no matter how often you invoke this function
  // @ts-expect-error - It's there if zendesk if activated
  window?.zE?.('messenger', 'loginUser', function (callback) {
    callback(zendeskToken)
  })
}

export const Zendesk: FC<ZendeskProps> = ({
  localstorageKey = 'authToken',
  isAuthed,
}) => {
  const zendeskToken = getZendeskToken(localstorageKey)

  useEffect(() => {
    if (isNil(document.getElementById(zendeskScriptId)) === false) return

    const loadZendesk = async () => {
      await loadZendeskScript()

      attemptZendeskLogin(zendeskToken)
    }

    loadZendesk()
  }, [zendeskToken])

  useEffect(() => {
    if (isAuthed) {
      attemptZendeskLogin(zendeskToken)
    } else {
      // @ts-expect-error - It's there if zendesk if activated
      window?.zE?.('messenger', 'logoutUser')
    }
  }, [isAuthed, zendeskToken])

  return null
}

/**
 * Invoke this hook for parts of the app where you would like zendesk to be hidden. There is no native way
 * to do this and since it's a script it's async when it decides to mount.
 */
export const useHideZendesk = () => {
  // This can get called before the script has set itself on the window so call in an interval
  useEffect(() => {
    let retryCounter = 0
    const MAX_RETRIES = 50
    const intervalId = setInterval(() => {
      retryCounter += 1

      if (retryCounter > MAX_RETRIES) {
        clearInterval(intervalId)
        return
      }

      // @ts-ignore
      if (window.zE) {
        // There's no native way to do this so we copy pasta from the forum
        // https://support.zendesk.com/hc/en-us/articles/4408828655514
        // @ts-ignore
        window?.zE('messenger:set', 'cookies', false)
        clearInterval(intervalId)
      }
    }, 100)

    return () => {
      // @ts-ignore
      window?.zE('messenger:set', 'cookies', true)
    }
  }, [])
}
