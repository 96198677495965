import { FC, ReactNode } from 'react'
import { Tile } from '.'
import {
  BoxProps,
  HStack,
  Stack,
  StackProps,
  Text,
  Skeleton,
  VStack,
} from './Chakra'

type MetricProps = BoxProps & {
  title: ReactNode
  value: ReactNode
  isLoading?: boolean
}

export const Metric: FC<MetricProps> = ({
  children,
  title,
  value,
  isLoading = false,
  ...rest
}) => {
  return (
    <Tile
      flex="1"
      _hover={{ boxShadow: '3px 3px 0 0 black', borderColor: 'black' }}
      {...rest}
    >
      {isLoading ? (
        <VStack align="stretch">
          <Skeleton height="20px" />
          <Skeleton height="33px" />
        </VStack>
      ) : (
        <VStack width="100%" alignItems="flex-start">
          <Text color="gray.600" fontSize="sm">
            {title}
          </Text>
          <HStack flexWrap="wrap" width="100%" justifyContent="space-between">
            <Text color="black" fontWeight="semibold" fontSize="2xl">
              {value}
            </Text>
            {children}
          </HStack>
        </VStack>
      )}
    </Tile>
  )
}

export type MetricGroupProps = StackProps
export const MetricGroup = Stack
