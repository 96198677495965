import { FC } from 'react'
import { useQueryBackend } from '../../apollo/backend/hooks'
import { StoreUsageChargeBalancesDocument } from '../../generated/backendGraphql'
import {
  Text,
  Box,
  Flex,
  ContentBox,
  Skeleton,
  Progress,
  Heading,
} from '@bounty/web-components'
import { prettyCurrency, isNil } from '@bounty/utils'
import { parseISO, format } from 'date-fns'

/**
 * Note, this is an ISO Date, not an ISO timestamp
 */
const prettyFormatISODate = (isoDate: string) => {
  return format(parseISO(isoDate), 'MMM do y')
}

export const CurrentMonthlySpend: FC = () => {
  const { data, loading } = useQueryBackend(StoreUsageChargeBalancesDocument)

  return (
    <ContentBox mb="6" p={10}>
      <Flex flexDir="column">
        {loading || isNil(data) ? (
          <Box flex="1" px="4">
            <Skeleton height="16px" mb="4" />
            <Skeleton height="16px" />
          </Box>
        ) : (
          <Flex flexDirection={'column'}>
            <Heading mb="4" size="md" marginTop="2">
              Summary
            </Heading>

            <Flex width={'100%'} justifyContent="space-between" pb={6}>
              <Box mr={4} flex="1">
                <ContentBox
                  backgroundColor="gray.50"
                  flex="1"
                  px={10}
                  py={6}
                  mb={4}
                >
                  <Text textAlign="center">Total Spend This Cycle</Text>
                  <Text fontSize="lg" fontWeight={'bold'} textAlign="center">
                    {prettyCurrency(data.storeUsageChargeBalances.balanceUsed, {
                      precision: 2,
                    })}
                  </Text>
                </ContentBox>
              </Box>

              <Box mr={4} flex="1">
                <ContentBox
                  backgroundColor="gray.50"
                  flex="1"
                  px={10}
                  py={6}
                  mb={4}
                >
                  <Text textAlign="center">Bill cycle end date</Text>
                  <Text fontSize="lg" fontWeight={'bold'} textAlign="center">
                    {data.storeUsageChargeBalances.billingOn &&
                      prettyFormatISODate(
                        data.storeUsageChargeBalances.billingOn,
                      )}
                  </Text>
                </ContentBox>
              </Box>
            </Flex>

            {data.currentStore.usageChargeDiscountMultiplier !== 100 && (
              <Flex maxWidth={'400px'}>
                <Text flex="1" fontSize="lg">
                  Content Pricing Beta Discount
                </Text>
                <Text fontWeight={'bold'} fontSize="lg">
                  {100 - data.currentStore.usageChargeDiscountMultiplier}%
                </Text>
              </Flex>
            )}

            <Flex width="100%" alignItems="center">
              <Progress
                width="80%"
                value={data.storeUsageChargeBalances.balanceUsed}
                min={0}
                max={data.storeUsageChargeBalances.totalMonthlyLimit}
                size="sm"
                colorScheme="green"
              />
              <Flex flexDirection={'column'} pl="4" pr="4">
                <Text textAlign={'center'} color="gray.600">
                  {prettyCurrency(
                    data.storeUsageChargeBalances.totalMonthlyLimit,
                    {
                      precision: 2,
                    },
                  )}
                </Text>
                <Text fontSize={'sm'} textAlign="center" color="gray.600">
                  Budget
                </Text>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
    </ContentBox>
  )
}
