import { FC } from 'react'
import { AnimatedLogo } from './Logo'
import { Page, PageProps } from './Page'

type PageSkeletonProps = Partial<PageProps>

export const PageSkeleton: FC<PageSkeletonProps> = (props) => {
  return (
    <Page
      name=""
      height="100vh"
      overflow="hidden"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <AnimatedLogo
        style={{ maxWidth: '400px', width: '75%', margin: '0 auto' }}
      />
    </Page>
  )
}
