import { ComponentProps, FC, ReactNode, useRef } from 'react'
import {
  Box,
  BoxProps,
  Flex,
  useDimensions,
  Text,
  Button,
  Link,
  LinkPropsExternal,
  LinkPropsInternal,
} from './Chakra'
import { AppRoute } from './types'
import { motion, SVGMotionProps, useCycle, Variants } from 'framer-motion'
import styled from '@emotion/styled'
import { useLocation } from 'react-router-dom'
import { noop, isString } from '@bounty/utils'
import { Logo, BetaLogo } from './Logo'

const Path: FC<SVGMotionProps<SVGPathElement>> = (props) => (
  // @ts-ignore not sure what's happening here
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="hsl(0, 0%, 18%)"
    strokeLinecap="round"
    {...props}
  />
)

export type MobileNavMenuToggleProps = {
  toggle: () => void
}

export const MobileNavMenuToggle: FC<MobileNavMenuToggleProps> = ({
  toggle,
}) => (
  <Button
    onClick={toggle}
    display="block"
    variant="unstyled"
    minWidth="auto"
    height="auto"
    boxShadow="none !important"
  >
    <svg width="23" height="23" viewBox="0 0 23 23">
      <Path
        variants={{
          closed: { d: 'M 2 2.5 L 20 2.5' },
          open: { d: 'M 3 16.5 L 17 2.5' },
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: 'M 2 16.346 L 20 16.346' },
          open: { d: 'M 3 2.5 L 17 16.346' },
        }}
      />
    </svg>
  </Button>
)

const menuItemVariants = {
  open: {
    opacity: 1,
  },
  closed: {
    opacity: 0,
  },
}

const StyledMenuItem = styled(motion.li)`
  list-style: none;
`

export type MobileNavMenuItemProps = ComponentProps<typeof Link> & {
  icon: ReactNode
  to: string
  isExternal: boolean
  href?: string
}

// Replace wildcard routes to go the base and let the child router handle the
// redirect to a certain path.
const cleanTo = (to: string) => {
  return to.replace(new RegExp(`[*]$`, 'i'), '')
}

type MobileNavItemContentsProps = { isActive: boolean; icon: ReactNode }

const MobileNavItemContents: FC<MobileNavItemContentsProps> = ({
  isActive,
  children,
  icon,
}) => {
  return (
    <StyledMenuItem
      variants={menuItemVariants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      <Box
        px="4"
        py="2"
        transition="background 250ms"
        textAlign="center"
        background={
          isActive
            ? 'linear-gradient(90.68deg, #95F2DC -2.88%, #AEF197 106.42%)'
            : 'transparent'
        }
        fontWeight={isActive ? 'bold' : 'normal'}
      >
        <Text fontSize="xl">
          <Text as="span" mr="2">
            {icon}
          </Text>{' '}
          {children}
        </Text>
      </Box>
    </StyledMenuItem>
  )
}

const MobileNavItemExternal: FC<
  LinkPropsExternal & Omit<MobileNavItemContentsProps, 'isActive'>
> = ({ icon, children, ...rest }) => {
  return (
    <Link {...rest} _hover={{ textDecoration: 'none' }}>
      <MobileNavItemContents isActive={false} icon={icon}>
        {children}
      </MobileNavItemContents>
    </Link>
  )
}

const MobileNavItemInternal: FC<
  LinkPropsInternal & Omit<MobileNavItemContentsProps, 'isActive'>
> = ({ icon, to, children, ...rest }) => {
  const location = useLocation()

  const cleanedTo = isString(to) ? cleanTo(to) : ''
  const isActive =
    to === '/'
      ? location.pathname === cleanedTo
      : location.pathname.startsWith(cleanedTo)

  return (
    <Link {...rest} to={to} _hover={{ textDecoration: 'none' }}>
      <MobileNavItemContents isActive={isActive} icon={icon}>
        {children}
      </MobileNavItemContents>
    </Link>
  )
}

const navigationVariants: Variants = {
  open: {
    y: 0,
    transition: {
      stiffness: 100,
      staggerChildren: 0.07,
      delayChildren: 0.2,
    },
  },
  closed: {
    y: '-100%',
    transition: {
      delay: 0.4,
      stiffness: 100,
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
}

type MobileNavNavigationProps = {
  items: AppRoute[]
  onNavItemClicked: () => void
  mobileNavHeight: string
  topNavMenuComponent?: ReactNode
  bottomNavMenuComponent?: ReactNode
}

const StyledNavigation = styled(motion.ul)<{ height: string }>`
  padding-top: 2rem;
  position: absolute;
  background: white;
  left: 0;
  right: 0;
  top: ${(props) => props.height};
  height: calc(100vh - ${(props) => props.height});
`

const FlexMotion = motion(Flex)

export const MobileNavNavigation: FC<MobileNavNavigationProps> = ({
  items,
  onNavItemClicked = noop,
  mobileNavHeight,
  topNavMenuComponent = null,
  bottomNavMenuComponent = null,
}) => (
  <StyledNavigation variants={navigationVariants} height={mobileNavHeight}>
    {topNavMenuComponent != null && (
      <FlexMotion variants={menuItemVariants} justifyContent="center" mb="6">
        <motion.div variants={menuItemVariants}>
          {topNavMenuComponent}
        </motion.div>
      </FlexMotion>
    )}
    {items.map((item) =>
      item.isExternal === true ? (
        <MobileNavItemExternal
          key={item.name}
          icon={item.icon}
          isExternal={item.isExternal}
          href={item.href}
          onClick={onNavItemClicked}
        >
          {item.name}
        </MobileNavItemExternal>
      ) : (
        <MobileNavItemInternal
          key={item.name}
          to={item.displayPath}
          icon={item.icon}
          isExternal={item.isExternal}
          onClick={onNavItemClicked}
        >
          {item.name}
        </MobileNavItemInternal>
      ),
    )}
    {bottomNavMenuComponent != null && (
      <FlexMotion variants={menuItemVariants} justifyContent="center" mt="6">
        {bottomNavMenuComponent}
      </FlexMotion>
    )}
  </StyledNavigation>
)

type MobileNavProps = BoxProps & {
  logoLinkTo: string
  mainItems?: AppRoute[]
  showLogo?: boolean
  showBetaLogo?: boolean
  centerNavComponent?: ReactNode
  rightNavComponent?: ReactNode
  mobileNavHeight: string
  topNavMenuComponent?: ReactNode
  bottomNavMenuComponent?: ReactNode
}

const StyledNav = styled(motion(Box))`
  position: sticky;
  top: 0px;
  z-index: 100;
`

export const MobileNav: FC<MobileNavProps> = ({
  mainItems,
  logoLinkTo,
  centerNavComponent = null,
  rightNavComponent = null,
  showLogo = true,
  showBetaLogo = false,
  mobileNavHeight,
  topNavMenuComponent = null,
  bottomNavMenuComponent = null,
  ...rest
}) => {
  const [isOpen, toggleOpen] = useCycle(false, true)
  const containerRef = useRef(null)
  const dimensions = useDimensions(containerRef)

  return (
    <StyledNav
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      custom={dimensions?.borderBox.height}
      ref={containerRef}
      {...rest}
    >
      <Flex
        width="100%"
        height={mobileNavHeight}
        px="4"
        alignItems="center"
        justifyContent="space-between"
        backgroundColor="white"
        position="relative"
        zIndex={30}
      >
        <Box flex="1">
          {showLogo && (
            <Link isExternal={false} to={logoLinkTo}>
              {showBetaLogo ? (
                <BetaLogo
                  style={{
                    height: `calc(${mobileNavHeight} - 20px`,
                  }}
                />
              ) : (
                <Logo
                  style={{
                    height: `calc(${mobileNavHeight} - 20px`,
                  }}
                />
              )}
            </Link>
          )}
        </Box>
        <Box flex="1">{centerNavComponent}</Box>
        <Flex flex="1" justifyContent="flex-end">
          {rightNavComponent}
          <MobileNavMenuToggle toggle={toggleOpen} />
        </Flex>
      </Flex>
      {mainItems && (
        <MobileNavNavigation
          mobileNavHeight={mobileNavHeight}
          items={mainItems}
          onNavItemClicked={toggleOpen}
          topNavMenuComponent={topNavMenuComponent}
          bottomNavMenuComponent={bottomNavMenuComponent}
        />
      )}
    </StyledNav>
  )
}
