import {
  Box,
  Link,
  BoxProps,
  DrawerBody,
  DrawerHeader,
  DrawerCloseButton,
} from '@bounty/web-components'
import { ChevronLeftIcon } from '@bounty/icons'
import { ProductInstructionEditQuery } from '../../../generated/backendGraphql'
import {
  ProductInstructionForm,
  ProductInstructionFormLoading,
  ProductInstructionFormProps,
} from './ProductInstructionForm'
import { useNavigate } from 'react-router-dom'

export const formatInstructionForForm = (
  x: ProductInstructionEditQuery['storeProductInstruction'],
) => {
  const { __typename, features, headline, ...rest } = x

  return {
    ...rest,
    features: (features ?? []).map((x) => x),
    headline: headline ?? undefined,
  }
}

type ProductInstructionBaseProps = BoxProps &
  Pick<ProductInstructionFormProps, 'variant' | 'isDefault' | 'formProps'> & {
    isLoading: boolean
  }

export const ProductInstructionBase = ({
  variant,
  formProps,
  isLoading,
  isDefault,
  ...rest
}: ProductInstructionBaseProps) => {
  const navigate = useNavigate()

  const getTitle = () => {
    if (isDefault) return 'Edit Default Brief'
    return variant === 'create' ? 'Create Brief' : 'Edit Brief'
  }

  return (
    <Box {...rest}>
      <DrawerCloseButton />
      <DrawerHeader>
        <Box fontSize="md" fontWeight="normal" mb="4">
          <Box display="inline-block">
            <Link
              display="flex"
              alignItems="center"
              isExternal={false}
              to="../"
            >
              <ChevronLeftIcon mr="1" /> Go back
            </Link>
          </Box>
        </Box>
        {getTitle()}
      </DrawerHeader>
      <DrawerBody>
        {isLoading ? (
          <ProductInstructionFormLoading />
        ) : (
          <ProductInstructionForm
            variant={variant}
            isDefault={isDefault}
            formProps={formProps}
            onSubmitSuccess={() => {
              navigate('../')
            }}
          />
        )}
      </DrawerBody>
    </Box>
  )
}
