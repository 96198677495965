import { Box, BoxProps } from './Chakra'
import { FC } from 'react'

export const Tile: FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box
      padding="6"
      border="1px solid"
      borderColor="gray.300"
      borderRadius="sm"
      {...props}
    >
      {children}
    </Box>
  )
}
