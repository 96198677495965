import { FC } from 'react'
import { Box, BoxProps } from './Chakra'

export type ContentBoxProps = BoxProps

/**
 * A special box to wrap components and blocks of content to enforce a consistent border and padding.
 */
export const ContentBox: FC<ContentBoxProps> = ({ children, ...rest }) => {
  return (
    <Box
      border="1px"
      borderColor="gray.200"
      borderRadius="md"
      padding="4"
      {...rest}
    >
      {children}
    </Box>
  )
}
