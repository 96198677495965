import { ViewIcon } from '@bounty/icons'
import { prettyViews } from '@bounty/utils'
import { Flex, Text, Image } from '@bounty/web-components'
import { FC } from 'react'
import { useQueryBackend } from '../apollo/backend/hooks'
import {
  ContentLibraryItem,
  StoreProductDocument,
} from '../generated/backendGraphql'
import { getCloudinaryVideoImageUrl } from '../utils/urls'

type BountyCardProps = {
  content: ContentLibraryItem
}

export const BountyCard: FC<BountyCardProps> = ({ content }) => {
  const { data } = useQueryBackend(StoreProductDocument, {
    variables: { id: content.productId },
  })
  const product = data?.shopifyStoreProduct
  if (!product) return null
  return (
    <Flex
      width="100%"
      p={4}
      borderRadius="lg"
      borderColor="gray.500"
      borderWidth="1px"
      alignItems="flex-start"
    >
      <Image
        alt={content.profileName}
        src={getCloudinaryVideoImageUrl(content.videoId)}
        maxH="125px"
        objectFit="contain"
        borderRadius="md"
        mr={4}
      />
      <Flex flexDir="column" justifyContent="center">
        <Text mb={2} fontWeight="semibold">
          {content.profileName}
        </Text>
        <Text mb={2}>{content.videoTitle}</Text>
        <Flex
          borderRadius="md"
          p={2}
          alignItems="center"
          borderColor="gray.200"
          borderWidth="1px"
        >
          <Image
            src={product.imgLink ?? 'https://via.placeholder.com/30'}
            alt={product.productName ?? 'No name provided.'}
            height="30px"
            width="30px"
            objectFit="cover"
            borderRadius="sm"
            mr={2}
          />
          <Text fontWeight="semibold">
            {product.productName ?? 'No name provided.'}
          </Text>
        </Flex>
      </Flex>
      <Flex alignItems="center">
        <ViewIcon />
        <Text ml="2" fontSize="lg" fontWeight="semibold">
          {prettyViews(content.viewCount)}
        </Text>
      </Flex>
    </Flex>
  )
}
