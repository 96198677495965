import { UnreachableCaseError } from '@bounty/utils'
import { EmbedLocation } from '../../generated/backendGraphql'
import {
  landingPageSnippetConfigSchema,
  productSnippetConfigSchema,
  thankYouSnippetConfigSchema,
} from '@bounty/schemas'
import {
  buildRemoteConfigSnippetSettingsObjectLandingPage,
  buildRemoteConfigSnippetSettingsObjectProduct,
  buildRemoteConfigSnippetSettingsObjectThankYou,
} from '@bounty/common'
import {
  RemoteConfigSnippetSetting,
  BOUNTY_PRODUCT_SNIPPET_REMOTE_CONFIG_SETTINGS,
  BOUNTY_THANK_YOU_SNIPPET_REMOTE_CONFIG_SETTINGS,
  BOUNTY_LANDING_PAGE_SNIPPET_REMOTE_CONFIG_SETTINGS,
} from '@bounty/constants'
import * as z from 'zod'

/**
 * Due to image uploads, we extends the zod schema here because Form objects make it into the form state. This allows us to show
 * users instant feedback without needing to handle an upload on every chance.
 */
export const getPlacementBag = (
  location: EmbedLocation,
  embed: any,
): {
  schema: any
  configSettings: RemoteConfigSnippetSetting[]
  defaultValues: Record<string, any>
} => {
  switch (location) {
    case 'PRODUCT':
      return {
        schema: productSnippetConfigSchema,
        configSettings: BOUNTY_PRODUCT_SNIPPET_REMOTE_CONFIG_SETTINGS,
        defaultValues: buildRemoteConfigSnippetSettingsObjectProduct({
          embedConfiguration: embed?.configuration,
        }),
      }
    case 'THANKYOU':
      return {
        schema: thankYouSnippetConfigSchema.merge(
          z.object({
            imgLink: z.union([
              z.string().optional().nullable(),
              z.instanceof(File),
            ]),
          }),
        ),
        configSettings: BOUNTY_THANK_YOU_SNIPPET_REMOTE_CONFIG_SETTINGS,
        defaultValues: buildRemoteConfigSnippetSettingsObjectThankYou({
          embedConfiguration: embed?.configuration,
        }),
      }
    case 'LANDINGPAGE':
      return {
        schema: landingPageSnippetConfigSchema.merge(
          z.object({
            heroImgLink: z.union([
              z.string().optional().nullable(),
              z.instanceof(File),
            ]),
            heroFloatyImage1ImgLink: z.union([
              z.string().optional().nullable(),
              z.instanceof(File),
            ]),
            heroFloatyImage2ImgLink: z.union([
              z.string().optional().nullable(),
              z.instanceof(File),
            ]),
          }),
        ),
        configSettings: BOUNTY_LANDING_PAGE_SNIPPET_REMOTE_CONFIG_SETTINGS,
        defaultValues: buildRemoteConfigSnippetSettingsObjectLandingPage({
          embedConfiguration: embed?.configuration,
        }),
      }

    default:
      throw new UnreachableCaseError(location)
  }
}
