import { isNil } from '@bounty/utils'
import { useQueryBackend } from '../apollo/backend/hooks'
import {
  BountyFeatureCode,
  UseFeaturesDocument,
} from '../generated/backendGraphql'

export const useFeatureForStore = (code: BountyFeatureCode) => {
  const { data, loading } = useQueryBackend(UseFeaturesDocument)

  const feature = data?.currentStore.features?.find((f) => f.code === code)
  return {
    isAvailable: loading ? null : !isNil(feature),
  }
}
