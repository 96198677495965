import { FC } from 'react'
import { Alert, AlertProps, Link, Box } from '@bounty/web-components'
import { AlertIcon } from '@bounty/icons'
import { useQueryBackend } from '../apollo/backend/hooks'
import { NoEmbedsConfiguredDocument } from '../generated/backendGraphql'
import { isNil } from '@bounty/utils'

type NoEmbedsConfiguredProps = AlertProps

export const NoEmbedsConfigured: FC<NoEmbedsConfiguredProps> = ({
  ...rest
}) => {
  const { data, loading } = useQueryBackend(NoEmbedsConfiguredDocument)

  if (loading || isNil(data) || data.currentStore.embeds.length > 0) {
    return null
  }

  return (
    <Alert mb="6" status="warning" {...rest}>
      <AlertIcon boxSize="6" mr="4" />
      <Box>
        Your store does not have any enabled user experiences. Go to&nbsp;
        <Link
          isExternal={false}
          textDecoration="underline"
          to="/user-experiences"
        >
          user experiences
        </Link>
        &nbsp;to activate placements.
      </Box>
    </Alert>
  )
}
