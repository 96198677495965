import { FC } from 'react'
import {
  Box,
  Flex,
  BoxProps,
  FastForm,
  FastFormInput,
  FastFormTextarea,
  FastFormInputBase,
  FastFormButton,
  Text,
} from '@bounty/web-components'
import { sparkCodeRequestSchema, SparkCodeRequest } from '@bounty/schemas'
import { Controller, SubmitHandler } from 'react-hook-form'
import { SendIcon } from '@bounty/icons'

type SparkCodeRequestFormProps = Omit<BoxProps, 'onSubmit'> & {
  tikTokVideoId: string
  isLoading: boolean
  status: string
  onSubmit: SubmitHandler<{
    tikTokVideoId: string
    offerPrice: string
    message?: string | undefined
  }>
}

export const SparkCodeRequestForm: FC<SparkCodeRequestFormProps> = ({
  onSubmit,
  tikTokVideoId,
  isLoading,
  status,
  ...rest
}) => {
  return (
    <Box {...rest}>
      <FastForm<SparkCodeRequest>
        schema={sparkCodeRequestSchema}
        formProps={{
          defaultValues: {
            tikTokVideoId,
          },
        }}
        onSubmit={onSubmit}
      >
        <Text fontSize="2xl" fontWeight="bold" mb={2}>
          Request Spark Code
        </Text>
        <Text mb={6}>
          Choose an amount to offer this creator for a 30 day Spark Code.
        </Text>
        <FastFormInput<SparkCodeRequest>
          name="offerPrice"
          helpText="$10 minimum"
        >
          {() => (
            <Controller
              defaultValue=""
              name="offerPrice"
              render={({ field }) => (
                <FastFormInputBase
                  width={150}
                  borderRadius={0}
                  placeholder="$10.00"
                  mb={5}
                  onChange={(e) => {
                    const currentValue = e.target.value
                    // value doesn't contain $ on the first onChange
                    const valueWithoutDollar = currentValue.startsWith('$')
                      ? currentValue.substring(1)
                      : currentValue
                    const isValueNotNumber = isNaN(Number(valueWithoutDollar))
                    if (isValueNotNumber) {
                      e.target.value = ''
                      field.onChange(e)
                      return
                    }
                    e.target.value = valueWithoutDollar
                    field.onChange(e)
                  }}
                  value={field.value ? `$${field.value}` : ''}
                />
              )}
            />
          )}
        </FastFormInput>
        <FastFormTextarea<SparkCodeRequest>
          name="message"
          borderRadius={0}
          placeholder="Add a short message (optional)"
          maxCharacterLimit={250}
        />

        <Text>
          Upon delivery of the spark code, your account will be charged the
          offer amount plus the service fee on your current plan. This will be
          reflected as a line item on your monthly billing within Shopify.
        </Text>

        <FastFormButton
          borderRadius="full"
          isLoading={isLoading}
          isDisabled={status === 'LIVE'}
          mt="5"
          size="lg"
          px={3}
        >
          <Flex px={3}>
            <Text mr={2}>Send</Text>
            <SendIcon width="14px" height="18px"></SendIcon>
          </Flex>
        </FastFormButton>

        {status === 'LIVE' && (
          <Text pt="5"> Cannot request Spark Code for Live Bounty. </Text>
        )}
      </FastForm>
    </Box>
  )
}
