import { Box, Heading, Text } from '@bounty/web-components'
import { FC } from 'react'
import { Page } from '../../../../components/Page'
import { useNavigate } from 'react-router-dom'
import { useWelcomeDispatch } from '../../useWelcome'
import { useAnalytics } from '@bounty/hooks'
import { SnippetExperimentsToggler } from '../../../../components/SnippetsExperimentsToggler'
import { MonthlyBudget } from '../../../Billing/MonthlyBudget'

export const Budget: FC = () => {
  const { track } = useAnalytics()
  const navigate = useNavigate()
  const { setBudgetAmount } = useWelcomeDispatch()

  return (
    <Page data-testid="budget-page" name="Budget">
      <Box marginX="auto" maxWidth="650px" paddingTop={['0', '10']}>
        <Heading as="h1" mb="4" size="lg">
          Let’s talk money.
        </Heading>
        <Heading as="h2" size="lg" mb="2">
          What’s the maximum you’d like to spend each month?
        </Heading>
        <Text>(We cannot guarantee that you’ll spend your entire budget)</Text>
        <MonthlyBudget
          p="0"
          border="none"
          maxWidth="auto"
          submitButtonContent="Next"
          footerComponent={<SnippetExperimentsToggler />}
          onSubmit={(val) => {
            track('Welcome Budget Completed')
            setBudgetAmount(val)
            navigate('../billing')
          }}
        />
      </Box>
    </Page>
  )
}
