import { Analytics, AnalyticsBrowser } from '@segment/analytics-next'
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react'
import { createLogger } from '@bounty/utils'

const logger = createLogger()

type ReturnOrVoid<T extends (...args: any) => any> = (
  ...params: Parameters<T>
) => ReturnType<T> | void

export type AnalyticsState = {
  analytics: Analytics | undefined
  isEnabled: boolean
  track: ReturnOrVoid<Analytics['track']>
  trackClick: ReturnOrVoid<Analytics['trackClick']>
  trackForm: ReturnOrVoid<Analytics['trackForm']>
  trackSubmit: ReturnOrVoid<Analytics['trackSubmit']>
  trackLink: ReturnOrVoid<Analytics['trackLink']>
  alias: ReturnOrVoid<Analytics['alias']>
  identify: ReturnOrVoid<Analytics['identify']>
  page: ReturnOrVoid<Analytics['page']>
  reset: ReturnOrVoid<Analytics['reset']>
}
const AnalyticsStateContext = createContext<AnalyticsState | undefined>(
  undefined,
)

export type AnalyticsProviderProps = {
  isEnabled?: boolean
  writeKey: string
  /** Only log that analytics is not turned on. */
  quiet?: boolean
}

let hasAnalyticsTurnedOffWarningBeenTriggered = false
export const AnalyticsProvider: FC<AnalyticsProviderProps> = ({
  children,
  isEnabled = true,
  writeKey,
  quiet = true,
}) => {
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined)
  useEffect(() => {
    if (isEnabled === false) {
      if (hasAnalyticsTurnedOffWarningBeenTriggered === false) {
        hasAnalyticsTurnedOffWarningBeenTriggered = true
        logger.warn('Analytics is not turned on!')
      }
      return
    }

    const loadAnalytics = async () => {
      const [response] = await AnalyticsBrowser.load({ writeKey })
      setAnalytics(response)
    }
    loadAnalytics()
  }, [writeKey, isEnabled])

  /**
   * This method gracefully handles if we try to use analytics methods in our code when Analytics
   * is either not isEnabled OR it's not loaded yet. It keeps us from needing to use ? marks everywhere.
   */
  const callAnalyticsSafe = useCallback(
    <T extends keyof Analytics>(method: T) =>
      (
        ...params: Parameters<Analytics[T]>
      ): ReturnType<Analytics[T]> | void => {
        if (!analytics) {
          if (quiet === false && isEnabled === false) {
            logger.warn(
              `Cannot call analytics.${method} because analytics is not isEnabled. Ignoring!`,
            )
            return
          }

          if (quiet === false) {
            logger.warn(
              `Cannot call analytics.${method} because analytics is not initialized. Ignoring event, please try again.`,
            )
          }
          return
        }

        // @ts-ignore
        return analytics[method](...params)
      },

    [analytics, isEnabled, quiet],
  )

  const track: AnalyticsState['track'] = useCallback(
    (...args) => callAnalyticsSafe('track')(...args),
    [callAnalyticsSafe],
  )
  const trackClick: AnalyticsState['trackClick'] = useCallback(
    (...args) => callAnalyticsSafe('trackClick')(...args),
    [callAnalyticsSafe],
  )
  const trackForm: AnalyticsState['trackForm'] = useCallback(
    (...args) => callAnalyticsSafe('trackForm')(...args),
    [callAnalyticsSafe],
  )
  const trackSubmit: AnalyticsState['trackSubmit'] = useCallback(
    (...args) => callAnalyticsSafe('trackSubmit')(...args),
    [callAnalyticsSafe],
  )
  const trackLink: AnalyticsState['trackLink'] = useCallback(
    (...args) => callAnalyticsSafe('trackLink')(...args),
    [callAnalyticsSafe],
  )
  const alias: AnalyticsState['alias'] = useCallback(
    (...args) => callAnalyticsSafe('alias')(...args),
    [callAnalyticsSafe],
  )
  const identify: AnalyticsState['identify'] = useCallback(
    (...args) => callAnalyticsSafe('identify')(...args),
    [callAnalyticsSafe],
  )
  const page: AnalyticsState['page'] = useCallback(
    (...args) => callAnalyticsSafe('page')(...args),
    [callAnalyticsSafe],
  )
  const reset: AnalyticsState['reset'] = useCallback(
    () => callAnalyticsSafe('reset'),
    [callAnalyticsSafe],
  )

  return (
    <AnalyticsStateContext.Provider
      value={{
        analytics,
        isEnabled,
        track,
        trackClick,
        trackForm,
        trackSubmit,
        trackLink,
        alias,
        identify,
        page,
        reset,
      }}
    >
      {children}
    </AnalyticsStateContext.Provider>
  )
}

export const useAnalytics = () => {
  const context = useContext(AnalyticsStateContext)

  if (!context) {
    throw new Error('useAnalytics must be used within a Analytics.')
  }

  return context
}
