import { Creators } from './Creators/Creators'
// import { Favorites } from './Favorites/Favorites'
import { Home } from './Home/Home'
import { Products } from './Products/Products'
import { Settings } from './Settings/Settings'
import { Support } from './Support/Support'
import {
  SpeedometerIcon,
  CodeSlashIcon,
  PriceTagIcon,
  UserIcon,
  SettingsIcon,
  // StarIcon,
  SupportIcon,
  BillingIcon,
  ContentLibraryIcon,
  DollarIcon,
  AnnouncementsIcon,
  MailIcon,
} from '@bounty/icons'
import { AppRoute } from '@bounty/web-components'
import { Billing } from './Billing/Billing'
import { Snippets } from './UserExperiences/UserExperiences'
import { ContentLibrary } from './ContentLibrary/ContentLibrary'
import { ContentPricing } from './ContentPricingSettings/ContentPricingSettings'
import { Announcements } from './Announcements/Announcements'
import { NotificationPreferences } from './Notifications/NotificationPreferences'

export const INTERIOR_NAV_ROUTES: AppRoute[] = [
  {
    isExternal: false,
    displayPath: '/',
    routerPath: '/',
    name: 'Dashboard',
    icon: <SpeedometerIcon />,
    component: Home,
  },
  {
    isExternal: false,
    displayPath: '/content-library',
    routerPath: '/content-library/*',
    name: 'Content Library',
    icon: <ContentLibraryIcon />,
    component: ContentLibrary,
  },
  {
    isExternal: false,
    displayPath: '/user-experiences',
    routerPath: '/user-experiences/*',
    name: 'User Experiences',
    icon: <CodeSlashIcon />,
    component: Snippets,
  },
  {
    isExternal: false,
    displayPath: '/products',
    routerPath: '/products/*',
    name: 'Products',
    icon: <PriceTagIcon />,
    component: Products,
  },
  {
    isExternal: false,
    displayPath: '/creators',
    routerPath: '/creators',
    name: 'Creators',
    icon: <UserIcon />,
    component: Creators,
  },
  {
    isExternal: false,
    displayPath: '/creator-incentives',
    routerPath: '/creator-incentives',
    name: 'Creator Incentives',
    icon: <DollarIcon />,
    component: ContentPricing,
  },
  {
    isExternal: false,
    displayPath: '/billing',
    routerPath: '/billing',
    name: 'Billing',
    icon: <BillingIcon />,
    component: Billing,
  },
  {
    isExternal: false,
    displayPath: '/notifications',
    routerPath: '/notifications',
    name: 'Notifications',
    icon: <MailIcon />,
    component: NotificationPreferences,
  },

  {
    isExternal: false,
    displayPath: '/settings',
    routerPath: '/settings',
    name: 'Settings',
    icon: <SettingsIcon />,
    component: Settings,
  },
  {
    isExternal: false,
    displayPath: '/announcements',
    routerPath: '/announcements/*',
    name: 'Announcements',
    icon: <AnnouncementsIcon />,
    component: Announcements,
  },
  // {
  //   isExternal: false,
  //   displayPath: '/favorites',
  //   routerPath: '/favorites',
  //   name: 'Favorites',
  //   icon: <StarIcon />,
  //   component: Favorites,
  // },
  {
    isExternal: false,
    displayPath: '/support',
    routerPath: '/support',
    name: 'Support',
    icon: <SupportIcon />,
    component: Support,
  },
]
