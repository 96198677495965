import { PageHeader } from '@bounty/web-components'
import { FC } from 'react'
import { Page } from '../../components/Page'
import { CreatorsTable } from './CreatorsTable'

export type CreatorsProps = unknown

export const Creators: FC<CreatorsProps> = () => {
  return (
    <Page
      name="Creators"
      fixedHeight="all"
      fluid
      addBottomSpacing={false}
      paddingBottom="3rem"
      width="100%"
    >
      <PageHeader>Creators</PageHeader>
      <CreatorsTable />
    </Page>
  )
}
