import { FC, useEffect } from 'react'
import { Routes, Route, Outlet, Navigate, Link } from 'react-router-dom'
import { Login } from './pages/Login/Login'
import { LoginSuccess } from './pages/LoginSuccess/LoginSuccess'
import {
  RequireAuth,
  InteriorAppWrapper,
  IconButton,
  HStack,
  Tooltip,
} from '@bounty/web-components'
import { useAuthState, useAuthDispatch } from './hooks/useAuth'
import {
  LD_CLIENT_ID,
  SEGMENT_WRITE_KEY,
  IS_BUILT_TARGETING_AN_ENVIRONMENT,
} from './config/env'
import {
  AnalyticsProvider,
  useAnalytics,
  LaunchDarklyProvider,
  useScrollToTop,
} from '@bounty/hooks'
import { Welcome } from './pages/Welcome/Welcome'
import { RequireWelcomeFlow } from './pages/Welcome/RequireWelcomeFlow/RequireWelcomeFlow'
import { useSubscriptionSuccess } from './hooks/useSubscriptionSuccess'
import { INTERIOR_NAV_ROUTES } from './pages/interiorNavRoutes'
import { SupportIcon, LogoutIcon } from '@bounty/icons'
import * as Sentry from '@sentry/react'
import { useFlag } from './hooks/useFlag'

const AuthWrapper: FC = () => {
  const { user } = useAuthState()
  const { identify } = useAnalytics()

  useEffect(() => {
    if (!user) return
    Sentry.setUser({ id: user.shopUrl })
    identify(user.sessionId, {
      shopifyUrl: user.shopUrl,
    })
  }, [identify, user])

  return <Outlet />
}

const AuthedProviders: FC = () => {
  const { shopifyStoreUrl, isLoading, isAuthed } = useAuthState()
  return (
    <RequireAuth isAuthed={isAuthed} isLoading={isLoading}>
      <LaunchDarklyProvider
        clientSideID={LD_CLIENT_ID}
        user={{ key: shopifyStoreUrl }}
        isEnabled={IS_BUILT_TARGETING_AN_ENVIRONMENT}
      >
        <AnalyticsProvider
          isEnabled={IS_BUILT_TARGETING_AN_ENVIRONMENT}
          writeKey={SEGMENT_WRITE_KEY}
        >
          <RequireWelcomeFlow>
            <AuthWrapper />
          </RequireWelcomeFlow>
        </AnalyticsProvider>
      </LaunchDarklyProvider>
    </RequireAuth>
  )
}

const TopNavRight: FC = () => {
  const { logout } = useAuthDispatch()
  return (
    <HStack>
      <Tooltip label="Support">
        <IconButton
          aria-label="support-button"
          icon={<SupportIcon />}
          as={Link}
          to="/support"
          borderRadius="full"
        />
      </Tooltip>
      <Tooltip label="Logout">
        <IconButton
          aria-label="logout-button"
          icon={<LogoutIcon />}
          borderRadius="full"
          onClick={logout}
        />
      </Tooltip>
    </HStack>
  )
}

const AuthedAppWrapper: FC = () => {
  const showBrandNotificationPreferences = useFlag(
    'showBrandNotificationPreferences',
  )
  return (
    <InteriorAppWrapper
      leftNavItems={INTERIOR_NAV_ROUTES.filter((x) => {
        if (x.name === 'Notifications') {
          return showBrandNotificationPreferences
        }

        return true
      })}
      showBetaLogo={true}
      topNavProps={{
        rightComponent: <TopNavRight />,
      }}
    />
  )
}

export const AuthedApp: FC = () => {
  useSubscriptionSuccess()

  return (
    <Routes>
      <Route element={<AuthedProviders />}>
        <Route element={<AuthedAppWrapper />}>
          {INTERIOR_NAV_ROUTES.map((route) => {
            if (route.isExternal === false) {
              const { component: Component, routerPath } = route

              return (
                <Route
                  key={routerPath}
                  path={routerPath}
                  element={<Component />}
                />
              )
            }

            return null
          })}
        </Route>
        <Route element={<Welcome />} path="/welcome/*" />

        {/* Redirect to home on path not found. */}
        <Route element={<Navigate to="/" />} path="*" />
      </Route>
    </Routes>
  )
}

export const App: FC = () => {
  useScrollToTop()

  return (
    <Routes>
      <Route element={<AuthedApp />} path="/*" />
      <Route element={<Login />} path="/login" />
      <Route element={<Login variant="signUp" />} path="/sign-up" />
      <Route element={<LoginSuccess />} path="/login-success" />
    </Routes>
  )
}
