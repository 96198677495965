import { z } from 'zod'

export const SnippetDisplayCheckQuerySchema = z
  .object({
    shop: z.string(),
    productId: z.string().optional(),
    location: z.string().transform((x) => x.toUpperCase()),
    price: z
      .string()
      .optional()
      .transform((x: any) => parseFloat(x) ?? 0),
    views: z
      .string()
      .optional()
      .transform((x: any) => parseFloat(x) ?? 0),
  })
  .strict()

// This type is technically wrong, the schema expects price to be a string and then it transforms it to a number
export type SnippetDisplayCheckQuerySchemaType = z.infer<
  typeof SnippetDisplayCheckQuerySchema
>

export const SnippetSubmissionSchema = z.object({
  sms: z.string().min(7),
  email: z.string().email().optional(),
  tikTok: z.string().min(3).optional(),
  shopUrl: z
    .string()
    .min(10)
    .regex(/myshopify\.com/gi), // it needs to have at least "myshopify.com"
  shopifyProductId: z.string().optional(),
})

export type SnippetSubmissionType = z.infer<typeof SnippetSubmissionSchema>

export const SnippetCheckOrderEligibility = z.object({
  email: z.string().email().optional(),
  sms: z.string().min(7).optional(),
  // https://community.shopify.com/c/shopify-discussions/what-happens-after-order-9999/td-p/407374
  orderNumber: z.string().min(4),
  shopUrl: z
    .string()
    .min(10)
    .regex(/myshopify\.com/gi), // it needs to have at least "myshopify.com"
})

export type SnippetCheckOrderEligibilityType = z.infer<
  typeof SnippetCheckOrderEligibility
>

export const SnippetEstimatedPayoutForViews = z.object({
  views: z.number().min(0),
  shopUrl: z
    .string()
    .min(10)
    .regex(/myshopify\.com/gi), // it needs to have at least "myshopify.com"
})

export type SnippetEstimatedPayoutForViewsType = z.infer<
  typeof SnippetEstimatedPayoutForViews
>
