import * as z from 'zod'

export const contentExplorerParamsSchema = z.object({
  shop: z.string().optional(),
  skip: z.number().optional().nullable(),
  take: z.number().optional().nullable(),
  orderBy: z
    .enum(['CREATED_AT', 'FEEDBACK_RATING', 'LIKES', 'PAYOUT_VALUE', 'VIEWS'])
    .optional()
    .nullable(),
  orderByDirection: z.enum(['ASC', 'DESC']).optional().nullable(),
  filterMinimumRating: z.number().min(0).max(10).optional().nullable(),
  filterBountyStatus: z
    .enum([
      'PENDING',
      'ACCEPTED',
      'LIVE',
      'COMPLETED',
      'REJECTED',
      'APPROVED',
      'HOLD',
    ])
    .optional()
    .nullable(),
  filterByProfileNames: z.array(z.string().uuid()).optional().nullable(),
  filterByCreatedAt: z
    .enum(['ALL_TIME', 'LAST_7_DAYS', 'LAST_30_DAYS', 'LAST_DAY'])
    .optional()
    .nullable(),
  isFavorited: z.boolean().optional().nullable(),
})

export type ContentExplorerParams = z.infer<typeof contentExplorerParamsSchema>
