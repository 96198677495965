import { FC, ReactNode } from 'react'
import { Box, Link, Text, Flex, Button, Logo } from '@bounty/web-components'
import { useAuthDispatch } from '../hooks/useAuth'
import { useFlag } from '../hooks/useFlag'

export const WelcomeNav: FC<{
  assumeSpacing?: boolean
  centerComponent?: ReactNode
}> = ({ assumeSpacing = false, centerComponent }) => {
  const { logout } = useAuthDispatch()
  const showLogoutButton = useFlag('showLogoutButton')

  return (
    <Flex
      {...(assumeSpacing
        ? {
            maxWidth: '1500px',
            margin: '0 auto',
            paddingX: ['1rem', '2rem', '3rem', '4rem'],
          }
        : {})}
      alignItems="center"
      justifyContent="space-between"
      paddingY="8"
    >
      <Flex justifyContent="flex-start" flex="1">
        <Logo style={{ width: '80px' }} />
      </Flex>
      {centerComponent ? (
        <Flex
          justifyContent="center"
          flex="1"
          display={['none', 'none', 'flex']}
        >
          {centerComponent}
        </Flex>
      ) : null}
      <Flex flex="1" alignItems="center" justifyContent="flex-end">
        <Box>
          <Text fontSize={['xs', 'sm']} textAlign="right">
            Need support? Email us at:
          </Text>
          <Text fontSize={['xs', 'sm']} textAlign="right">
            <Link
              isExternal
              colorScheme="blue"
              href="mailto:support@bounty.co?subject=Bounty Support Request"
            >
              support@bounty.co
            </Link>
          </Text>
        </Box>
        {showLogoutButton && (
          <Button ml="4" onClick={logout} size={'sm'}>
            Logout
          </Button>
        )}
      </Flex>
    </Flex>
  )
}
