import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import './Grid.css'
import { AgGridReact, AgGridReactProps } from 'ag-grid-react'
import {
  Box,
  BoxProps,
  useMergeRefs,
  Input,
  Flex,
  Text,
} from '@bounty/web-components'
import { MutableRefObject, ReactNode, useRef } from 'react'
import { ColDef } from 'ag-grid-community'
import { prettyNumber } from '@bounty/utils'

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
type GridProps<TData extends { id: string } = any> = AgGridReactProps<TData> & {
  containerProps?: BoxProps
  gridRef?:
    | ((node: AgGridReact<TData> | null) => void)
    | MutableRefObject<AgGridReact<TData> | null>
    | null
  /**
   * Show quick search bar for the grid
   */
  showQuickSearch?: boolean
  showFooter?: boolean
  rightComponent?: ReactNode
  leftComponent?: ReactNode
}

const defaultColDef: ColDef = {
  flex: 1,
  resizable: true,
  sortable: true,
  filter: true,
  cellStyle: () => ({
    display: 'flex',
    alignItems: 'center',
  }),
}

// If you're using TSX you have to use extends otherwise your IDE can't infer what you're doing
// You also can't use forward refs with a generic component :(
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const Grid = <TData extends { id: string } = any>({
  containerProps,
  gridRef = null,
  showQuickSearch = true,
  showFooter = true,
  rightComponent = null,
  leftComponent = null,
  defaultColDef: defaultColDefProp,
  rowData,
  ...rest
}: GridProps<TData>) => {
  const internalRef = useRef<AgGridReact<TData> | null>(null)
  const refs = useMergeRefs(internalRef, gridRef)

  return (
    <Box flex="1" display="flex" flexDirection="column">
      {showQuickSearch || !!rightComponent || !!leftComponent ? (
        <Box
          mb="4"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="center">
            <Input
              placeholder="Search"
              width="180px"
              type="search"
              mr="4"
              onChange={(e) => {
                internalRef?.current?.api.setQuickFilter(e.target.value)
              }}
            />
            <Box>{leftComponent}</Box>
          </Box>
          <Box>{rightComponent}</Box>
        </Box>
      ) : null}
      <Box
        className="ag-theme-alpine"
        flex="1"
        height="100%"
        {...containerProps}
      >
        <AgGridReact
          suppressCellFocus
          // animateRows
          rowHeight={50}
          defaultColDef={{ ...defaultColDef, ...defaultColDefProp }}
          ref={refs}
          suppressScrollOnNewData={true}
          getRowId={(d) => d.data.id}
          rowData={rowData}
          {...rest}
        ></AgGridReact>
      </Box>
      {showFooter && (
        <Flex pt="2" justifyContent="flex-end">
          {rowData && (
            <Text fontSize="sm" color="gray.600">
              {prettyNumber(rowData.length)} results
            </Text>
          )}
        </Flex>
      )}
    </Box>
  )
}
