import {
  Box,
  Button,
  Text,
  PageSkeleton,
  BoxProps,
  Flex,
  useBounty,
  Link,
} from '@bounty/web-components'
import { FC, ReactNode } from 'react'
import { useMutationBackend, useQueryBackend } from '../../apollo/backend/hooks'
import {
  BillingDocument,
  AddSubscriptionDocument,
  BillingQuery,
} from '../../generated/backendGraphql'
import { prettyCurrency, prettyPercentage } from '@bounty/utils'
import PricingSprinklesLight from '../../images/sprinkles-pricing-light.png'
import PricingSprinklesDark from '../../images/sprinkles-pricing-dark.png'

export type BillingTilesProps = {
  budgetedAmount: number
  discountDetails?: {
    status?: boolean
    price?: string | null | undefined
  }
  onSubscriptionClicked: BillingTileProps['onSubscriptionClicked']
  discountCode?: string
  isPricingHidden?: boolean
  isSignupHidden?: boolean
}

type ReceiptComponentConfig = {
  showBorder: boolean
  backgroundColor: string
  borderColor?: string
  gradientConfig?: ReactNode
  gradientId?: string
}

type BillingTileProps = BoxProps & {
  isLight: boolean
  isLoading: boolean
  topReceiptConfig: ReceiptComponentConfig
  bottomReceiptConfig: ReceiptComponentConfig
  subscriptionOption: BillingQuery['subscriptionOptions'][0]
  isPricingHidden?: boolean
  isSignupHidden?: boolean
  onSubscriptionClicked: (
    option: BillingTileProps['subscriptionOption'],
  ) => void
}

const BillingTile: FC<BillingTileProps> = ({
  isLight,
  isLoading,
  topReceiptConfig: {
    backgroundColor: topBackgroundColor,
    borderColor: topBorderColor,
    showBorder: topShowBorder,
    gradientConfig: topGradientConfig,
    gradientId: topGradientId,
  },
  bottomReceiptConfig: {
    backgroundColor: bottomBackgroundColor,
    borderColor: bottomBorderColor,
    showBorder: bottomShowBorder,
    gradientConfig: bottomGradientConfig,
    gradientId: bottomGradientId,
  },
  subscriptionOption,
  onSubscriptionClicked,
  isPricingHidden = false,
  isSignupHidden = false,
  ...rest
}) => {
  const { supportEmail } = useBounty()

  return (
    <Box mb="12" {...rest}>
      <Box width="311px" position={'relative'} mr="8px">
        <Box
          position="absolute"
          background={bottomBackgroundColor}
          left="8px"
          top="12px"
          height="100%"
          width="100%"
        >
          <svg
            width="312"
            height="15"
            viewBox="0 0 312 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              position: 'absolute',
              // 1 minus the height for the top part of the stroke
              bottom: '-14px',
              left: '0px',
              right: '0px',
            }}
          >
            <path
              d="M311 0L0 9.15527e-05V11.6656L5.57276 5.75484L13.649 14.0939L21.5298 5.95657L29.4107 14.0939L37.2916 5.95657L45.1724 14.0939L53.0533 5.95657L60.9341 14.0939L68.815 5.95657L76.6959 14.0939L84.5767 5.95657L92.4576 14.0939L100.336 5.95858L108.215 14.0939L116.096 5.95657L123.977 14.0939L131.858 5.95656L139.739 14.0939L147.62 5.95656L155.501 14.0939L163.381 5.95656L171.262 14.0939L179.143 5.95656L187.024 14.0939L194.905 5.95656L202.786 14.0939L210.665 5.95858L218.544 14.0939L226.424 5.95656L234.305 14.0939L242.186 5.95656L250.067 14.0939L257.948 5.95656L265.829 14.0939L273.71 5.95657L281.59 14.0939L289.471 5.95657L297.352 14.0939L305.35 5.83552L311 11.6692V0.00182465L311.002 9.15527e-05L311 0Z"
              stroke={bottomShowBorder ? bottomBorderColor : 'none'}
              fill={
                bottomGradientId
                  ? `url(#${bottomGradientId})`
                  : bottomBackgroundColor
              }
            />
            {bottomGradientConfig}
          </svg>
          <Box
            // Needs to be included in the stacking context
            position={'relative'}
            background={bottomBackgroundColor}
            border={bottomShowBorder ? '1px solid' : 'none'}
            borderColor={bottomBorderColor}
            borderBottom="none"
            height="100%"
          ></Box>
        </Box>
        <Box backgroundColor={topBorderColor} height="100%" width="100%">
          <svg
            width="312"
            style={{
              position: 'absolute',
              // 1 minus the height for the top part of the stroke
              bottom: '-13px',
              left: '0px',
              right: '0px',
            }}
            height="15"
            viewBox="0 0 312 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M310.501 0.5V10.4342L305.71 5.48767L305.351 5.11681L304.992 5.48767L297.353 13.3752L289.831 5.60871L289.472 5.23786L289.113 5.60871L281.591 13.3752L274.069 5.60871L273.71 5.23786L273.351 5.60871L265.829 13.3752L258.308 5.60871L257.948 5.23785L257.589 5.60871L250.068 13.3752L242.546 5.6087L242.187 5.23784L241.828 5.6087L234.306 13.3752L226.784 5.60871L226.425 5.23785L226.066 5.60871L218.544 13.3752L211.024 5.61073L210.665 5.23987L210.306 5.61073L202.786 13.3752L195.265 5.60871L194.905 5.23785L194.546 5.60871L187.025 13.3752L179.503 5.60871L179.144 5.23785L178.785 5.60871L171.263 13.3752L163.741 5.60871L163.382 5.23785L163.023 5.60871L155.501 13.3752L147.979 5.60871L147.62 5.23785L147.261 5.60871L139.739 13.3752L132.218 5.60871L131.859 5.23785L131.499 5.60871L123.978 13.3752L116.456 5.60871L116.097 5.23785L115.738 5.60871L108.216 13.3752L100.696 5.61073L100.337 5.23987L99.9779 5.61073L92.4582 13.3752L84.9365 5.60871L84.5773 5.23785L84.2181 5.60871L76.6964 13.3752L69.1748 5.60871L68.8156 5.23785L68.4564 5.60871L60.9347 13.3752L53.413 5.60871L53.0539 5.23785L52.6947 5.60871L45.173 13.3752L37.6513 5.60871L37.2922 5.23785L36.933 5.60871L29.4113 13.3752L21.8896 5.60871L21.5304 5.23785L21.1713 5.60871L13.6496 13.3752L5.93252 5.40699L5.5685 5.03112L5.20955 5.41184L0.500531 10.4065L0.500026 0.500084L310.501 0.5Z"
              fill={
                topGradientId ? `url(#${topGradientId})` : topBackgroundColor
              }
              stroke={topShowBorder ? topBorderColor : 'none'}
            />
            {topGradientConfig}
          </svg>
          <Box
            // Needs to be included in the stacking context
            position={'relative'}
            background={`${topBackgroundColor} url(${
              isLight ? PricingSprinklesLight : PricingSprinklesDark
            }) no-repeat center center`}
            border={topShowBorder ? '1px solid' : 'none'}
            borderColor={topBorderColor}
            borderBottom="none"
            height="100%"
            p="8"
          >
            <Text
              fontSize="3xl"
              fontWeight="bold"
              mb="4"
              color={isLight ? 'gray.800' : 'warmGray.100'}
            >
              {subscriptionOption.name}
            </Text>
            <Text
              minHeight={'50px'}
              mb="6"
              color={isLight ? 'gray.800' : 'warmGray.500'}
            >
              {subscriptionOption.terms}
            </Text>
            <Text color="warmGray.600" mb="2">
              Subscription Fee
            </Text>
            <Text
              mb="6"
              fontSize="2xl"
              fontWeight="bold"
              color={isLight ? 'gray.800' : 'warmGray.100'}
            >
              {isPricingHidden
                ? '--'
                : prettyCurrency(Number(subscriptionOption.price))}
            </Text>
            <Flex mb="8">
              <Box flex={1}>
                <Text color={isLight ? 'warmGray.600' : 'warmGray.500'}>
                  Per Video
                </Text>
                <Text
                  color={isLight ? 'gray.800' : 'warmGray.100'}
                  fontWeight="semibold"
                  fontSize="xl"
                >
                  {isPricingHidden
                    ? '--'
                    : `${prettyCurrency(subscriptionOption.approvalBaseFee, {
                        precision: 2,
                      })} + ${prettyPercentage(
                        subscriptionOption.serviceFee / 100,
                      )}`}
                </Text>
              </Box>
            </Flex>
            <Text
              mb="6"
              fontWeight="semibold"
              color={isLight ? 'gray.800' : 'warmGray.500'}
            >
              6 month commitment, first {subscriptionOption.trialDays} days
              free.
            </Text>
            {isSignupHidden ? (
              <Button
                isLoading={isLoading}
                colorScheme={isLight ? 'blackAlpha' : 'whiteAlpha'}
                variant="outline"
                size="lg"
                borderRadius={'full'}
                as={Link}
                href={`mailto:${supportEmail}?subject=Enterprise plan help`}
                isExternal
              >
                Contact Us
              </Button>
            ) : (
              <Button
                isLoading={isLoading}
                onClick={() => onSubscriptionClicked(subscriptionOption)}
                colorScheme={isLight ? 'blackAlpha' : 'whiteAlpha'}
                variant="outline"
                size="lg"
                borderRadius={'full'}
              >
                Choose
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export const BillingTiles: FC<BillingTilesProps & BoxProps> = ({
  budgetedAmount,
  onSubscriptionClicked: onSubscriptionClickedProp,
  discountDetails,
  discountCode,
  ...rest
}) => {
  const { data, loading } = useQueryBackend(BillingDocument)
  const [addSubscription, { loading: subscriptionSubmitLoading }] =
    useMutationBackend(AddSubscriptionDocument)

  if (!data || loading) return <PageSkeleton />
  const growthPlan = data.subscriptionOptions[1]
  const customPlan = data.subscriptionOptions[2]

  const onSubscriptionClicked: BillingTileProps['onSubscriptionClicked'] =
    async (subscriptionOption) => {
      const { data } = await addSubscription({
        variables: {
          customCappedAmount: `${budgetedAmount}`,
          id: subscriptionOption.id,
          discountCode,
        },
      })

      /**
       * Note - this can fail for a reason - you have a current
       * active subscription with a non-zero balance on usage charges.
       *
       */
      await onSubscriptionClickedProp(subscriptionOption)

      if (data?.addSubscription.confirmation_url) {
        window.location.assign(data.addSubscription.confirmation_url)
      }
    }

  return (
    <Box px={['4', '8']} {...rest}>
      <Box display="flex" flexWrap={'wrap'}>
        <BillingTile
          isLoading={subscriptionSubmitLoading}
          mr="6"
          onSubscriptionClicked={onSubscriptionClicked}
          isLight
          topReceiptConfig={{
            showBorder: true,
            backgroundColor: '#E9D8C9',
            borderColor: '#242424',
          }}
          bottomReceiptConfig={{
            showBorder: false,
            backgroundColor: '#BA895F',
          }}
          subscriptionOption={growthPlan}
        />
        <BillingTile
          isLoading={subscriptionSubmitLoading}
          mr="6"
          onSubscriptionClicked={onSubscriptionClicked}
          isLight={false}
          topReceiptConfig={{
            showBorder: false,
            backgroundColor: '#151513',
          }}
          bottomReceiptConfig={{
            showBorder: false,
            backgroundColor:
              'linear-gradient(149.91deg, #F2AB47 -1.27%, #FFC0E6 54.58%, #F2AB47 88.83%)',
            gradientId: 'paint0_linear_44_116',
            gradientConfig: (
              <defs>
                <linearGradient
                  id="paint0_linear_44_116"
                  x1="0.000515088"
                  y1="-332.5"
                  x2="225"
                  y2="2.49992"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F2AB47" />
                  <stop offset="0.62572" stopColor="#FFC0E6" />
                  <stop offset="1" stopColor="#F2AB47" />
                </linearGradient>
              </defs>
            ),
          }}
          subscriptionOption={customPlan}
          isPricingHidden={true}
          isSignupHidden={true}
        />
      </Box>
    </Box>
  )
}
