import { Text, TextProps } from '@chakra-ui/react'
import { FC } from 'react'

export type ErrorTextProps = TextProps

export const ErrorText: FC<ErrorTextProps> = ({ children, ...rest }) => {
  return (
    <Text data-element-name="error-text" color="red.700" {...rest}>
      {children}
    </Text>
  )
}
