import { PageSkeleton } from '@bounty/web-components'
import { FC } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useQueryBackend } from '../../../apollo/backend/hooks'
import { RequireWelcomeFlowDocument } from '../../../generated/backendGraphql'

export type RequireWelcomeFlowProps = unknown

export const RequireWelcomeFlow: FC<RequireWelcomeFlowProps> = ({
  children,
}) => {
  const location = useLocation()
  const { loading, data } = useQueryBackend(RequireWelcomeFlowDocument)

  if (loading || !data) {
    return <PageSkeleton data-testid="require-welcome-flow-loading" />
  }

  if (location.pathname.includes('/welcome')) {
    return <>{children}</>
  }

  // Only show them app if they have a current subscription
  if (data.currentSubscription != null) {
    return <>{children}</>
  }

  return <Navigate to={'/welcome/get-started'} />
}
