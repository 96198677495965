export const DEFAULT_PRODUCT_HEADLINE =
  'Share your experience with the product. Talking to the camera or including a voiceover often performs better.'

export const DEFAULT_PRODUCT_FEATURE_1 =
  'Use relevant hashtags for more reach! #momtok, #cleantok, #cleanbeauty, etc.'

export const DEFAULT_PRODUCT_FEATURE_2 = `Try a hook like “My go-to X for Y…” or “Things TikTok made me buy…”`

export const DEFAULT_PRODUCT_FEATURE_3 = `Have fun with it! Don’t make ads, make TikToks!`

export const DEFAULT_PRODUCT_FEATURES = [
  DEFAULT_PRODUCT_FEATURE_1,
  DEFAULT_PRODUCT_FEATURE_2,
  DEFAULT_PRODUCT_FEATURE_3,
]
