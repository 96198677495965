import {
  DEFAULT_PRODUCT_FEATURES,
  DEFAULT_PRODUCT_HEADLINE,
} from '@bounty/constants'
import { BoxProps } from '@bounty/web-components'
import { useQueryBackend } from '../../../apollo/backend/hooks'
import { ProductInstructionEditDefaultDocument } from '../../../generated/backendGraphql'
import {
  formatInstructionForForm,
  ProductInstructionBase,
} from './ProductInstructionBase'

type ProductInstructionEditDefaultProps = BoxProps

export const ProductInstructionEditDefault = ({
  ...rest
}: ProductInstructionEditDefaultProps) => {
  const { data, loading } = useQueryBackend(
    ProductInstructionEditDefaultDocument,
  )

  return (
    <ProductInstructionBase
      variant={'update'}
      isDefault={true}
      isLoading={loading}
      {...(data != null
        ? {
            formProps: {
              defaultValues: data.storeProductInstructionDefault
                ? formatInstructionForForm(data.storeProductInstructionDefault)
                : {
                    name: 'Default',
                    headline: DEFAULT_PRODUCT_HEADLINE,
                    features: DEFAULT_PRODUCT_FEATURES,
                  },
            },
          }
        : {})}
      {...rest}
    />
  )
}
