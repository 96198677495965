import { ContentLibraryInput } from '../../generated/backendGraphql'
import { isNil, isString } from '@bounty/utils'
import * as z from 'zod'

export const DEFAULT_PARAMS = {
  filterBountyStatus: 'APPROVED',
  filterByCreatedAt: 'ALL_TIME',
  filterByProfileNames: [],
  orderBy: 'VIEWS',
  orderByDirection: 'DESC',
  skip: 0,
  // We get 20 because if we get 10 then infinite scroll will kick in and get 10 more anyways (2 XHRs)
  take: 20,
} as const

const stringToArray = z.preprocess((s: unknown) => {
  if (Array.isArray(s)) return s
  if (!isString(s) || s === '') return null
  return s.split(',')
}, z.string().array().default([]))

export const schema = z.object({
  orderBy: z
    .enum(['VIEWS', 'CREATED_AT', 'FEEDBACK_RATING', 'LIKES', 'PAYOUT_VALUE'])
    .default('VIEWS'),
  orderByDirection: z.enum(['ASC', 'DESC']).default('DESC'),
  filterBountyStatus: z
    .enum(['COMPLETED', 'ACCEPTED', 'LIVE', 'PENDING', 'REJECTED', 'APPROVED'])
    .default('APPROVED'),
  filterByCreatedAt: z
    .enum(['ALL_TIME', 'LAST_7_DAYS', 'LAST_30_DAYS', 'LAST_DAY'])
    .default('ALL_TIME'),
  filterByProfileNames: stringToArray.default([]),
  isFavorited: z.preprocess(Boolean, z.boolean()).optional(),
})

export const getDefaultParams = (
  searchParams: URLSearchParams,
): ContentLibraryInput => {
  const result = schema.safeParse(Object.fromEntries(searchParams))
  return result.success ? { ...DEFAULT_PARAMS, ...result.data } : DEFAULT_PARAMS
}

export const convertToSearchParams = (input: ContentLibraryInput) => {
  const result: Record<string, string> = {}
  const changed = diff(DEFAULT_PARAMS, input)
  Object.entries(changed).forEach(([k, v]) => {
    if (isNil(v)) return
    result[k] = String(v)
  })
  return new URLSearchParams(result)
}

interface AnyMap {
  [key: string]: any
}
const diff = (first: AnyMap, second: AnyMap) =>
  Object.keys(second).reduce((acc, key) => {
    if (JSON.stringify(first[key]) === JSON.stringify(second[key])) return acc
    return {
      ...acc,
      [key]: second[key],
    }
  }, {})
