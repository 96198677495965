import { BOUNTY_FEATURES, isFeatureAvailableForPlan } from '@bounty/common'
import { CheckmarkIcon, CloseIcon } from '@bounty/icons'
import {
  TableContainer,
  Table,
  Tbody,
  Td,
  Tr,
  Thead,
  Th,
} from '@bounty/web-components'

export const FeatureTable = () => {
  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th></Th>
            <Th color="black" fontSize="xl" textTransform={'none'}>
              Growth
            </Th>
            <Th color="black" fontSize="xl" textTransform={'none'}>
              Custom
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {BOUNTY_FEATURES.map((feature) => {
            return (
              <Tr key={feature.code}>
                <Td width="400px" fontWeight="bold">
                  {feature.name}
                </Td>
                <Td width="310px">
                  {isFeatureAvailableForPlan(feature.code)('V2_PRO_PLAN') ? (
                    <CheckmarkIcon boxSize="28px" />
                  ) : (
                    <CloseIcon boxSize="28px" opacity={0.3} />
                  )}
                </Td>
                <Td width="310px">
                  {isFeatureAvailableForPlan(feature.code)(
                    'V2_ENTERPRISE_PLAN',
                  ) ? (
                    <CheckmarkIcon boxSize="28px" />
                  ) : (
                    <CloseIcon boxSize="28px" opacity={0.3} />
                  )}
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
