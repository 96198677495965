import {
  Flex,
  Box,
  PageHeader,
  Skeleton,
  VStack,
  Select,
  Tile,
  Text,
} from '@bounty/web-components'
import { FC, useState, useMemo } from 'react'
import { Page } from '../../components/Page'

import { RecentActivity } from '../../components/RecentActivity'
import { NoEmbedsConfigured } from '../../components/NoEmbedsConfigured'
import { Metrics } from './Metrics'
import { DateRangeDisplay } from '../../components/DateRangeDisplay'
import {
  UnreachableCaseError,
  getLastWeek,
  getLastMonth,
  getLastYear,
  getLast30Days,
  getLast3Months,
  getLast7Days,
  getYesterday,
  getToday,
} from '@bounty/utils'
import { useQueryBackend } from '../../apollo/backend/hooks'
import { HomeDocument, TimeSeriesBin } from '../../generated/backendGraphql'
import { ResponsiveDashboardChart } from './DashboardChart'

export type DateRangeType =
  | 'TODAY'
  | 'YESTERDAY'
  | 'WEEK'
  | '7_DAYS'
  | 'MONTH'
  | '30_DAYS'
  | '3_MONTHS'
  | '12_MONTHS'

const getValuesForRange = (
  range: DateRangeType,
): { values: [Date, Date]; bin: TimeSeriesBin; comparison: [Date, Date] } => {
  switch (range) {
    case 'TODAY':
      return { values: getToday(), bin: 'HOUR', comparison: getYesterday() }
    case 'YESTERDAY':
      return {
        values: getYesterday(),
        bin: 'HOUR',
        comparison: getYesterday(getYesterday()[0]),
      }
    case 'WEEK':
      return {
        values: getLastWeek(),
        bin: 'DAY',
        comparison: getLastWeek(getLastWeek()[0]),
      }
    case '7_DAYS':
      return {
        values: getLast7Days(),
        bin: 'DAY',
        comparison: getLast7Days(getLast7Days()[0]),
      }
    case '30_DAYS':
      return {
        values: getLast30Days(),
        bin: 'DAY',
        comparison: getLast30Days(getLast30Days()[0]),
      }
    case 'MONTH':
      return {
        values: getLastMonth(),
        bin: 'DAY',
        comparison: getLastMonth(getLastMonth()[0]),
      }
    case '3_MONTHS':
      return {
        values: getLast3Months(),
        bin: 'WEEK',
        comparison: getLast3Months(getLast3Months()[0]),
      }
    case '12_MONTHS':
      return {
        values: getLastYear(),
        bin: 'MONTH',
        comparison: getLastYear(getLastYear()[0]),
      }
    default:
      throw new UnreachableCaseError(range)
  }
}

export const Home: FC = () => {
  const [range, setRange] = useState<DateRangeType>('30_DAYS')
  const {
    values: [startDate, endDate],
    comparison: [comparisonStartDate, comparisonEndDate],
    bin,
  } = useMemo(() => getValuesForRange(range), [range])
  const { data, loading } = useQueryBackend(HomeDocument, {
    variables: { params: { startDate, endDate, bin } },
  })
  return (
    <Page
      fluid
      data-testid="home-page"
      name="Dashboard"
      pb="12"
      addBottomSpacing={false}
      fixedHeight="desktopOnly"
      display="flex"
      flexDir="column"
    >
      <NoEmbedsConfigured />
      <PageHeader>Dashboard</PageHeader>
      <Box pb={4}>
        <DateRangeDisplay mb="4" start={startDate} end={endDate} />
        <Select
          py={2}
          mr="4"
          maxWidth="150px"
          value={range.toLowerCase()}
          onChange={(e) =>
            setRange(e.target.value.toUpperCase() as DateRangeType)
          }
          borderRadius="sm"
          size="lg"
        >
          <option value="today">Today</option>
          <option value="yesterday">Yesterday</option>
          <option value="7_days">Last 7 Days</option>
          <option value="30_days">Last 30 Days</option>
          <option value="week">Last Week</option>
          <option value="month">Last Month</option>
          <option value="3_months">Last 3 Months</option>
          <option value="12_months">Last 12 Months</option>
        </Select>
      </Box>

      <Flex
        flexDirection={['column', 'column', 'column', 'row']}
        flex="1"
        minHeight="0"
      >
        <Box
          ml={['0', '0', '0', '2']}
          mb={['4', '4', '4', '0']}
          width={['100%', '100%', '100%', '50%']}
        >
          <Metrics
            start={startDate}
            end={endDate}
            comparisonStartDate={comparisonStartDate}
            comparisonEndDate={comparisonEndDate}
            width="100%"
            pb={4}
          />

          <VStack minWidth={0} width="100%" flex={1}>
            <Tile width="100%" height="100%" minHeight="425px">
              <VStack width="100%" alignItems="flex-start">
                <Text fontSize="xl" fontWeight="bold">
                  Creators Added
                </Text>
                <Text>Unique creators who signed up to your store</Text>
                <DateRangeDisplay start={startDate} end={endDate} />
                {!data || loading ? (
                  <VStack width="100%" align="stretch">
                    <Skeleton width="100%" height="300px" />
                  </VStack>
                ) : (
                  <Box minHeight="0" width="100%" height="300px">
                    <ResponsiveDashboardChart
                      data={data.storeStatsTimeSeries.creators}
                      range={range}
                    />
                  </Box>
                )}
              </VStack>
            </Tile>
            <Tile width="100%" height="100%" minHeight="425px">
              <VStack width="100%" alignItems="flex-start">
                <Text fontSize="xl" fontWeight="bold">
                  Assets
                </Text>
                <Text>New assets posted</Text>
                <DateRangeDisplay start={startDate} end={endDate} />
                {!data || loading ? (
                  <VStack width="100%" align="stretch">
                    <Skeleton width="100%" height="300px" />
                  </VStack>
                ) : (
                  <Box minHeight="0" width="100%" height="300px">
                    <ResponsiveDashboardChart
                      data={data.storeStatsTimeSeries.assets}
                      range={range}
                    />
                  </Box>
                )}
              </VStack>
            </Tile>
          </VStack>
        </Box>
        <Flex
          ml={['0', '0', '0', '2']}
          width={['100%', '100%', '100%', '50%']}
          alignSelf="stretch"
          minHeight="0"
          height="100%"
          as={Tile}
        >
          <RecentActivity />
        </Flex>
      </Flex>
    </Page>
  )
}
