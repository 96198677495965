import { useClipboard, Box, Button } from '@bounty/web-components'
import { CODE_SNIPPET } from '../const'
import { FC } from 'react'

export const CodeSnippet: FC = () => {
  const { hasCopied, onCopy } = useClipboard(CODE_SNIPPET)

  return (
    <Box>
      <Box
        backgroundColor="gray.200"
        display="inline-block"
        fontSize={['xs', 'sm']}
        borderRadius="lg"
        paddingY="2"
        paddingX="4"
        mb={['4', '0']}
        fontWeight="semibold"
      >
        <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
          {CODE_SNIPPET}
        </pre>
      </Box>
      <Button ml={'2'} onClick={onCopy} size="sm" variant="outline">
        {hasCopied ? 'Copied' : 'Copy'}
      </Button>
    </Box>
  )
}
