import {
  useBounty,
  ContentBox,
  Box,
  PageHeader,
  Heading,
  Text,
  Link,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  UnorderedList,
  ListItem,
} from '@bounty/web-components'
import { SupportIcon } from '@bounty/icons'
import { FC } from 'react'
import { Page } from '../../components/Page'
import { useAuthState } from '../../hooks/useAuth'

export type SupportProps = unknown

export const Support: FC<SupportProps> = () => {
  const { supportEmail, appName } = useBounty()
  const { shopifyStoreUrl } = useAuthState()

  return (
    <Page
      addBottomSpacing={false}
      alignItems="center"
      data-testid="support-page"
      name="Support"
    >
      <PageHeader>Support</PageHeader>
      <ContentBox mb="4">
        <Heading as="h2" size="md" mb="4">
          Frequently Asked Questions
        </Heading>
        <Accordion allowMultiple>
          <AccordionItem>
            <AccordionButton>
              <Text fontWeight="semibold" flex="1" textAlign="left">
                When should I expect my first Bounties?
              </Text>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              Users cannot create Bounties until after they have received your
              product and signed up. Once they receive your product they have 14
              days to create a Bounty. We'll give them a nudge if they haven't
              in a couple days.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Text fontWeight="semibold" flex="1" textAlign="left">
                Can you help me install my snippet?
              </Text>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              Yes! Go to the{' '}
              <Link isExternal={false} to="/snippets">
                snippets page
              </Link>{' '}
              and click contact us under "Free Concierge Installation."
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Text fontWeight="semibold" flex="1" textAlign="left">
                How do I turn off the Bounty snippet?
              </Text>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              The Bounty snippet can be turned off globally by going to the{' '}
              <Link isExternal={false} to="/snippets">
                snippets page
              </Link>{' '}
              and toggling the switch at the upper right hand corner. It can be
              turned off per product on the{' '}
              <Link isExternal={false} to="/products">
                products page
              </Link>
              .
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton>
              <Text fontWeight="semibold" flex="1" textAlign="left">
                Why is the Bounty snippet not appearing on my store?
              </Text>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text mb="2">This can happen for a variety of reasons:</Text>
              <UnorderedList pl="4" mb="2">
                <ListItem>
                  Make sure the snippet is enabled for your store globally on
                  the{' '}
                  <Link isExternal={false} to="/snippets">
                    snippets page
                  </Link>
                  .
                </ListItem>
                <ListItem>
                  Make sure the snippet is installed for your store by either
                  editing the liquid file or using app blocks. For more
                  information, review the{' '}
                  <Link isExternal={false} to="/snippets">
                    snippets page
                  </Link>
                  .
                </ListItem>
                <ListItem>
                  Make sure the product you want the Bounty snippet available on
                  is configured and enabled on the{' '}
                  <Link isExternal={false} to="/snippets">
                    products page
                  </Link>
                  .
                </ListItem>
                <ListItem>
                  Make sure your account is in good standing with billing
                  configured on the Shopify admin website.
                </ListItem>
                <ListItem>
                  You may be out of budget for the month. You can check that on
                  the{' '}
                  <Link isExternal={false} to="/settings">
                    settings page
                  </Link>
                  .
                </ListItem>
              </UnorderedList>
              <Text>
                Contact us below if you are still running into issues.
              </Text>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </ContentBox>
      <ContentBox>
        <Flex>
          <Box mr="6">
            <SupportIcon boxSize={10} />
          </Box>
          <Box>
            <Heading as="h2" size="md" mb="2">
              Chat with Someone
            </Heading>
            <Text>
              Please{' '}
              <Link
                href={`mailto:${supportEmail}?subject=${appName} | ${shopifyStoreUrl} Help`}
                isExternal
                textDecoration={'underline'}
              >
                email us
              </Link>{' '}
              for all other questions and we'll get back to soon as we can.
            </Text>
          </Box>
        </Flex>
      </ContentBox>
    </Page>
  )
}
