import { FC, forwardRef, useMemo, useRef, useState } from 'react'
import {
  CellRendererSelectorFunc,
  ColDef,
  RowNode,
  RowSelectedEvent,
} from 'ag-grid-community'
import { useMutationBackend, useQueryBackend } from '../../apollo/backend/hooks'
import {
  ProductsGridDocument,
  UpdateShopifyStoreProductsDocument,
  ProductsGridQuery,
} from '../../generated/backendGraphql'
import {
  Button,
  Image,
  ButtonGroup,
  Tooltip,
  IconButton,
  Switch,
  Link,
  Portal,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  ButtonProps,
} from '@bounty/web-components'
import { useAuthState } from '../../hooks/useAuth'
import { Grid } from '../../components/Grid/Grid'
import { ChevronDownIcon, StarFillIcon, StarIcon } from '@bounty/icons'
import { debounce, prettyCurrency } from '@bounty/utils'

export type ProductsGridProps = unknown

export type ProductItem = ProductsGridQuery['shopifyStoreProducts'][0]
type CellRendererParams = Parameters<CellRendererSelectorFunc<ProductItem>>[0]

const getShouldBulkEnable = (items: RowNode<ProductItem>[]) => {
  return items.some((item) => item.data!.enabled === false)
}

const makeItemsToEnableBody = (items: RowNode<ProductItem>[]) => {
  return items
    .filter((item) => item.data!.enabled === false)
    .map((i) => i.data!.id)
}

const makeItemsToDisableBody = (items: RowNode<ProductItem>[]) => {
  return items
    .filter((item) => item.data!.enabled === true)
    .map((i) => i.data!.id)
}

const getShouldBulkFeature = (items: RowNode<ProductItem>[]) => {
  return items.some((item) => item.data!.publicDirectoryEnabled === false)
}

const makeItemsToFeatureBody = (items: RowNode<ProductItem>[]) => {
  return items
    .filter((item) => item.data!.publicDirectoryEnabled === false)
    .map((i) => i.data!.id)
}

const makeItemsToUnfeatureBody = (items: RowNode<ProductItem>[]) => {
  return items
    .filter((item) => item.data!.publicDirectoryEnabled === true)
    .map((i) => i.data!.id)
}

const makeItemsToBulkAssignBrief = (items: RowNode<ProductItem>[]) => {
  return items.map((i) => i.data!.id)
}

// Need this to get a full width menu button
const BriefMenuActionButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    return (
      <Button
        ref={ref}
        textAlign="left"
        width="100%"
        overflow="hidden"
        rightIcon={
          <ChevronDownIcon
            width="auto"
            backgroundColor="white"
            pl="3px"
            pr="1"
          />
        }
        size="sm"
        variant="outline"
        pr="0"
        backgroundColor="white"
        _hover={{
          backgroundColor: 'white',
        }}
        _focus={{
          backgroundColor: 'white',
        }}
        _active={{
          backgroundColor: 'white',
        }}
        {...props}
      />
    )
  },
)

export const ProductsGrid: FC<ProductsGridProps> = () => {
  const { shopifyStoreUrl } = useAuthState()
  const [updateShopifyStoreProducts, { loading }] = useMutationBackend(
    UpdateShopifyStoreProductsDocument,
    {
      // @ts-expect-error The internal types are wrong in apollo
      optimisticResponse: (proxy) => {
        return {
          updateShopifyStoreProducts: {
            count: proxy.ids.length,
          },
          __typename: 'Mutation',
        }
      },
      update(cache, _updates, { variables }) {
        if (!variables) return
        ;(variables.ids as string[]).forEach((id) => {
          cache.modify({
            id: `ShopifyStoreProduct:${id}`,
            fields: {
              enabled(val) {
                return variables.updates.enabled ?? val
              },
              publicDirectoryEnabled(val) {
                return variables.updates.publicDirectoryEnabled ?? val
              },
              productInstructionId(val) {
                return variables.updates.productInstructionId ?? val
              },
              productInstructionName(val) {
                return variables.updates.productInstructionName ?? val
              },
            },
          })
        })
      },
    },
  )

  /**
   * Be careful! Ag-grid mutates the objects stored here as you do stuff to the table and it does not cause rerenders!
   *
   * However, all mutations will cause a rerender on success so that's how the bulk toggle works so well.
   */
  const [selectedRows, setSelectedRows] = useState<RowNode<ProductItem>[]>([])
  const shouldBulkEnable = getShouldBulkEnable(selectedRows)
  const shouldBulkFeature = getShouldBulkFeature(selectedRows)
  const debouncedRowSelect = useRef(
    debounce<RowSelectedEvent<ProductItem>[], void>(async (e) => {
      setSelectedRows(e.api.getSelectedNodes())
    }, 300),
  )
  const { data: { shopifyStoreProducts = [], storeProductInstructions } = {} } =
    useQueryBackend(ProductsGridDocument)

  const columnDefs = useMemo(
    (): ColDef<ProductItem>[] => [
      {
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        width: 30,
        flex: 0,
        resizable: false,
        suppressMovable: true,
      },
      {
        headerName: '',
        width: 40,
        flex: 0,
        field: 'enabled',
        suppressMovable: true,
        cellRenderer: (params: CellRendererParams) => {
          return (
            <Switch
              colorScheme="darkBlack"
              isChecked={params.data!.enabled}
              onChange={() => {
                updateShopifyStoreProducts({
                  variables: {
                    ids: [params.data!.id],
                    updates: {
                      enabled: !params.data!.enabled,
                    },
                  },
                })
              }}
            />
          )
        },
        resizable: false,
      },
      {
        headerName: '',
        width: 30,
        flex: 0,
        field: 'publicDirectoryEnabled',
        suppressMovable: true,
        cellRenderer: (params: CellRendererParams) => {
          return (
            <Tooltip hasArrow label="Show on Shop Page">
              <IconButton
                aria-label="Feature button"
                variant="unstyled"
                minWidth="auto"
                minHeight="auto"
                _focus={{
                  boxShadow: 'none',
                }}
                height="auto"
                onClick={() => {
                  updateShopifyStoreProducts({
                    variables: {
                      ids: [params.data!.id],
                      updates: {
                        publicDirectoryEnabled:
                          !params.data!.publicDirectoryEnabled,
                      },
                    },
                  })
                }}
                icon={
                  params.data!.publicDirectoryEnabled ? (
                    <StarFillIcon boxSize="20px" />
                  ) : (
                    <StarIcon boxSize="20px" />
                  )
                }
              />
            </Tooltip>
          )
        },
        resizable: false,
      },
      {
        headerName: '',
        field: 'imgLink',
        width: 80,
        flex: 0,
        resizable: false,
        suppressMovable: true,
        cellRenderer: (params: CellRendererParams) => {
          return (
            <Image
              width="40px"
              height="40px"
              objectFit="cover"
              src={params.value ?? 'https://via.placeholder.com/150'}
              alt={params.data!.productName ?? 'Not provided.'}
            />
          )
        },
      },
      {
        headerName: 'Product Name',
        field: 'productName',
        flex: 2,
        cellRenderer: (params: CellRendererParams) => {
          return (
            <Link
              isExternal
              href={`https://${shopifyStoreUrl}/admin/products/${
                params.data!.productId
              }`}
            >
              {params.data!.productName}
            </Link>
          )
        },
      },
      { headerName: 'Shopify ID', field: 'productId' },
      { headerName: 'SKU', field: 'productSku' },
      { headerName: 'Handle', field: 'handle' },
      {
        headerName: 'Brief',
        field: 'productInstructionName',
        flex: 1,
        // Needed for search filters
        valueGetter: (params) => {
          return params.data?.productInstructionName ?? 'default'
        },
        cellRenderer: (params: CellRendererParams) => {
          return (
            <Menu>
              <MenuButton as={BriefMenuActionButton}>
                {params.data?.productInstructionName ?? 'Default'}
              </MenuButton>
              <Portal>
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      updateShopifyStoreProducts({
                        variables: {
                          ids: [params.data!.id],
                          updates: {
                            productInstructionId: null,
                            productInstructionName: 'Default',
                          },
                        },
                      })
                    }}
                  >
                    Default
                  </MenuItem>
                  <MenuDivider />
                  {storeProductInstructions
                    ?.filter((x) => x.isDefault === false)
                    .map((x) => {
                      return (
                        <MenuItem
                          onClick={() => {
                            updateShopifyStoreProducts({
                              variables: {
                                ids: [params.data!.id],
                                updates: {
                                  productInstructionId: x.id,
                                  productInstructionName: x.name,
                                },
                              },
                            })
                          }}
                          key={x.id}
                        >
                          {x.name}
                        </MenuItem>
                      )
                    })}
                </MenuList>
              </Portal>
            </Menu>
          )
        },
      },
      {
        headerName: 'Price',
        field: 'price',
        filter: 'agNumberColumnFilter',
        valueFormatter: (x) =>
          x.data!.price != null
            ? prettyCurrency(x.data!.price, { precision: 2 })
            : 'n/a',
      },
    ],
    [updateShopifyStoreProducts, shopifyStoreUrl, storeProductInstructions],
  )

  return (
    <Grid<ProductItem>
      containerProps={{ flex: 1 }}
      rowData={[...shopifyStoreProducts]}
      columnDefs={columnDefs}
      rowSelection="multiple"
      suppressRowClickSelection={true}
      rightComponent={
        selectedRows.length > 0 ? (
          <ButtonGroup variant="solid" size="sm" colorScheme="darkBlack">
            <Button
              isDisabled={loading}
              onClick={() => {
                updateShopifyStoreProducts({
                  variables: {
                    ids: shouldBulkEnable
                      ? makeItemsToEnableBody(selectedRows)
                      : makeItemsToDisableBody(selectedRows),
                    updates: {
                      enabled: shouldBulkEnable,
                    },
                  },
                })
              }}
            >
              {shouldBulkEnable ? 'Enable' : 'Disable'}
            </Button>
            <Button
              isDisabled={loading}
              onClick={() => {
                updateShopifyStoreProducts({
                  variables: {
                    ids: shouldBulkFeature
                      ? makeItemsToFeatureBody(selectedRows)
                      : makeItemsToUnfeatureBody(selectedRows),
                    updates: {
                      publicDirectoryEnabled: shouldBulkFeature,
                    },
                  },
                })
              }}
            >
              {shouldBulkFeature ? 'Make Featured' : 'Remove Featured'}
            </Button>
            <Menu>
              <MenuButton
                size="sm"
                colorScheme="darkBlack"
                as={Button}
                isDisabled={loading}
                rightIcon={<ChevronDownIcon />}
              >
                Assign Brief
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    updateShopifyStoreProducts({
                      variables: {
                        ids: makeItemsToBulkAssignBrief(selectedRows),
                        updates: {
                          productInstructionId: null,
                          productInstructionName: 'Default',
                        },
                      },
                    })
                  }}
                >
                  Default
                </MenuItem>
                <MenuDivider />
                {storeProductInstructions
                  ?.filter((x) => x.isDefault === false)
                  .map((x) => {
                    return (
                      <MenuItem
                        onClick={() => {
                          updateShopifyStoreProducts({
                            variables: {
                              ids: makeItemsToBulkAssignBrief(selectedRows),
                              updates: {
                                productInstructionId: x.id,
                                productInstructionName: x.name,
                              },
                            },
                          })
                        }}
                        key={x.id}
                      >
                        {x.name}
                      </MenuItem>
                    )
                  })}
              </MenuList>
            </Menu>
          </ButtonGroup>
        ) : null
      }
      onRowSelected={debouncedRowSelect.current}
    />
  )
}
