import { Box, Heading, PageSkeleton, Text } from '@bounty/web-components'
import { FC, useState } from 'react'
import { Page } from '../../../../components/Page'
import { useQueryBackend } from '../../../../apollo/backend/hooks'
import { BillingDocument } from '../../../../generated/backendGraphql'
import { Navigate } from 'react-router-dom'
import { useWelcomeState } from '../../useWelcome'
import { BillingTiles } from '../../../Billing/BillingTile'
import { useAnalytics } from '@bounty/hooks'
import { DiscountCodeForm } from '../../../../components/DiscountCodeForm'
import { FeatureTable } from '../../../Billing/FeatureTable'

export type DiscountDetails = {
  status: boolean
  price: string | null | undefined
}

export const Billing: FC = () => {
  const { track } = useAnalytics()
  const { budgetedAmount } = useWelcomeState()
  const { data, loading } = useQueryBackend(BillingDocument)

  const [discountDetails, setDiscountDetails] = useState<DiscountDetails>({
    status: false,
    price: '',
  })
  const [discountCode, setDiscountCode] = useState('')

  if (!data || loading) return <PageSkeleton />

  if (budgetedAmount == null) {
    return <Navigate to="../budget" />
  }

  return (
    <Page data-testid="billing-page" name="Billing">
      <Box paddingTop={['0', '4']}>
        <Box px={['0', '8']}>
          <Heading as="h1" mb="4" size="lg">
            Pricing
          </Heading>
          <Text fontSize="lg" mb="8">
            Automatic UGC for a fraction of the price of a content agency
          </Text>
        </Box>
        <BillingTiles
          mb="100px"
          budgetedAmount={budgetedAmount}
          onSubscriptionClicked={async () => {
            await track('Welcome Billing Completed')
            await track('Welcome Completed')
          }}
          discountDetails={discountDetails}
          discountCode={discountCode}
        />
        <FeatureTable />
        <DiscountCodeForm
          discountDetails={discountDetails}
          setDiscountDetails={setDiscountDetails}
          discountCode={discountCode}
          setDiscountCode={setDiscountCode}
        />
      </Box>
    </Page>
  )
}
