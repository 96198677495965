import { PageHeader, Flex, Button } from '@bounty/web-components'
import { FC, useEffect } from 'react'
import { Page } from '../../components/Page'
import { ProductsGrid } from './ProductsGrid'
import { useMutationBackend } from '../../apollo/backend/hooks'
import {
  ForceSyncProductsDocument,
  ProductsGridDocument,
} from '../../generated/backendGraphql'
import { ChevronDownIcon } from '@bounty/icons'
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'
import { Routes, Route, useNavigate, Outlet } from 'react-router-dom'
import { ProductInstructionsDrawer } from './ProductInstructions/ProductInstructionsDrawer'

export type ProductsProps = unknown

export const Products: FC<ProductsProps> = () => {
  const navigate = useNavigate()
  const [forceSyncClients, { loading }] = useMutationBackend(
    ForceSyncProductsDocument,
    { refetchQueries: [{ query: ProductsGridDocument }] },
  )

  /**
   * Within the product grid we use portaled dropdown elements. They mount at the end of document.body. Chakra
   * renders all of them into the document flow with visibility: hidden instead of display: none to make the
   * animations look pretty (I think). This causes them to take space in the DOM and cause scrolling on this page even
   * though we want it to be fixed height (only the grid should scroll). To fix this, we're putting in a hack preventing
   * any scrolling on document.body until this page is unmounted.
   */
  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = ''
    }
  }, [])

  return (
    <Page
      data-testid="products-page"
      name="Products"
      width="100%"
      fixedHeight="all"
      fluid
      addBottomSpacing={false}
      paddingBottom="3rem"
    >
      <Flex justifyContent="space-between" alignItems="flex-start">
        <PageHeader>Products</PageHeader>
        <Menu>
          <MenuButton
            variant="outline"
            as={Button}
            rightIcon={<ChevronDownIcon />}
          >
            Actions
          </MenuButton>
          <MenuList>
            <MenuItem isDisabled={loading} onClick={() => forceSyncClients()}>
              Sync All Products
            </MenuItem>
            <MenuItem onClick={() => navigate('./briefs')}>
              Manage Briefs
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <ProductsGrid />
              <Outlet />
            </>
          }
        >
          <Route
            path="briefs/*"
            element={<ProductInstructionsDrawer rootPath="/products" />}
          />
        </Route>
      </Routes>
    </Page>
  )
}
