import {
  Box,
  BoxProps,
  Flex,
  Button,
  Text,
  DrawerBody,
  DrawerHeader,
  DrawerCloseButton,
  DrawerProps,
  Divider,
  Skeleton,
} from '@bounty/web-components'
import { Link as RouterLink } from 'react-router-dom'
import { useQueryBackend } from '../../../apollo/backend/hooks'
import {
  ProductInstructionsListDocument,
  ProductInstructionsListQuery,
} from '../../../generated/backendGraphql'

export type ManageProductInstructionsProps = Omit<DrawerProps, 'children'>

const ProductInstructionItem = ({
  name,
  to,
  id,
}: {
  name: string
  to: string
  id: string
}) => {
  return (
    <Flex py="3" justifyContent="space-between" alignItems="center">
      <Text>{name}</Text>
      <Button
        data-testid={`editButton--${id}`}
        variant="outline"
        as={RouterLink}
        to={to}
        colorScheme="darkBlack"
      >
        Edit
      </Button>
    </Flex>
  )
}

const ProductInstructionItemLoading = () => {
  return (
    <Flex py="4" justifyContent="space-between" alignItems="center">
      <Skeleton width="100%" height="20px" />
    </Flex>
  )
}

const DefaultInstruction = ({
  productInstructions,
}: {
  productInstructions: ProductInstructionsListQuery['storeProductInstructions']
}) => {
  const maybeDefaultInstruction = productInstructions.find((x) => x.isDefault)

  return (
    <ProductInstructionItem
      name="Default"
      id="default"
      to={maybeDefaultInstruction ? `./default/edit` : `./default/new`}
    />
  )
}

export type ProductInstructionsListProps = BoxProps

export const ProductInstructionsList = ({
  ...rest
}: ProductInstructionsListProps) => {
  const { data, loading } = useQueryBackend(ProductInstructionsListDocument)
  return (
    <Box display="flex" flexDirection="column" minHeight="0" {...rest}>
      <DrawerCloseButton />
      <DrawerHeader>
        <Box fontSize="md" fontWeight="normal" mb="4">
          &nbsp;
        </Box>
        Briefs
      </DrawerHeader>
      <DrawerBody display="flex" flexDirection="column" minHeight="0" flex="1">
        <Text mb="6">
          Briefs allow you to give creators specific directions, ideas, or
          guidelines on what you’d like to see from their video.
        </Text>
        <Box display="flex" flexDirection="column" minHeight="0" flex="1">
          {loading || !data ? (
            <Box>
              <ProductInstructionItemLoading />
              <Divider />
              <ProductInstructionItemLoading />
              <ProductInstructionItemLoading />
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" minHeight="0" flex="1">
              <DefaultInstruction
                productInstructions={data.storeProductInstructions}
              />
              <Divider />
              <Box flex="1" overflowY="auto">
                {data.storeProductInstructions.length > 0 ? (
                  data.storeProductInstructions
                    .filter((x) => x.isDefault === false)
                    .map((instruction) => {
                      return (
                        <ProductInstructionItem
                          name={instruction.name}
                          to={`./${instruction.id}/edit`}
                          id={instruction.id}
                          key={instruction.id}
                        />
                      )
                    })
                ) : (
                  <Box py="4">No briefs added.</Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
        <Flex justifyContent="flex-end" mt="4" pb="6">
          <Button
            as={RouterLink}
            to="./new"
            colorScheme="darkBlack"
            data-testid="createNewPromptButton"
          >
            Create New Brief
          </Button>
        </Flex>
      </DrawerBody>
    </Box>
  )
}
