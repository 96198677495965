import { FC, Dispatch, SetStateAction } from 'react'
import { useQueryBackend } from '../../apollo/backend/hooks'
import {
  ContentLibraryFiltersDocument,
  ContentLibraryInput,
} from '../../generated/backendGraphql'
import { FancySelect, Box, Flex, Skeleton } from '@bounty/web-components'
import { isNil } from '@bounty/utils'

export type ContentLibraryFiltersProps = {
  /**Only passing down the setter from the parent to make sure it can be typesafe */
  setParams: Dispatch<SetStateAction<ContentLibraryInput>>
  params: ContentLibraryInput
}

export const ContentLibraryFilters: FC<ContentLibraryFiltersProps> = ({
  params,
  setParams,
}) => {
  const { data, loading } = useQueryBackend(ContentLibraryFiltersDocument)

  if (loading || isNil(data)) {
    return (
      <Flex
        alignItems="flex-start"
        justifyContent="space-between"
        mb="6"
        flexDirection={['column', 'column', 'column', 'row']}
        width="100%"
      >
        <Flex
          width="100%"
          flex="1"
          flexDirection={['column', 'column', 'column', 'row']}
        >
          <Skeleton
            width={['100%', '100%', '100%', '320px']}
            mb={['4', '4', '4', '0']}
            height="35px"
            mr="4"
          />
          <Skeleton
            width={['100%', '100%', '100%', '320px']}
            mb={['4', '4', '4', '0']}
            height="35px"
          />
        </Flex>
        <Skeleton
          mb={['4', '4', '4', '0']}
          height="35px"
          width={['100%', '100%', '100%', '180px']}
        />
      </Flex>
    )
  }

  const getDefaultCreatorValue = () => {
    if (
      !params.filterByProfileNames ||
      params.filterByProfileNames.length === 0
    ) {
      return null
    }
    const getLabelById = (id: string) => {
      const maybeUsername = data.contentLibraryFilters.usernames.find(
        (u) => u.id === id,
      )
      return maybeUsername?.profileName ?? ''
    }

    return params.filterByProfileNames?.map((id) => ({
      value: id,
      label: getLabelById(id),
    }))
  }

  const getDefaultCreatedAt = () => {
    const value = params.filterByCreatedAt
    const maybeLabel = data.contentLibraryFilters.createdAtFilters.find(
      (c) => c.value === value,
    )
    const label = maybeLabel?.name
    return { value, label }
  }

  const getDefaultSort = () => {
    const value = {
      orderByDirection: params.orderByDirection,
      orderBy: params.orderBy,
    }
    const maybeLabel = data.contentLibraryFilters.orderByCombinations.find(
      (c) =>
        c.orderBy === value.orderBy && c.direction === value.orderByDirection,
    )
    const label = maybeLabel?.name ?? ''
    return { value, label }
  }

  return (
    <Flex
      alignItems="flex-start"
      justifyContent="space-between"
      mb="6"
      flexDirection={['column', 'column', 'column', 'row']}
      width="100%"
    >
      <Flex
        width="100%"
        flex="1"
        flexDirection={['column', 'column', 'column', 'row']}
      >
        <Box
          mb={['4', '4', '4', '0']}
          width={['100%', '100%', '100%', '320px']}
          mr="4"
        >
          <FancySelect
            defaultValue={getDefaultCreatorValue()}
            placeholder="Filter by creators..."
            options={data.contentLibraryFilters.usernames.map((username) => ({
              value: username.id,
              label: username.profileName,
            }))}
            isMulti
            closeMenuOnSelect={false}
            isSearchable
            isClearable
            onChange={(options) => {
              setParams((x) => ({
                ...x,
                filterByProfileNames: options.map((option) => option.value),
              }))
            }}
          />
        </Box>
        <Box
          mb={['4', '4', '4', '0']}
          width={['100%', '100%', '100%', '200px']}
        >
          <FancySelect
            placeholder="Filter by date..."
            defaultValue={getDefaultCreatedAt()}
            isSearchable
            options={data.contentLibraryFilters.createdAtFilters.map((i) => ({
              value: i.value,
              label: i.name,
            }))}
            onChange={(option) => {
              if (option?.value) {
                setParams((x) => ({
                  ...x,
                  filterByCreatedAt: option.value,
                }))
              }
            }}
          />
        </Box>
      </Flex>
      <Flex width={['100%', '100%', '100%', 'auto']} flexShrink={0}>
        <Box
          mb={['4', '4', '4', '0']}
          width={['100%', '100%', '100%', '180px']}
        >
          <FancySelect
            placeholder="Sort"
            isSearchable
            defaultValue={getDefaultSort()}
            options={data.contentLibraryFilters.orderByCombinations.map(
              (i) => ({
                value: { orderByDirection: i.direction, orderBy: i.orderBy },
                label: i.name,
              }),
            )}
            onChange={(option) => {
              if (option?.value) {
                setParams((x) => ({
                  ...x,
                  ...option.value,
                }))
              }
            }}
          />
        </Box>
      </Flex>
    </Flex>
  )
}
