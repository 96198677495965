import {
  Container,
  Box,
  Flex,
  Image,
  Heading,
  Tag,
  Avatar,
  Text,
} from './Chakra'
import { PortableText } from '@portabletext/react'
import { FC } from 'react'
import { prettyFloatingDate } from '@bounty/utils'

export type PortableTextPageProps = {
  item: {
    title: string
    mainImage?: string
    publishedAt: string
    authors?: { slug: string; title: string; position: string; image: string }[]
    categories?: {
      title: string
      slug: string
    }[]
    body: any[]
  }
}

export const PortableTextPage: FC<PortableTextPageProps> = ({ item }) => {
  return (
    <Container maxW="container.md" mb="20">
      <Text
        textAlign="center"
        pt="16"
        mb="4"
        color="gray.600"
        fontWeight="semibold"
      >
        {prettyFloatingDate(item.publishedAt)}
      </Text>
      <Heading as={'h1'} textAlign="center" mb="6" size="2xl">
        {item.title}
      </Heading>
      {item.authors && item.authors.length > 0 ? (
        <Flex flexDir="column" alignItems="center" mb="6">
          {item.authors.map((author) => {
            return (
              <Flex key={author.slug} mb="4">
                <Avatar src={author.image} size="lg" mr="6" />
                <Box>
                  <Text>{author.title}</Text>
                  <Text color="gray.600" fontSize="sm">
                    {author.position}
                  </Text>
                </Box>
              </Flex>
            )
          })}
        </Flex>
      ) : null}

      <Box mb="8">
        {item.mainImage && (
          <Image
            src={item.mainImage}
            objectFit="cover"
            width="100%"
            height={['300px', '300px', '400px']}
            mb="2"
          />
        )}

        {item.categories && item.categories.length > 0 ? (
          <Flex wrap="wrap">
            {item.categories.map((cat) => (
              <Tag colorScheme="green" size="md" mr="2" mb="4" key={cat.slug}>
                {cat.title}
              </Tag>
            ))}
          </Flex>
        ) : null}
      </Box>
      <PortableText value={item.body} />
    </Container>
  )
}
