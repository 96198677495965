import { BoxProps } from '@bounty/web-components'
import { ProductInstructionBase } from './ProductInstructionBase'

type ProductInstructionCreateProps = BoxProps

export const ProductInstructionCreate = ({
  ...rest
}: ProductInstructionCreateProps) => {
  return (
    <ProductInstructionBase
      variant={'create'}
      isDefault={false}
      isLoading={false}
      formProps={{}}
      {...rest}
    />
  )
}
