import {
  FormControl,
  Input,
  InputProps,
  FormErrorMessage,
  FormErrorMessageProps,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  Button,
  ButtonProps,
  Textarea,
  TextareaProps,
  Select,
  SelectProps,
  Switch,
  SwitchProps,
  Box,
} from './Chakra'
import { FC, Ref, forwardRef, ReactNode } from 'react'
import { FormProps, Form } from './Form'
import {
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormProps,
  FieldValues,
  useFormContext,
  Path,
  FieldError,
  UseFormReturn,
  Controller,
} from 'react-hook-form'
import { ColorInput, ColorInputProps } from './ColorInput'
import { zodResolver } from '@hookform/resolvers/zod'
import { isFunction } from '@bounty/utils'
import { ErrorText } from './ErrorText'

export type FastFormProps<TFieldValues extends FieldValues = FieldValues> =
  Omit<FormProps, 'onSubmit' | 'children'> & {
    formProps?: UseFormProps<TFieldValues>
    formRef?: Ref<HTMLFormElement>
    schema: any
    onSubmit: SubmitHandler<TFieldValues>
    children:
      | ReactNode
      | ((props: UseFormReturn<TFieldValues, object>) => ReactNode)
  }

const getGenericError = (e: any) => {
  return e?.['&']?.[0]?.message
}

export const FastForm = <TFieldValues extends FieldValues = FieldValues>({
  children,
  formProps,
  onSubmit,
  schema,
  formRef,
  ...rest
}: FastFormProps<TFieldValues>) => {
  const methods = useForm<TFieldValues>({
    // @ts-ignore - Not sure how to prove the schema passed in will match the generic type even though it'll be inferred from it
    resolver: zodResolver(schema),
    ...formProps,
  })
  const genericError = getGenericError(methods.formState.errors)

  return (
    <FormProvider {...methods}>
      <Form {...rest} onSubmit={methods.handleSubmit(onSubmit)} ref={formRef}>
        {isFunction(children) ? children(methods) : children}
        {genericError && <ErrorText>{genericError}</ErrorText>}
      </Form>
    </FormProvider>
  )
}

FastForm.displayName = 'FastForm'

export type FastFormControlProps = {
  error?: FieldError
  helpText?: string
  label?: string
  labelProps?: FormLabelProps
  errorProps?: FormErrorMessageProps
}

export const FastFormControl: FC<FastFormControlProps> = ({
  error,
  helpText,
  label,
  children,
  labelProps,
  errorProps,
}) => {
  return (
    <FormControl isInvalid={!!error} marginBottom="1">
      <FormLabel pl="4" {...labelProps}>
        {label}
      </FormLabel>
      {children}
      {error ? (
        <FormErrorMessage
          position="absolute"
          bottom="0"
          left="0"
          m="0"
          fontSize="11px"
          pl="4"
          {...errorProps}
        >
          {error.message}
        </FormErrorMessage>
      ) : helpText ? (
        <FormHelperText
          position="absolute"
          bottom="0"
          left="0"
          m="0"
          fontSize="11px"
          pl="4"
        >
          {helpText}
        </FormHelperText>
      ) : null}
    </FormControl>
  )
}

export type FastFormInputBaseProps = Omit<InputProps, 'name'>
export const FastFormInputBase = forwardRef<any, FastFormInputBaseProps>(
  (props, ref) => {
    return <Input mb="18px" ref={ref} {...props} />
  },
)

FastFormInputBase.displayName = 'FastFormInputBase'

export type FastFormInputProps<TFieldValues extends FieldValues = FieldValues> =
  FastFormInputBaseProps &
    FastFormControlProps & {
      name: Path<TFieldValues>
      children?: (props: UseFormReturn<TFieldValues, object>) => ReactNode
    }

export const FastFormInput = <TFieldValues extends FieldValues = FieldValues>({
  label,
  name,
  helpText,
  labelProps,
  errorProps,
  children,
  ...rest
}: FastFormInputProps<TFieldValues>) => {
  const methods = useFormContext<TFieldValues>()
  const {
    register,
    formState: { errors },
  } = methods
  // @ts-ignore Indexing error on deep partial, not sure how to fix
  const error = errors[name]

  return (
    <FastFormControl
      error={error}
      helpText={helpText}
      label={label}
      labelProps={labelProps}
      errorProps={errorProps}
    >
      {children != null && isFunction(children) ? (
        children(methods)
      ) : (
        <FastFormInputBase {...rest} {...register(name)} />
      )}
    </FastFormControl>
  )
}

export type FastFormTextareaProps<
  TFieldValues extends FieldValues = FieldValues,
> = Omit<TextareaProps, 'name'> &
  FastFormControlProps & {
    name: Path<TFieldValues>
  }

export const FastFormTextarea = <
  TFieldValues extends FieldValues = FieldValues,
>({
  label,
  name,
  helpText,
  labelProps,
  errorProps,
  ...rest
}: FastFormTextareaProps<TFieldValues>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<TFieldValues>()
  // @ts-ignore Indexing error on deep partial, not sure how to fix
  const error = errors[name]

  return (
    <FastFormControl
      error={error}
      helpText={helpText}
      label={label}
      labelProps={labelProps}
      errorProps={{ bottom: '-18px', ...errorProps }}
    >
      <Textarea mb="18px" {...rest} {...register(name)} />
    </FastFormControl>
  )
}

export type FastFormSelectProps<
  TFieldValues extends FieldValues = FieldValues,
> = Omit<SelectProps, 'name'> &
  FastFormControlProps & {
    name: Path<TFieldValues>
  }

export type FastFormSelectBaseProps = SelectProps
export const FastFormSelectBase = forwardRef<any, FastFormSelectBaseProps>(
  (props, ref) => {
    return <Select mb="18px" ref={ref} {...props} />
  },
)

FastFormSelectBase.displayName = 'FastFormSelectBase'

export const FastFormSelect = <TFieldValues extends FieldValues = FieldValues>({
  label,
  name,
  helpText,
  labelProps,
  errorProps,
  children,
  ...rest
}: FastFormSelectProps<TFieldValues>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<TFieldValues>()
  // @ts-ignore Indexing error on deep partial, not sure how to fix
  const error = errors[name]

  return (
    <FastFormControl
      error={error}
      helpText={helpText}
      label={label}
      labelProps={labelProps}
      errorProps={errorProps}
    >
      <Select mb="18px" {...rest} {...register(name)}>
        {children}
      </Select>
    </FastFormControl>
  )
}

export type FastFormSwitchProps<
  TFieldValues extends FieldValues = FieldValues,
> = Omit<SwitchProps, 'name' | 'onChange'> &
  FastFormControlProps & {
    name: Path<TFieldValues>
    defaultValue?: boolean
    value?: boolean
  }

export type FastFormSwitchBaseProps = SwitchProps
export const FastFormSwitchBase = forwardRef<any, FastFormSwitchBaseProps>(
  (props, ref) => {
    return (
      <Box mb="18px">
        <Switch ref={ref} {...props} />
      </Box>
    )
  },
)

FastFormSwitchBase.displayName = 'FastFormSwitchBase'

export const FastFormSwitch = <TFieldValues extends FieldValues = FieldValues>({
  label,
  name,
  helpText,
  labelProps,
  errorProps,
  ...rest
}: FastFormSwitchProps<TFieldValues>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<TFieldValues>()
  // @ts-ignore Indexing error on deep partial, not sure how to fix
  const error = errors[name]

  return (
    <FastFormControl
      error={error}
      helpText={helpText}
      label={label}
      labelProps={labelProps}
      errorProps={errorProps}
    >
      <FastFormSwitchBase {...rest} {...register(name)} />
    </FastFormControl>
  )
}

export type FastFormColorInputProps<
  TFieldValues extends FieldValues = FieldValues,
> = Omit<ColorInputProps, 'name' | 'onChange' | 'value'> &
  FastFormControlProps & {
    name: Path<TFieldValues>
  }

export type FastFormColorInputBaseProps = ColorInputProps
export const FastFormColorInputBase = forwardRef<
  any,
  FastFormColorInputBaseProps
>((props, ref) => {
  return (
    <Box mb="18px">
      <ColorInput ref={ref} {...props} />
    </Box>
  )
})

FastFormColorInputBase.displayName = 'FastFormColorInputBase'

export const FastFormColorInput = <
  TFieldValues extends FieldValues = FieldValues,
>({
  label,
  name,
  helpText,
  labelProps,
  errorProps,
  ...rest
}: FastFormColorInputProps<TFieldValues>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<TFieldValues>()
  // @ts-ignore Indexing error on deep partial, not sure how to fix
  const error = errors[name]

  return (
    <FastFormControl
      error={error}
      helpText={helpText}
      label={label}
      labelProps={labelProps}
      errorProps={errorProps}
    >
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          return <FastFormColorInputBase {...rest} {...field} />
        }}
      />
    </FastFormControl>
  )
}

export const FastFormButton = forwardRef<any, ButtonProps>(
  ({ children, ...rest }, ref) => {
    return (
      <Button type="submit" colorScheme="darkBlack" {...rest} ref={ref}>
        {children}
      </Button>
    )
  },
)

FastFormButton.displayName = 'FastFormButton'
