/* eslint-disable import/export */
import { Icon } from '@chakra-ui/react'
import {
  FaMobileAlt,
  FaDesktop,
  FaVolumeMute,
  FaVolumeUp,
  FaLinkedin,
  FaTwitter,
  FaPaintBrush,
  FaStripeS,
  FaHashtag,
  FaHeart,
  FaReply,
} from 'react-icons/fa'
import { GrAnnounce } from 'react-icons/gr'
import { AiOutlineStar, AiFillStar } from 'react-icons/ai'
import { MdSpeed, MdGraphicEq, MdAttachMoney } from 'react-icons/md'
import {
  FiCode,
  FiTag,
  FiUser,
  FiSettings,
  FiLogOut,
  FiCreditCard,
  FiAtSign,
  FiEye,
  FiAlertCircle,
  FiRefreshCw,
  FiBook,
  FiMessageCircle,
  FiSend,
  FiChevronLeft,
  FiMail,
} from 'react-icons/fi'
import { BiSupport } from 'react-icons/bi'
import {
  BsFillCalendar2CheckFill,
  BsMegaphoneFill,
  BsCameraVideo,
  BsPlayFill,
  BsArrowRight,
  BsCheck2,
  BsArrowLeft,
} from 'react-icons/bs'
import { HiOutlineShoppingBag } from 'react-icons/hi'
import { Stars } from './Stars'
import { MdOutlineVerified, MdClose } from 'react-icons/md'

export * from '@chakra-ui/icons'

export const DollarIcon: typeof Icon = (props) => (
  <Icon as={MdAttachMoney} {...props} />
)
export const MobilePhoneIcon: typeof Icon = (props) => (
  <Icon as={FaMobileAlt} {...props} />
)
export const DesktopIcon: typeof Icon = (props) => (
  <Icon as={FaDesktop} {...props} />
)
export const HeartIcon: typeof Icon = (props) => (
  <Icon as={FaHeart} {...props} />
)
export const ReplyIcon: typeof Icon = (props) => (
  <Icon as={FaReply} {...props} />
)
export const SpeedometerIcon: typeof Icon = (props) => (
  <Icon as={MdSpeed} {...props} />
)
export const CodeSlashIcon: typeof Icon = (props) => (
  <Icon as={FiCode} {...props} />
)
export const PriceTagIcon: typeof Icon = (props) => (
  <Icon as={FiTag} {...props} />
)
export const UserIcon: typeof Icon = (props) => <Icon as={FiUser} {...props} />
export const SettingsIcon: typeof Icon = (props) => (
  <Icon as={FiSettings} {...props} />
)
export const MailIcon: typeof Icon = (props) => <Icon as={FiMail} {...props} />
export const StarIcon: typeof Icon = (props) => (
  <Icon as={AiOutlineStar} {...props} />
)
export const StarFillIcon: typeof Icon = (props) => (
  <Icon as={AiFillStar} {...props} />
)
export const SupportIcon: typeof Icon = (props) => (
  <Icon as={BiSupport} {...props} />
)
export const ReleasesIcon: typeof Icon = (props) => (
  <Icon as={FiBook} {...props} />
)
export const AnnouncementsIcon: typeof Icon = (props) => (
  <Icon as={GrAnnounce} {...props} />
)
export const LogoutIcon: typeof Icon = (props) => (
  <Icon as={FiLogOut} {...props} />
)
export const BillingIcon: typeof Icon = (props) => (
  <Icon as={FiCreditCard} {...props} />
)
export const ContentLibraryIcon: typeof Icon = (props) => (
  <Icon as={BsCameraVideo} {...props} />
)
export const AtSignIcon: typeof Icon = (props) => (
  <Icon as={FiAtSign} {...props} />
)
export const EyeIcon: typeof Icon = (props) => <Icon as={FiEye} {...props} />

export const StarsIcon: typeof Icon = (props) => <Icon as={Stars} {...props} />

export const AlertIcon: typeof Icon = (props) => (
  <Icon as={FiAlertCircle} {...props} />
)
export const RefreshIcon: typeof Icon = (props) => (
  <Icon as={FiRefreshCw} {...props} />
)
export const MessageIcon: typeof Icon = (props) => (
  <Icon as={FiMessageCircle} {...props} />
)

export const ChevronLeftIcon: typeof Icon = (props) => (
  <Icon as={FiChevronLeft} {...props} />
)

export const SendIcon: typeof Icon = (props) => <Icon as={FiSend} {...props} />

export const FilledCalendarIcon: typeof Icon = (props) => (
  <Icon as={BsFillCalendar2CheckFill} {...props} />
)
export const PlayFillIcon: typeof Icon = (props) => (
  <Icon as={BsPlayFill} {...props} />
)
export const Megaphone: typeof Icon = (props) => (
  <Icon as={BsMegaphoneFill} {...props} />
)
export const SliderThumbIcon: typeof Icon = (props) => (
  <Icon as={MdGraphicEq} {...props} />
)
export const VolumeMuteIcon: typeof Icon = (props) => (
  <Icon as={FaVolumeMute} {...props} />
)
export const VolumeUpIcon: typeof Icon = (props) => (
  <Icon as={FaVolumeUp} {...props} />
)
export const LinkedinIcon: typeof Icon = (props) => (
  <Icon as={FaLinkedin} {...props} />
)
export const TwitterIcon: typeof Icon = (props) => (
  <Icon as={FaTwitter} {...props} />
)
export const CustomizeIcon: typeof Icon = (props) => (
  <Icon as={FaPaintBrush} {...props} />
)
export const ShopIcon: typeof Icon = (props) => (
  <Icon as={HiOutlineShoppingBag} {...props} />
)
export const StripeIcon: typeof Icon = (props) => (
  <Icon as={FaStripeS} {...props} />
)
export const RightArrowIcon: typeof Icon = (props) => (
  <Icon as={BsArrowRight} {...props} />
)
export const CheckmarkIcon: typeof Icon = (props) => (
  <Icon as={BsCheck2} {...props} />
)
export const CloseIcon: typeof Icon = (props) => (
  <Icon as={MdClose} {...props} />
)
export const HashtagIcon: typeof Icon = (props) => (
  <Icon as={FaHashtag} {...props} />
)
export const VerifiedIcon: typeof Icon = (props) => (
  <Icon as={MdOutlineVerified} {...props} />
)
export const LeftArrowIcon: typeof Icon = (props) => (
  <Icon as={BsArrowLeft} {...props} />
)
