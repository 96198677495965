import * as z from 'zod'

export const signupOrderPreviewBody = z.object({
  orderId: z.string(),
})

export type SignupOrderPreviewBody = z.infer<typeof signupOrderPreviewBody>

// Duped from Prisma because we need this type to work on the server and client since it's the response
export type ShopifyStoreProduct = {
  id: string
  storeId: string
  enabled: boolean
  productId: string
  productSku: string | null
  productName: string
  imgLink: string | null
  customCPM: number | null
  createdAt: string
  updatedAt: string
}

export type SignupOrderPreviewResponseSuccess = {
  success: true
  products: ShopifyStoreProduct[]
  minBountyPayment: number
  storeName: string
}

export type SignupOrderPreviewResponseFail = {
  success: false
  message: string
}

export type SignupOrderPreviewResponse =
  | SignupOrderPreviewResponseSuccess
  | SignupOrderPreviewResponseFail

export const signupNewUserBody = z.object({
  phone: z.string(),
  shopUrl: z.string().optional(),
  orderId: z.string().optional().nullable(),
  email: z.string().email().optional().nullable(),
})

export type SignupNewUserBody = z.infer<typeof signupNewUserBody>

export type SignUpNewUserResponseCode =
  | 'INVALID_PHONE_NUMBER'
  | 'DUPLICATE_PHONE_NUMBER'
  | 'INVALID_STORE_URL'
  | 'SIGNUP_SUCCESS'
  | 'SERVER_ERROR'
  | 'INVALID_BODY'

export type SignupNewUserResponseSuccess = {
  success: true
  code: SignUpNewUserResponseCode
  message: string
  userId: string
}

export type SignupNewUserResponseFail = {
  success: false
  code: SignUpNewUserResponseCode
  message: string
}

export type SignupNewUserResponse =
  | SignupNewUserResponseSuccess
  | SignupNewUserResponseFail
