import { prettyFloatingDate } from '@bounty/utils'
import { ComponentType } from 'react'
import { Grid, Box, Text, Image, Heading } from './Chakra'

type BlogListItemProps = {
  /**
   * A react router link. If this is remix, make sure to make in the remix link.
   * Otherwise, react-router-dom
   */
  link: ComponentType<any>
  blog: {
    _id: string
    title: string
    description: any[]
    publishedAt: string
    slug: string
    mainImage: string
    categories: { title: string; slug: string }[]
    authors: {
      title: string
      image: string
      slug: string
    }[]
  }
}

export const BlogListItem = ({ blog, link: Link }: BlogListItemProps) => {
  return (
    <Box mb="6">
      <Link to={`./${blog.slug}`} prefetch="intent">
        <Image
          width="100%"
          height={['200px']}
          objectFit="cover"
          mb="4"
          alt={blog.title}
          src={blog.mainImage}
        />
        <Text mb="2" color="gray.700" fontSize="sm">
          {blog.authors[0].title} | {prettyFloatingDate(blog.publishedAt)}
        </Text>
        <Box>
          <Heading fontWeight="semibold" size="md" as="h2">
            {blog.title}
          </Heading>
          <Text noOfLines={3} _groupHover={{ color: 'white' }}>
            {blog.description}
          </Text>
        </Box>
      </Link>
    </Box>
  )
}

export type BlogListProps = {
  blogs: BlogListItemProps['blog'][]
  link: BlogListItemProps['link']
}

export const BlogList = ({ blogs, link }: BlogListProps) => {
  return (
    <Grid
      templateColumns={[
        'repeat(1, minmax(0, 1fr))',
        'repeat(1, minmax(0, 1fr))',
        'repeat(2, minmax(0, 1fr))',
      ]}
      gap="4"
    >
      {blogs.map((blog) => (
        <BlogListItem key={blog._id} blog={blog} link={link} />
      ))}
    </Grid>
  )
}
