import {
  PageHeader,
  Flex,
  Box,
  Text,
  Button,
  Link as ChakraLink,
  PageSkeleton,
  FastForm,
  FastFormButton,
  FastFormColorInput,
  FastFormInput,
  FastFormSwitch,
  useToast,
  FastFormControl,
  Image,
  BoxProps,
} from '@bounty/web-components'
import { UnreachableCaseError } from '@bounty/utils'
import { FC, ComponentPropsWithoutRef, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Page } from '../../components/Page'
import {
  EmbedLocation,
  SnippetCustomizeDocument,
  UpdateConfigForEmbedDocument,
} from '../../generated/backendGraphql'
import { useQueryBackend, useMutationBackend } from '../../apollo/backend/hooks'
import { ThankYouSnippetPreview } from './ThankYouPlacement/ThankYouSnippetPreview'
import { ProductSnippetPreview } from './ProductPlacement/ProductSnippetPreview'
import { getPlacementBag } from './SnippetCustomize.utils'
import { useWatch, useFormState, useController } from 'react-hook-form'
import { LandingPagePreview } from './LandingPagePlacement/LandingPageSnippetPreview'
import { useDropzone } from 'react-dropzone'
import { StoreAssetUploader } from '../../utils/StoreAssetUploader'
import { RemoteConfigSnippetSetting } from '@bounty/constants'

type SnippetCustomizeProps = {
  location: EmbedLocation
}

const isFileWithPreview = (x: unknown): x is File & { preview: string } => {
  // @ts-expect-error we are appending preview to the file object due to https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL#memory_management
  return x?.preview != null && x instanceof File
}

const mapParamToEmbedLocation = (param: string | undefined) => {
  if (!param) return null

  switch (param) {
    case 'product':
      return 'PRODUCT'
    case 'thank-you':
      return 'THANKYOU'
    case 'landing-page':
      return 'LANDINGPAGE'

    default:
      return null
  }
}

type LivePreviewProps = {
  location: EmbedLocation
}

const LivePreview: FC<LivePreviewProps> = ({ location }) => {
  const formValues = useWatch()

  const configProps: Record<string, any> = {}
  for (const prop in formValues) {
    const value = formValues[prop]

    if (isFileWithPreview(value)) {
      configProps[prop] = value.preview
    } else {
      configProps[prop] = value
    }
  }

  switch (location) {
    case 'PRODUCT':
      return <ProductSnippetPreview height="auto" configProps={configProps} />
    case 'THANKYOU':
      return <ThankYouSnippetPreview height="auto" configProps={configProps} />
    case 'LANDINGPAGE':
      return <LandingPagePreview height="auto" configProps={configProps} />

    default:
      throw new UnreachableCaseError(location)
  }
}

const SubmitButton = ({
  isLoading = false,
  ...rest
}: ComponentPropsWithoutRef<typeof FastFormButton>) => {
  const { isDirty } = useFormState()
  return (
    <FastFormButton
      colorScheme="darkBlack"
      isDisabled={isLoading || isDirty === false}
      isLoading={isLoading}
      type="submit"
      data-testid="snippetCustomizeSave"
      {...rest}
    >
      Save
    </FastFormButton>
  )
}

const focusProps: BoxProps = {
  borderColor: 'blue.500',
  outline: 'none',
}

const dragActiveProps: BoxProps = {
  borderColor: 'blue.500',
  background: 'blue.50',
}

const dragRejectProps: BoxProps = {
  borderColor: 'red.500',
  background: 'red.50',
}

const dragAcceptProps: BoxProps = {
  borderColor: 'green.500',
  background: 'green.50',
}

const ImageUpload = ({ label, name }: { label: string; name: string }) => {
  const [dropError, setDropError] = useState('')
  const {
    field: { onChange, value },
    formState: { errors },
  } = useController({ name })
  // See: https://react-dropzone.js.org/#!/Previews
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    isDragActive,
  } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpeg', 'jpg'],
    },
    multiple: false,
    onDrop: (acceptedFiles, rejectedFiles) => {
      setDropError('')

      if (rejectedFiles.length > 0) {
        setDropError('File dropped not an accepted format.')
        return
      }

      if (acceptedFiles.length > 0) {
        const file = Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        })

        onChange(file)
      }
    },
  })
  const error = errors[name] ?? { message: dropError }

  useEffect(() => {
    return () => {
      // Make sure to revoke the data uris to avoid memory leaks, will run on unmount or file replace
      if (isFileWithPreview(value)) {
        URL.revokeObjectURL(value.preview)
      }
    }
  }, [value])

  return (
    <FastFormControl label={label} error={error}>
      <Box mb="4">
        <Box
          background={'gray.50'}
          border="2px solid"
          borderRadius="md"
          borderColor={'gray.200'}
          padding={4}
          outline={'none'}
          cursor="pointer"
          {...getRootProps({ className: 'dropzone' })}
          {...(isFocused && focusProps)}
          {...(isDragActive && dragActiveProps)}
          {...(isDragReject && dragRejectProps)}
          {...(isDragAccept && dragAcceptProps)}
        >
          <input name={name} {...getInputProps()} />
          <Box display="flex" alignItems="center">
            <Box width="30%" pr="4" height="80px">
              <Image
                width="100%"
                objectFit="cover"
                height="100%"
                src={isFileWithPreview(value) ? value.preview : value}
              />
            </Box>
            <Box p="2">
              <Button
                variant="link"
                size="sm"
                mb="2"
                textDecoration="underline"
                color="gray.600"
                tabIndex={-1}
              >
                Replace
              </Button>
              <Text size="xs" color="gray.500" fontWeight="semibold">
                1MB max, .jpg/.png
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </FastFormControl>
  )
}

const buildAssetUrl = (storeId: string, uploadKey: string) => {
  return `https://shop-assets.bounty.co/${storeId}/${uploadKey}`
}

const getUploadKey = (setting: RemoteConfigSnippetSetting) => {
  if (setting.type !== 'image') {
    throw new Error('Cannot upload image for non image type!')
  }

  return setting.imageLookupKey
}

const uploadImagesAndReturnUrls = async ({
  data,
  configSettings,
  storeId,
}: {
  data: Record<string, any>
  configSettings: RemoteConfigSnippetSetting[]
  storeId: string
}) => {
  const filesToUpload: [setting: RemoteConfigSnippetSetting, file: File][] = []

  for (const key in data) {
    const val = data[key]

    if (isFileWithPreview(val)) {
      const foundSetting = configSettings.find((setting) => setting.id === key)

      if (!foundSetting) {
        throw new Error(`Cannot find setting for key, ${key}`)
      }

      filesToUpload.push([foundSetting, val])
    }
  }

  const uploadedConfigImages: Record<string, string> = {}
  await Promise.all(
    filesToUpload.map(async ([setting, file]) => {
      try {
        const key = getUploadKey(setting)
        const resp = await new StoreAssetUploader().uploadSnippetImage(
          key,
          file,
        )

        if (resp.code === 'FILE_UPLOADED') {
          uploadedConfigImages[setting.id] = buildAssetUrl(storeId, key)
        }
      } catch (error) {
        console.error(error)
      }
    }),
  )

  return uploadedConfigImages
}

export const SnippetCustomizeComponent: FC<SnippetCustomizeProps> = ({
  location,
}) => {
  const toast = useToast()
  const { data, loading } = useQueryBackend(SnippetCustomizeDocument)
  const [mutation, { loading: updateConfigForEmbedLoading }] =
    useMutationBackend(UpdateConfigForEmbedDocument)

  if (loading || !data) return <PageSkeleton />
  const {
    currentStore: { embeds, id },
  } = data

  const embed = embeds.find((e) => e.code === location) || {
    configuration: { meta: {} },
  }
  const { schema, configSettings, defaultValues } = getPlacementBag(
    location,
    embed,
  )

  return (
    <FastForm
      schema={schema}
      style={{ flex: 1 }}
      onSubmit={async (data) => {
        const values = await uploadImagesAndReturnUrls({
          data,
          configSettings,
          storeId: id,
        })

        await mutation({
          variables: {
            code: location,
            config: { ...data, ...values },
          },
        })

        toast({
          title: 'Snippet customize success',
          description: 'You should see updates on your shop within 5 minutes.',
          status: 'success',
          id: 'snippetCustomizeSuccess',
        })
      }}
      formProps={{
        defaultValues,
      }}
    >
      <Page
        name="Customize User Experience"
        data-testid="customize-user-experience-page"
        fixedHeight="all"
        addBottomSpacing={false}
        display="flex"
        flexDir="column"
        fluid
        pl={['1rem', '2rem', '3rem', '4rem']}
        px="0"
        pb="4"
      >
        <Flex justifyContent="space-between" alignItems="flex-start" pr="12">
          {/* The landing page hides h1 tags because of how shopify works. Since the script runs it does it here as well */}
          <PageHeader as="h2">Customize User Experience</PageHeader>
          <SubmitButton isLoading={updateConfigForEmbedLoading} />
        </Flex>
        <Box
          display="flex"
          flex="1"
          minH="0"
          borderTop="1px solid"
          borderColor="gray.300"
        >
          <Box flex="1" p="8">
            <LivePreview location={location} />
          </Box>
          <Box
            width="350px"
            overflowY="auto"
            py="6"
            px="10"
            borderLeft="1px solid"
            borderColor="gray.300"
          >
            {configSettings.map((item) => {
              const { type } = item

              switch (type) {
                case 'checkbox':
                  return (
                    <FastFormSwitch
                      key={item.id}
                      label={item.label}
                      name={item.id}
                      size="lg"
                    />
                  )
                case 'color':
                  return (
                    <FastFormColorInput
                      key={item.id}
                      label={item.label}
                      name={item.id}
                      size="lg"
                    />
                  )
                case 'text':
                  return (
                    <FastFormInput
                      key={item.id}
                      label={item.label}
                      name={item.id}
                      size="lg"
                    />
                  )
                case 'image':
                  return (
                    <ImageUpload
                      key={item.id}
                      label={item.label}
                      name={item.id}
                    />
                  )

                default:
                  throw new UnreachableCaseError(type)
              }
            })}
          </Box>
        </Box>
      </Page>
    </FastForm>
  )
}

export const SnippetCustomize: FC<unknown> = () => {
  const { placement } = useParams()
  const location = mapParamToEmbedLocation(placement)

  if (!location) {
    return (
      <Box data-testid="customizeSnippetError">
        <PageHeader>Customize Snippet</PageHeader>
        <Text>
          The user experience customizer is not available for <b>{placement}</b>
          . Please{' '}
          <ChakraLink textDecoration="underline" isExternal={false} to="../">
            go back to user experiences
          </ChakraLink>{' '}
          to customize a different snippet.
        </Text>
      </Box>
    )
  }

  return <SnippetCustomizeComponent location={location} />
}
