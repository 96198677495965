import {
  useQuery,
  useMutation,
  useLazyQuery,
  DocumentNode,
  TypedDocumentNode,
  OperationVariables,
  QueryHookOptions,
  MutationHookOptions,
  ApolloCache,
  DefaultContext,
  MutationTuple,
  QueryResult,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client'
import { getShopifyAdminClient } from './client'

export const useApolloClientShopifyAdmin = () => getShopifyAdminClient()

export const useQueryShopifyAdmin = <
  TData = any,
  TVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables> | undefined,
): QueryResult<TData, TVariables> =>
  useQuery(query, { client: getShopifyAdminClient(), ...options })

export const useLazyQueryShopifyAdmin = <
  TData = any,
  TVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<TData, TVariables> | undefined,
): QueryTuple<TData, TVariables> =>
  useLazyQuery(query, { client: getShopifyAdminClient(), ...options })

export const useMutationShopifyAdmin = <
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?:
    | MutationHookOptions<TData, TVariables, TContext, ApolloCache<any>>
    | undefined,
): MutationTuple<TData, TVariables, TContext, TCache> =>
  useMutation(mutation, { client: getShopifyAdminClient(), ...options })
