import {
  ContentBox,
  Divider,
  Flex,
  PageHeader,
  Heading,
  Text,
  PageSkeleton,
} from '@bounty/web-components'
import { FC } from 'react'
import { useQueryBackend } from '../../apollo/backend/hooks'
import { Page } from '../../components/Page'
import {
  ContentPricingSettingsDocument,
  AverageOrderValueDocument,
} from '../../generated/backendGraphql'
import { InitialCpm } from './InitialCpm'
import { MinimumPayout } from './MinimumPayout'

export type ContentPricingProps = unknown

export const AOVText: FC = () => {
  const { data, loading } = useQueryBackend(AverageOrderValueDocument)

  if (!data || loading || data.averageOrderValue <= 1) return null

  return (
    <Text>
      We recommend 5-10% of your AOV (Average order value). We estimate your AOV
      to be around ${data.averageOrderValue}.
    </Text>
  )
}

export const ContentPricing: FC<ContentPricingProps> = () => {
  const { data, loading } = useQueryBackend(ContentPricingSettingsDocument)

  if (!data || loading) return <PageSkeleton />

  return (
    <Page data-testid="content-pricing-page" name="Creator Incentives">
      <PageHeader>Creator Incentives</PageHeader>

      <ContentBox mb="6">
        <Heading as="h2" size="md" mb="2">
          Set Your CPMs
        </Heading>
        <Text>
          As views go up, the CPMs will get cheaper. You can define your
          starting CPM price here and view examples of payouts creators will
          receive based on the price you are willing to pay. Please note,
          creators only get paid for the views they receive in the first 48
          hours, and they must leave the content up for at least 15 days to be
          eligible for payment.
        </Text>

        <Flex
          mt={8}
          justifyContent={[
            'center',
            'center',
            'center',
            'center',
            'space-around',
          ]}
          alignItems={['center', 'center', 'center', 'center', 'flex-start']}
          flexDirection={['column', 'column', 'column', 'column', 'row']}
        >
          <InitialCpm
            initialCurrentMultiplierValue={
              data.currentStore.contentPricingMultiplier
            }
          />
        </Flex>
      </ContentBox>

      <Divider my="6" />

      <ContentBox mb="6">
        <Heading as="h2" size="md" mb="2">
          First Time Minimum Payout
        </Heading>
        <Text>
          If you want a lot more content and positive mentions on TikTok, you
          can offer a minimum payout for each creator's first approved post.
          Sometimes a great piece of content won't get many views, so this
          lowers the risk for creators trying Bounty for the first time.
        </Text>
        <AOVText />

        <Flex
          mt="8"
          justifyContent={[
            'center',
            'center',
            'center',
            'center',
            'space-around',
          ]}
          alignItems={['center', 'center', 'center', 'center', 'flex-start']}
          flexDirection={['column', 'column', 'column', 'column', 'row']}
        >
          <MinimumPayout
            initialCurrentMinimumPayout={data.currentStore.minBountyPayment}
          />
        </Flex>
      </ContentBox>
    </Page>
  )
}
