import * as z from 'zod'

export const ManuallyAssociateVideoRequestSchema = z
  .object({
    userId: z.string(),
    videoId: z.string(),
    bountyId: z.string(),
  })
  .strict()

export type ManuallyAssociateVideoRequest = z.infer<
  typeof ManuallyAssociateVideoRequestSchema
>
