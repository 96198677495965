import { FC, useMemo } from 'react'
import { TimeSeriesValue } from '../../generated/backendGraphql'
import { LinearGradient } from '@visx/gradient'
import { Group } from '@visx/group'
import { Bar } from '@visx/shape'
import { scaleBand, scaleLinear } from '@visx/scale'
import { AxisBottom, AxisRight } from '@visx/axis'
import { format } from 'date-fns'
import { GridRows } from '@visx/grid'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import { UnreachableCaseError } from '@bounty/utils'
import { Box } from '@chakra-ui/layout'
import { DateRangeType } from './Home'

interface ResponsiveDashboardChartProps {
  data: readonly TimeSeriesValue[]
  range: DateRangeType
}

export const ResponsiveDashboardChart: FC<ResponsiveDashboardChartProps> = (
  props,
) => {
  return (
    <ParentSize>
      {({ width, height }) => (
        <DashboardChart width={width} height={height} {...props} />
      )}
    </ParentSize>
  )
}

interface DashboardChartProps {
  data: readonly TimeSeriesValue[]
  width: number
  height: number
  range: DateRangeType
}

const DashboardChart: FC<DashboardChartProps> = ({
  width,
  height,
  data,
  range,
}) => {
  const marginRight = 20
  const marginLeft = 20
  const marginTop = 20
  const marginBottom = 20
  // bounds
  const xMax = width - marginRight - marginLeft
  const yMax = height - marginTop - marginBottom

  const xValues = data.map((d) => new Date(d.date))
  const yValues = data.map((d) => d.value)

  const dateFormatter = (d: Date) => {
    switch (range) {
      case 'TODAY':
      case 'YESTERDAY':
        return format(d, 'ha')
      case 'WEEK':
      case '7_DAYS':
        return format(d, 'eee')
      case 'MONTH':
      case '30_DAYS':
      case '3_MONTHS':
        return format(d, 'M/d')
      case '12_MONTHS':
        return format(d, 'MMM')
      default:
        throw new UnreachableCaseError(range)
    }
  }

  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleBand<Date>({
        range: [0, xMax],
        domain: xValues,
        round: true,
      }),
    [xMax, xValues],
  )
  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [0, Math.max(...yValues)],
      }),
    [yMax, yValues],
  )
  return (
    <Box position="relative">
      <svg width={width} height={height}>
        <LinearGradient
          from="#9444FA"
          fromOpacity=".7"
          toOpacity=".7"
          to="#385CDB"
          id="gradient"
        />
        <Group>
          <GridRows
            top={marginTop}
            numTicks={7}
            scale={yScale}
            width={xMax}
            height={yMax}
            stroke="#e0e0e0"
          />
          <AxisRight
            top={marginTop}
            left={xMax}
            scale={yScale}
            tickFormat={(n) => parseInt(n.toString()).toString()}
            tickValues={yScale.ticks().filter(Number.isInteger)}
            numTicks={7}
            hideTicks
            hideAxisLine
          />
          <Group top={marginTop}>
            {data.map((d) => {
              const barWidth = xScale.bandwidth()
              const barHeight = yMax - (yScale(d.value) ?? 0)
              const barX = xScale(new Date(d.date))
              const barY = yMax - barHeight
              return (
                <Bar
                  key={`bar-${d.date}-${range}`}
                  x={barX}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  fill="url(#gradient)"
                />
              )
            })}
            <AxisBottom
              top={yMax - 5}
              scale={xScale}
              hideTicks
              hideAxisLine
              tickFormat={dateFormatter}
              numTicks={10}
            />
          </Group>
        </Group>
      </svg>
    </Box>
  )
}
