import { requiredBoolean, requiredString } from '@bounty/utils'

export const API_URL: string = requiredString(
  process.env.VITE_API_URL,
  'VITE_API_URL',
)

export const DEPLOY_ENV: string = requiredString(
  process.env.VITE_DEPLOY_ENV,
  'VITE_DEPLOY_ENV',
)

export const SENTRY_DSN: string = requiredString(
  process.env.VITE_SENTRY_DSN,
  'VITE_SENTRY_DSN',
)

export const LD_CLIENT_ID: string = requiredString(
  process.env.VITE_LD_CLIENT_ID,
  'VITE_LD_CLIENT_ID',
)

export const SEGMENT_WRITE_KEY: string = requiredString(
  process.env.VITE_SEGMENT_WRITE_KEY,
  'VITE_SEGMENT_WRITE_KEY',
)

export const APP_NAME: string = requiredString(
  process.env.VITE_APP_NAME,
  'VITE_APP_NAME',
)

export const SNIPPET_PRODUCTS_URL: string = requiredString(
  process.env.VITE_SNIPPET_PRODUCTS_URL,
  'VITE_SNIPPET_PRODUCTS_URL',
)

export const SNIPPET_THANK_YOU_URL: string = requiredString(
  process.env.VITE_SNIPPET_THANK_YOU_URL,
  'VITE_SNIPPET_THANK_YOU_URL',
)

export const SNIPPET_LANDING_PAGE_URL: string = requiredString(
  process.env.VITE_SNIPPET_LANDING_PAGE_URL,
  'VITE_SNIPPET_LANDING_PAGE_URL',
)

export const THEME_EXTENSION_UUID: string = requiredString(
  process.env.VITE_THEME_EXTENSION_UUID,
  'VITE_THEME_EXTENSION_UUID',
)

export const RUN_WITH_MOCKED_BACKEND: boolean = requiredBoolean(
  process.env.VITE_RUN_WITH_MOCKED_BACKEND,
  'VITE_RUN_WITH_MOCKED_BACKEND',
)

export const CLOUDINARY_URL: string = requiredString(
  process.env.VITE_CLOUDINARY_URL,
  'CLOUDINARY_URL',
)

export const COMMIT_REF: string = requiredString(
  process.env.VITE_COMMIT_REF,
  'VITE_COMMIT_REF',
)

export const S3_BUCKET_URL = requiredString(
  process.env.VITE_S3_BUCKET_URL,
  'VITE_S3_BUCKET_URL',
)

export const IS_BUILT_TARGETING_AN_ENVIRONMENT =
  process.env.NODE_ENV === 'production'

export const SANITY_PROJECT_ID = requiredString(
  process.env.VITE_SANITY_PROJECT_ID,
  'VITE_SANITY_PROJECT_ID',
)

export const SANITY_DATASET = requiredString(
  process.env.VITE_SANITY_DATASET,
  'VITE_SANITY_DATASET',
)
