import { Box, Heading, Text, Link } from '@bounty/web-components'
import { FC } from 'react'
import { Page } from '../../../../components/Page'
import { TikTokForm } from '../../../Settings/TikTokForm'
import { useNavigate } from 'react-router-dom'
import { useAnalytics } from '@bounty/hooks'

export const TikTok: FC = () => {
  const navigate = useNavigate()
  const { track } = useAnalytics()

  return (
    <Page
      data-testid="tik-tok-page"
      name="Get Started"
      addBottomSpacing={false}
    >
      <Box marginX="auto" maxWidth="350px" paddingTop="150px">
        <Heading as="h1" maxWidth="284px" mb="8" size="lg">
          Great! First, what’s your brand’s TikTok handle?
        </Heading>
        <Box mb="8">
          <TikTokForm
            variant="large"
            onSubmitSuccess={({ tikTokUsername }) => {
              track('Welcome TikTok Submit Completed', { tikTokUsername })
              navigate('../products')
            }}
          />
        </Box>
        <Text fontSize="sm">
          If you don’t have a TikTok yet you will need to create one{' '}
          <Link
            onClick={() => {
              track('Welcome Brand Create TikTok Clicked')
            }}
            isExternal
            href="https://www.tiktok.com/signup"
          >
            here
          </Link>{' '}
          first.
        </Text>
      </Box>
    </Page>
  )
}
