export const isBoolean = (x: unknown): x is boolean => 'boolean' === typeof x

export const isNil = <T>(x: T | null | undefined): x is null | undefined => {
  return x == null
}

export const isString = (x: unknown): x is string =>
  typeof x === 'string' || x instanceof String

// eslint-disable-next-line @typescript-eslint/ban-types
export const isFunction = (x: unknown): x is Function =>
  // eslint-disable-next-line eqeqeq
  Object.prototype.toString.call(x) == '[object Function]'

/**
 * Shopify passes us an ID like this:
 * gid://shopify/Product/7197695967448
 *
 * This will return 7197695967448
 */
export const getIdFromShopifyGid = (x: string): string => {
  const maybeId = /([^/]+$)/.exec(x)?.[0]

  if (!maybeId) {
    // TODO: can this even throw?
    throw new Error(`Could not find ID from ${x}!`)
  }

  return maybeId
}

export const castUndefinedOrValue = <T>(x: T): NonNullable<T> | undefined => {
  return x === null ? undefined : (x as NonNullable<T>)
}
