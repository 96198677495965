import { FC, ReactNode, useState } from 'react'
import {
  Box,
  FlexProps,
  ButtonGroup,
  IconButton,
  Flex,
} from '@bounty/web-components'
import { MobilePhoneIcon, DesktopIcon } from '@bounty/icons'
import { useScript } from '@bounty/hooks'
import { SNIPPET_PRODUCTS_URL } from '../../../config/env'

export type ProductSnippetPreviewProps = FlexProps & {
  interiorContent?: ReactNode
  configProps?: { [x: string]: any }
}

export const ProductSnippetPreview: FC<ProductSnippetPreviewProps> = ({
  interiorContent = null,
  configProps,
  ...rest
}) => {
  const [displayMode, setDisplayMode] = useState<'mobile' | 'desktop'>(
    'desktop',
  )

  useScript({ src: SNIPPET_PRODUCTS_URL, id: 'snippet-products' })

  return (
    <Flex
      position="relative"
      height="100%"
      px="10"
      py="4"
      flexDirection="column"
      minHeight="150px"
      {...rest}
    >
      {interiorContent}

      <Flex flex={1} justifyContent="center" alignItems="center">
        <Box
          width={
            displayMode === 'mobile' ? ['280px', '280px', '330px'] : '100%'
          }
        >
          {/* 
          // @ts-ignore */}
          <bounty-product-sign-up></bounty-product-sign-up>
          <script
            type="text/props"
            data-island-props="product-sign-up"
            data-testid="productSnippetProps"
          >
            {JSON.stringify(
              configProps
                ? { ...configProps, isDemoMode: true }
                : { isDemoMode: true },
            )}
          </script>
        </Box>
      </Flex>

      <Box position="absolute" top="10px" right="10px">
        <ButtonGroup isAttached variant="outline" size="sm">
          <IconButton
            onClick={() => setDisplayMode('desktop')}
            isActive={displayMode === 'desktop'}
            icon={<DesktopIcon />}
            aria-label="Sets preview in desktop mode"
          />
          <IconButton
            onClick={() => setDisplayMode('mobile')}
            isActive={displayMode === 'mobile'}
            icon={<MobilePhoneIcon />}
            aria-label="Sets preview in mobile mode"
          />
        </ButtonGroup>
      </Box>
    </Flex>
  )
}
