import { FC } from 'react'
import { Page } from '../../../../components/Page'
import {
  Box,
  Heading,
  Text,
  Button,
  Link,
  Image,
  Flex,
} from '@bounty/web-components'
import { useAnalytics } from '@bounty/hooks'
import ProductTablePreviewImage from '../../../../images/product-table-preview.png'

export const ProductsInfo: FC = () => {
  const { track } = useAnalytics()

  return (
    <Page data-testid="products-info-page" name="Products">
      <Box marginX="auto" maxWidth="820px" paddingTop={['0', '10']}>
        <Box maxWidth="500px" marginX="auto" mb="20">
          <Heading as="h1" mb="4" size="lg">
            Now it's time to enable Bounties to be made on all of your products!
          </Heading>
          <Text as="h1" marginBottom="8" size="xl">
            Don't worry, you will be able to add/remove single products from
            Bounty any time in your brand portal.
          </Text>

          <Flex justifyContent="flex-end">
            <Button
              textAlign={'right'}
              as={Link}
              data-testid="products-info-next-button"
              colorScheme="darkBlack"
              to="../budget"
              variant="solid"
              onClick={() => {
                track('Welcome Configure Products Completed')
              }}
            >
              Done
            </Button>
          </Flex>
        </Box>

        <Image
          display="block"
          marginBottom="12"
          marginX="auto"
          maxWidth="485px"
          objectFit="contain"
          src={ProductTablePreviewImage}
          width="100%"
        />
      </Box>
    </Page>
  )
}
