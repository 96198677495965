import { useToast } from '@bounty/web-components'
import { APP_NAME } from '../config/env'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

export const useSubscriptionSuccess = () => {
  const toast = useToast()
  const location = useLocation()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    if (params.get('subscriptionSuccess') === 'true') {
      toast({
        title: '🎉 Subscription Success',
        description: `You've successfully signed up for ${APP_NAME}!`,
        position: 'top-right',
        status: 'success',
      })
    }
    // something about toast is causing this to render 3 times
    // eslint-disable-next-line
  }, [location.search])
}
