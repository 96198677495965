import { FC, useRef, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Drawer,
  DrawerProps,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  AspectRatio,
  Text,
  Flex,
  Avatar,
  Link,
  IconButton,
  IconButtonProps,
  PageSkeleton,
} from '@bounty/web-components'
import {
  ChevronLeftIcon,
  HeartIcon,
  MessageIcon,
  ReplyIcon,
  StarIcon,
  ViewIcon,
} from '@bounty/icons'
import { prettyCurrency, prettyNumber, isNil, prettyViews } from '@bounty/utils'
import {
  ContentLibraryItemDrawerDocument,
  ContentLibraryResponse,
} from '../../generated/backendGraphql'
import { S3_BUCKET_URL } from '../../config/env'
import { format } from 'date-fns'
import { Maybe } from 'graphql/jsutils/Maybe'
import { useAuthState } from '../../hooks/useAuth'
import {
  getCloudinaryUserImage,
  getCloudinaryVideoImageUrl,
} from '../../utils/urls'
import { SparkCodeRequest } from '../../components/SparkCodeRequest/SparkCodeRequest'
import { useQueryBackend } from '../../apollo/backend/hooks'
import { useParams } from 'react-router-dom'

export type ContentLibraryFavoriteButtonProps = Omit<
  IconButtonProps,
  'aria-label'
> & {
  isFavorited: boolean
  variant?: 'default' | 'expanded'
}

export const ContentLibraryFavoriteButton: FC<
  ContentLibraryFavoriteButtonProps
> = ({ isFavorited, variant = 'default', ...rest }) => {
  const boxSize = variant === 'expanded' ? '35px' : ['50px', '30px']

  return (
    <IconButton
      aria-label="Favorite video"
      icon={
        <StarIcon
          boxSize={boxSize}
          stroke={isFavorited ? 'cyan.100' : 'gray.50'}
          fill={isFavorited ? 'cyan.500' : 'gray.300'}
        />
      }
      height="auto"
      minWidth="auto"
      width="auto"
      {...(variant === 'expanded'
        ? {
            _after: {
              content: isFavorited
                ? '"Remove from Favorites"'
                : '"Add to Favorites"',
              lineHeight: boxSize,
              color: 'gray.500',
              opacity: 0,
              transition: 'all 250ms linear',
            },
          }
        : {})}
      _hover={{
        _after: {
          opacity: 1,
        },
      }}
      variant="unstyled"
      {...rest}
    />
  )
}

type ContentLibraryItemDrawerProps = Omit<DrawerProps, 'children'> & {
  onFavoriteClicked: (item: ContentLibraryResponse['items'][0]) => void
}

export const ContentLibraryItemDrawer: FC<ContentLibraryItemDrawerProps> = ({
  onFavoriteClicked,
  ...rest
}) => {
  const { tikTokVideoId } = useParams<{ tikTokVideoId: string }>()

  if (!tikTokVideoId) {
    throw new Error(
      `Cannot render ContentLibraryItemDrawer if no tikTokVideoId param given!`,
    )
  }

  const { data, loading } = useQueryBackend(ContentLibraryItemDrawerDocument, {
    variables: { tikTokVideoId },
  })

  const videoRef = useRef<HTMLVideoElement>(null)
  const [shouldPlayVideo, setShouldPlayVideo] = useState(false)
  const [showSparkCodeForm, setShowSparkCodeForm] = useState(false)
  const { shopifyStoreUrl } = useAuthState()

  useEffect(() => {
    if (shouldPlayVideo) {
      videoRef.current?.play()
    } else {
      videoRef?.current?.pause()
    }
  }, [shouldPlayVideo])

  if (loading === true || !data) {
    return <PageSkeleton />
  }

  const { contentLibraryItem, getRequestsForVideo } = data

  // has a request and last request is not in a finished state
  const requestInProgress =
    (getRequestsForVideo.length ?? 0) > 0 &&
    !['PAID_OUT', 'CREATOR_REJECTED'].includes(
      getRequestsForVideo[0].status ?? '',
    )

  const formatCurrency = (value?: Maybe<number>) =>
    isNil(value) ? 'N/A' : prettyCurrency(value, { precision: 2 })

  const makeOrderUrl = (orderName?: Maybe<string>) =>
    isNil(orderName)
      ? null
      : `https://${shopifyStoreUrl}/admin/orders/${contentLibraryItem.shopifyOrderId}`

  const tags = [
    { title: 'Payout', value: formatCurrency(contentLibraryItem.value) },
    { title: 'CPM', value: formatCurrency(contentLibraryItem.cpm) },
    {
      title: 'Post Date',
      value: format(new Date(contentLibraryItem.liveAt), 'MM/dd/yy'),
    },
    {
      title: 'Order',
      value: (
        <Link
          textDecoration="underline"
          isExternal
          href={makeOrderUrl(contentLibraryItem.shopifyOrderName) ?? ''}
        >
          {contentLibraryItem.shopifyOrderName ?? 'N/A'}
        </Link>
      ),
    },
    {
      title: 'Order Total',
      value: formatCurrency(contentLibraryItem.orderTotal),
    },
  ]

  return (
    <Drawer size="lg" {...rest}>
      <DrawerOverlay />
      <DrawerContent pb="12">
        <DrawerCloseButton />
        <DrawerHeader>
          {showSparkCodeForm && (
            <Flex
              alignItems="center"
              cursor="pointer"
              onClick={() => setShowSparkCodeForm(false)}
            >
              <ChevronLeftIcon width="24px" height="24px" />
              <Text fontSize="lg" ml={2}>
                Back
              </Text>
            </Flex>
          )}
        </DrawerHeader>
        <DrawerBody
          data-testid={`content-library-drawer-for-${contentLibraryItem.id}`}
          data-active={rest.isOpen}
        >
          <Flex flexDir="column" alignItems="center">
            {showSparkCodeForm ? (
              <SparkCodeRequest
                item={contentLibraryItem}
                onSubmit={() => setShowSparkCodeForm(false)}
              />
            ) : (
              <>
                <Box width="65%">
                  <Flex
                    width={['255px', '305px']}
                    mb="4"
                    justifyContent="space-between"
                    align="center"
                  >
                    <ContentLibraryFavoriteButton
                      isFavorited={contentLibraryItem.isFavorited}
                      onClick={() => onFavoriteClicked(contentLibraryItem)}
                      variant="expanded"
                    />

                    <Button
                      as={Link}
                      isExternal={true}
                      href={contentLibraryItem.videoUrl}
                      borderRadius="full"
                      variant="outline"
                    >
                      View on TikTok
                    </Button>
                  </Flex>
                  <Flex
                    width="100%"
                    justifyContent="center"
                    alignItems="flex-end"
                  >
                    <Box width="100%">
                      <AspectRatio
                        ratio={0.56}
                        cursor="pointer"
                        mb="2"
                        onClick={() => setShouldPlayVideo((x) => !x)}
                      >
                        <video
                          poster={getCloudinaryVideoImageUrl(
                            contentLibraryItem.videoId,
                          )}
                          autoPlay={false}
                          loop={true}
                          ref={videoRef}
                          preload="none"
                          controls
                          controlsList="nodownload"
                          style={{ borderRadius: 12 }}
                        >
                          <source
                            src={`${S3_BUCKET_URL}/${contentLibraryItem.videoId}.mp4`}
                            type="video/mp4"
                          ></source>
                        </video>
                      </AspectRatio>
                    </Box>
                    <Flex ml={4} flexDir="column">
                      <Flex mb={5} flexDir="column">
                        <HeartIcon width={18} height={18} color="gray.500" />
                        <Text fontSize="xl" fontWeight="bold">
                          {prettyNumber(contentLibraryItem.likeCount)}
                        </Text>
                      </Flex>
                      <Flex mb={5} flexDir="column">
                        <MessageIcon
                          width={18}
                          height={18}
                          fill="gray.500"
                          color="gray.500"
                        />
                        <Text fontSize="xl" fontWeight="bold">
                          {prettyNumber(contentLibraryItem.commentCount)}
                        </Text>
                      </Flex>
                      <Flex mb={5} flexDir="column">
                        <ReplyIcon width={18} height={18} color="gray.500" />
                        <Text fontSize="xl" fontWeight="bold">
                          {prettyNumber(contentLibraryItem.shareCount)}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex
                    width="85%"
                    alignItems="center"
                    justifyContent="space-between"
                    mb="6"
                  >
                    <Flex alignItems="center">
                      <ViewIcon></ViewIcon>
                      <Text ml="2" fontSize="2xl" fontWeight="semibold">
                        {prettyViews(contentLibraryItem.viewCount)}
                      </Text>
                    </Flex>
                    <Avatar
                      size="sm"
                      as={Link}
                      isExternal
                      name={contentLibraryItem.profileName}
                      href={`https://www.tiktok.com/${contentLibraryItem.profileName}`}
                      src={getCloudinaryUserImage(contentLibraryItem.userId)}
                    />
                  </Flex>
                  <Text mb="6">{contentLibraryItem.videoTitle}</Text>
                  <Text mb={4} fontWeight="bold" fontSize="2xl">
                    Stats
                  </Text>
                  <Flex flexDir="column" mb={100}>
                    {tags.map((t) => (
                      <Flex key={t.title} mb={2} justifyContent="space-between">
                        <Text as="span">{t.title}:</Text>
                        <Text as="span">{t.value}</Text>
                      </Flex>
                    ))}
                  </Flex>
                </Box>
                {contentLibraryItem.status === 'APPROVED' && (
                  <Flex
                    zIndex={100}
                    width="100%"
                    height="100px"
                    bottom={0}
                    position="fixed"
                    boxShadow="0 0 10px 2px rgba(0,0,0,.15)"
                    justifyContent="center"
                    alignItems="center"
                    backgroundColor="white"
                  >
                    <Button
                      colorScheme="darkBlack"
                      borderRadius="full"
                      size="lg"
                      onClick={() => setShowSparkCodeForm(true)}
                    >
                      {requestInProgress
                        ? 'View Spark Code Request'
                        : 'Request Spark Code'}
                    </Button>
                  </Flex>
                )}
              </>
            )}
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
