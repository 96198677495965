import { FC, useState } from 'react'
import {
  Flex,
  Box,
  Text,
  Button,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Tooltip,
} from '@bounty/web-components'
import { useMutationBackend } from '../../apollo/backend/hooks'
import { UpdateMinBountyPayoutDocument } from '../../generated/backendGraphql'

const styles = {
  sliderText: {
    color: '#BBBBBB',
  },
}

const MinPayoutSlider: FC<{
  minPayoutValue: number
  onChange: (cpm: number) => void
}> = (props) => {
  const [sliderValue, setSliderValue] = useState(props.minPayoutValue)
  const [showTooltip, setShowTooltip] = useState(false)
  const TICKS = [0, 5, 10, 15, 20, 25]

  return (
    <Slider
      id="slider"
      defaultValue={props.minPayoutValue}
      min={0}
      max={25}
      colorScheme="darkBlack"
      value={sliderValue}
      onChange={(v) => {
        setSliderValue(v)
        props.onChange(v)
      }}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      pt={2}
      pb={3}
    >
      {TICKS.map((val, idx) => {
        return (
          <SliderMark
            key={val}
            value={val}
            mt="3"
            ml={idx === 0 ? '0' : TICKS.length - 1 === idx ? '-7' : '-2.5'}
            fontSize="md"
            color={styles.sliderText.color}
            data-testid={`min-payout-slider-tick-${val}`}
          >
            ${val}
          </SliderMark>
        )
      })}
      <SliderTrack>
        <SliderFilledTrack />
      </SliderTrack>
      <Tooltip
        hasArrow
        bg="black"
        color="white"
        placement="top"
        isOpen={showTooltip}
        label={`$${sliderValue}`}
      >
        <SliderThumb />
      </Tooltip>
    </Slider>
  )
}

export const MinimumPayout: FC<{
  initialCurrentMinimumPayout: number
}> = (props) => {
  const [minPayout, setMinPayout] = useState(props.initialCurrentMinimumPayout)
  const [updateContentPricing, { loading: contentPricingLoading }] =
    useMutationBackend(UpdateMinBountyPayoutDocument)
  const isDirty = props.initialCurrentMinimumPayout !== minPayout

  return (
    <Flex flexDir="column" width="100%" data-testid="min-payout-box">
      <Flex flexDir="row" justifyContent={'space-between'} width="100%">
        <Box>
          <Text fontWeight={'bold'}>Minimum Payout</Text>
          <Text
            fontSize="xl"
            padding="3"
            textAlign={'center'}
            backgroundColor="rgba(0,0,0,0.1)"
            borderRadius="5px"
            data-testid="min-payout-display"
          >
            ${minPayout}
          </Text>
        </Box>
        <Button
          size="lg"
          colorScheme="darkBlack"
          isLoading={contentPricingLoading}
          disabled={!isDirty || contentPricingLoading}
          onClick={async () => {
            await updateContentPricing({
              variables: {
                minBountyPayment: minPayout,
              },
            })
          }}
        >
          Apply Changes
        </Button>
      </Flex>
      <Flex pt={3} pb={6} width="100%" flexDirection={'column'}>
        <MinPayoutSlider
          minPayoutValue={minPayout}
          onChange={(minPayout) => {
            setMinPayout(minPayout)
          }}
        />
      </Flex>
    </Flex>
  )
}
