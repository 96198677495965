import { FC, useRef } from 'react'
import {
  BoxProps,
  Heading,
  Text,
  ContentBox,
  Button,
  Skeleton,
  Box,
  Flex,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
} from '@bounty/web-components'
import { CodeSnippet } from '../../../components/CodeSnippet'
import {
  useMutationBackend,
  useQueryBackend,
} from '../../../apollo/backend/hooks'
import {
  ManualInstallDocument,
  AddScriptTagForLocationDocument,
  RemoveScriptTagForLocationDocument,
} from '../../../generated/backendGraphql'
import { getStoreEmbeds, isProductScriptTagActivated } from '@bounty/common'

type SnippetPreviewProps = BoxProps

export const ManualInstall: FC<SnippetPreviewProps> = (props) => {
  const cancelRef = useRef(null)
  const [addScriptTag, { loading: addScriptTagLoading }] = useMutationBackend(
    AddScriptTagForLocationDocument,
  )
  const [removeProductScriptTag, { loading: removeScriptTagLoading }] =
    useMutationBackend(RemoveScriptTagForLocationDocument)
  const { data, loading: queryLoading } = useQueryBackend(ManualInstallDocument)
  const { isOpen, onClose, onOpen } = useDisclosure()
  const maybeEmbeds = data && getStoreEmbeds(data.currentStore)
  const isScriptTagActivated =
    maybeEmbeds && isProductScriptTagActivated(maybeEmbeds)

  const handleSnippetActivate = async () => {
    if (queryLoading || isScriptTagActivated) {
      return
    }

    addScriptTag({
      variables: {
        code: 'PRODUCT',
      },
    })
  }

  return (
    <ContentBox position="relative" height="100%" px="10" py="8" {...props}>
      <Text
        color="green.600"
        opacity="0.6"
        fontSize="2xl"
        fontWeight="bold"
        marginBottom="2"
      >
        Option 1
      </Text>
      <Heading as="h3" size="lg">
        Manual Installation
      </Heading>
      <Box mb="4">
        <Text mb="2">First, you need to activate the widget.</Text>
        {queryLoading || data == null ? (
          <Skeleton height="35px" width="90px" />
        ) : (
          <Flex alignItems="center">
            <Button
              isDisabled={isScriptTagActivated || addScriptTagLoading}
              isLoading={addScriptTagLoading}
              flexShrink={0}
              colorScheme="darkBlack"
              data-testid="snippet-install-activate-button"
              onClick={handleSnippetActivate}
              mr="4"
            >
              {isScriptTagActivated ? 'Activated' : 'Activate Now'}
            </Button>
            {isScriptTagActivated ? (
              <Button
                isLoading={addScriptTagLoading || removeScriptTagLoading}
                flexShrink={0}
                colorScheme="darkBlack"
                data-testid="snippet-install-deactivate-button"
                onClick={onOpen}
                variant="link"
                size="sm"
              >
                Deactivate
              </Button>
            ) : null}

            <AlertDialog
              isOpen={isOpen}
              leastDestructiveRef={cancelRef}
              onClose={onClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Deactivate Widget
                  </AlertDialogHeader>

                  <AlertDialogBody>
                    Deactivating the widget will remove the script tag for your
                    site if it is not using app blocks. This will cause Bounty
                    to no longer show up on your website.
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      colorScheme="red"
                      isLoading={removeScriptTagLoading}
                      onClick={async () => {
                        if (isScriptTagActivated) {
                          await removeProductScriptTag({
                            variables: {
                              code: 'PRODUCT',
                            },
                          })

                          onClose()
                        }
                      }}
                      ml={3}
                    >
                      Delete
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </Flex>
        )}
      </Box>
      <Box>
        <Text mb="2">
          Next, add this snippet to your product page. We suggest under your add
          to cart button. But it’s up to you!
        </Text>
      </Box>
      <CodeSnippet />
    </ContentBox>
  )
}
