import {
  ContentBox,
  Box,
  Heading,
  Skeleton,
  Flex,
  Text,
  Switch,
  SwitchProps,
  FlexProps,
  HeadingProps,
  TextProps,
  Button,
} from '@bounty/web-components'
import { FC } from 'react'

export const UserExperiencePreview: FC = ({ children }) => {
  return (
    <Box
      width={['100%', '100%', '100%', '100%', '60%']}
      marginBottom={['8', '8', '8', '8', '0']}
    >
      <Heading pl="4" fontSize="lg" as="h3" size="sm" color="gray.600" mb="4">
        Preview
      </Heading>
      <Box p={['0', '0', '0', '4']} border="1px solid" borderColor="gray.600">
        {children}
      </Box>
    </Box>
  )
}

export type UserExperienceTogglerProps = FlexProps & {
  onChange: SwitchProps['onChange']
  isChecked: boolean
  isLoading: boolean
}

export const UserExperienceInfoContainer: FC = ({ children }) => {
  return (
    <Flex
      flexDir="column"
      width={['100%', '100%', '100%', '100%', '400px']}
      mr="6"
    >
      {children}
    </Flex>
  )
}

export const UserExperienceHeading: FC<HeadingProps> = ({
  children,
  ...rest
}) => {
  return (
    <Heading size="md" mb="4" {...rest}>
      {children}
    </Heading>
  )
}

export const UserExperienceText: FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text mb="6" width="80%" {...rest}>
      {children}
    </Text>
  )
}

export const UserExperienceButtonContainer: FC<FlexProps> = ({
  children,
  ...rest
}) => {
  return (
    <Flex mt="6" alignItems="flex-end" flex={1} {...rest}>
      {children}
    </Flex>
  )
}

export const UserExperienceButton: typeof Button = ({ children, ...rest }) => {
  return (
    <Button colorScheme="darkBlack" size="lg" {...rest}>
      {children}
    </Button>
  )
}

export const UserExperience: FC = ({ children }) => {
  return (
    <ContentBox
      py="8"
      px="6"
      mb="6"
      as={Flex}
      flexDirection={[
        'column-reverse',
        'column-reverse',
        'column-reverse',
        'column-reverse',
        'row',
      ]}
      justifyContent="space-between"
    >
      {children}
    </ContentBox>
  )
}
