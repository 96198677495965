import {
  ContentBox,
  Box,
  Text,
  Slider,
  SliderTrack,
  SliderThumb,
  Flex,
  Button,
  ContentBoxProps,
  Skeleton,
  Heading,
} from '@bounty/web-components'
import { FC, useState, Fragment, useRef, ReactNode, useEffect } from 'react'
import { prettyCurrency } from '@bounty/utils'
import { DEFAULT_BUDGETED_VALUE } from '../../const'
import { useMutationBackend, useQueryBackend } from '../../apollo/backend/hooks'
import {
  BudgetTileDocument,
  UpdateSubscriptionDocument,
} from '../../generated/backendGraphql'

const MIN_VALUE = 1000
const MAX_VALUE = 100000
const DEFAULT_VALUE = 10000
const STEP_VALUE = 2500

const buildSteps = () => {
  const steps: [val: number, percentOfTotal: number][] = []
  let val = 500

  while (val <= MAX_VALUE) {
    steps.push([val, Math.floor((val / MAX_VALUE) * 100)])
    val += 20000
  }
  steps.push([100000, 100])

  return steps
}

export type MonthlyBudgetProps = Omit<ContentBoxProps, 'onSubmit'> & {
  editMode?: boolean
  initialValue?: number
  onSubmit?: (value: number) => void
  submitButtonContent?: ReactNode
  footerComponent?: ReactNode
}

export const MonthlyBudget: FC<MonthlyBudgetProps> = ({
  editMode = false,
  initialValue = DEFAULT_BUDGETED_VALUE,
  submitButtonContent = 'Set New Budget',
  onSubmit,
  footerComponent = null,
  ...rest
}) => {
  const { data, loading } = useQueryBackend(BudgetTileDocument)
  const [budget, setBudget] = useState(initialValue)
  const [updateSubscriptionMutation, { loading: updateSubLoading }] =
    useMutationBackend(UpdateSubscriptionDocument)

  useEffect(() => {
    if (data?.currentSubscription?.currentPlanDetails?.capped_amount) {
      setBudget(
        parseInt(data.currentSubscription.currentPlanDetails.capped_amount, 10),
      )
    }
  }, [data])

  const tooltipRef = useRef<any>(null)

  return (
    <ContentBox p={10} mb={6} {...rest} data-testid="monthly-budget-container">
      {loading || !data ? (
        <Skeleton height="40px" width="100%" mb="18" />
      ) : (
        <Flex flexDirection={'column'}>
          <Flex
            alignItems="center"
            width={'100%'}
            justifyContent="space-between"
            pb={12}
          >
            <Heading mb="4" size="md" marginTop="2">
              Monthly budget
            </Heading>
            {editMode === false && (
              <Flex justifyContent="flex-end">
                <Button
                  data-testid="budget-tile-submit"
                  variant="outline"
                  colorScheme="gray"
                  onClick={() => {
                    onSubmit?.(budget)
                  }}
                >
                  {submitButtonContent}
                </Button>
              </Flex>
            )}

            {editMode === true && (
              <Flex justifyContent="flex-end">
                <Button
                  data-testid="budget-tile-submit"
                  variant="outline"
                  colorScheme="gray"
                  isLoading={updateSubLoading}
                  onClick={async () => {
                    const { data } = await updateSubscriptionMutation({
                      variables: {
                        cappedAmount: `${budget}`,
                      },
                    })
                    if (data?.updateSubscription?.confirmation_url) {
                      window.location.assign(
                        data.updateSubscription.confirmation_url,
                      )
                    }
                  }}
                >
                  {submitButtonContent}
                </Button>
              </Flex>
            )}
          </Flex>

          <Flex width={'75%'} flexDirection="column">
            <Text textAlign={'center'} color="gray.300" fontSize={'sm'}>
              Scroll budget in order preview changes
            </Text>
            <Box
              height="80px"
              display="flex"
              alignItems="flex-end"
              paddingBottom="10px"
              mb="6"
            >
              <Slider
                defaultValue={DEFAULT_VALUE}
                min={MIN_VALUE}
                width={['80%', '100%']}
                mx={'auto'}
                max={MAX_VALUE}
                step={STEP_VALUE}
                onChange={setBudget}
                value={budget}
                ref={tooltipRef}
              >
                {buildSteps().map(([value, percentOfTotal]) => {
                  const tikWidth = 2
                  const labelWidth = 60
                  return (
                    <Fragment key={value}>
                      <Box
                        position="absolute"
                        height="15px"
                        width={`${tikWidth}px`}
                        bg="gray.400"
                        left={`calc(${percentOfTotal}% - ${tikWidth / 2}px)`}
                        top="50%"
                        transform="translateY(-50%)"
                      ></Box>
                      {[0, 100].includes(percentOfTotal) && (
                        <Text
                          as="span"
                          width={`${labelWidth}px`}
                          fontSize="sm"
                          position="absolute"
                          left={`calc(${percentOfTotal}% - ${
                            labelWidth / 2
                          }px)`}
                          bottom={'20px'}
                          textAlign="center"
                          color="gray.400"
                          fontWeight={'semibold'}
                        >
                          {/* percent of total is the same as value, here its just a shortcut */}
                          ${percentOfTotal}k
                        </Text>
                      )}
                    </Fragment>
                  )
                })}

                <SliderTrack bg="black" height="2px"></SliderTrack>

                <SliderThumb
                  boxSize={6}
                  border="4px solid"
                  borderColor="darkBlack.500"
                  background="white"
                  position="relative"
                >
                  <Text
                    position="absolute"
                    paddingY="2"
                    fontSize="md"
                    lineHeight="1"
                    paddingX="3"
                    color="white"
                    bg="darkBlack.500"
                    borderRadius="lg"
                    top="-40px"
                  >
                    {prettyCurrency(budget)}
                  </Text>
                </SliderThumb>
              </Slider>
            </Box>
            <Flex width={'100%'} justifyContent="space-between" pb={6}>
              <Box mr={4} flex="1">
                <ContentBox
                  backgroundColor="gray.50"
                  flex="1"
                  px={10}
                  py={6}
                  mb={4}
                >
                  <Text textAlign="center">Soft Limit</Text>
                  <Text fontSize="lg" fontWeight={'bold'} textAlign="center">
                    {prettyCurrency(budget * 0.75)}
                  </Text>
                </ContentBox>
                <Text fontSize="0.7rem" textAlign={'center'} color="gray.500">
                  This is where we start to disable new content
                </Text>
              </Box>

              <Box flex="1">
                <ContentBox
                  backgroundColor="gray.50"
                  px={10}
                  py={6}
                  flex="1"
                  mb={4}
                >
                  <Text textAlign="center">Hard Limit</Text>
                  <Text fontSize="lg" fontWeight={'bold'} textAlign="center">
                    {prettyCurrency(budget)}
                  </Text>
                </ContentBox>
                <Text fontSize="0.7rem" textAlign={'center'} color="gray.500">
                  This is the maximum you can be billed
                </Text>
              </Box>
            </Flex>

            <Text mb="6">
              Due to the flexible nature of organic reach we begin to pause
              creators' ability to post Bounties when you start to approach your
              monthly budget. This begins when you've reached your soft limit.
              You will never be billed more than your hard limit in a given
              cycle.
            </Text>
            <Text mb="6">
              You'll receive notifications when you reach your soft limit, and
              can always top off your monthly budget if you'd like creators to
              keep posting.
            </Text>
            {footerComponent}
          </Flex>
        </Flex>
      )}
    </ContentBox>
  )
}
