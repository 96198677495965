import { defaultFontSize } from '@bounty/theme'
import { ChakraProvider } from '@chakra-ui/react'
import { PropsOf } from '@emotion/react'
import { PortableTextComponentsProvider } from '@portabletext/react'
import { createContext, FC, useContext, useEffect } from 'react'
import { buildPortableTextComponents } from './buildPortableTextComponents'
import { mergedTheme } from './Chakra'
import { ErrorBoundary } from './ErrorBoundary'

type BountyContextState = {
  appName: string
  supportEmail: string
  topNavHeight: string
  mobileNavHeight: string
}
const BountyContext = createContext<BountyContextState | undefined>(undefined)

export type BountyProviderProps = PropsOf<typeof ChakraProvider> & {
  appName: string
  supportEmail: string
  topNavHeight?: string
  mobileNavHeight?: string
}

const portableTextComponents = buildPortableTextComponents()

export const BountyProvider: FC<BountyProviderProps> = ({
  children,
  appName,
  supportEmail,
  topNavHeight = '60px',
  mobileNavHeight = '50px',
  ...rest
}) => {
  // Sets default font size of document since all of chakra uses REMs under the hood
  useEffect(() => {
    document.documentElement.style.fontSize = defaultFontSize
  }, [])

  return (
    <BountyContext.Provider
      value={{ appName, supportEmail, topNavHeight, mobileNavHeight }}
    >
      <ChakraProvider theme={mergedTheme} {...rest}>
        <ErrorBoundary supportEmail={supportEmail} appName={appName}>
          <PortableTextComponentsProvider components={portableTextComponents}>
            {children}
          </PortableTextComponentsProvider>
        </ErrorBoundary>
      </ChakraProvider>
    </BountyContext.Provider>
  )
}

export const useBounty = () => {
  const context = useContext(BountyContext)

  if (!context) {
    throw new Error('Cannot useBounty without using within a BountyProvider.')
  }

  return context
}
