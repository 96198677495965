import { FormHTMLAttributes, forwardRef, ReactNode } from 'react'
import { Box, BoxProps } from './Chakra'

export type FormProps = React.DetailedHTMLProps<
  FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
> & { children: ReactNode } & Omit<BoxProps, 'onSubmit'>

export const Form = forwardRef<HTMLFormElement, FormProps>(
  ({ children, ...rest }, ref) => {
    return (
      // @ts-expect-error - Casting makes the types wrong
      <Box as={'form'} ref={ref} {...rest}>
        {children}
      </Box>
    )
  },
)

Form.displayName = 'Form'
