/* eslint-disable import/export */
import {
  extendTheme,
  ChakraTheme,
  useTheme as useThemeCore,
  Textarea as TextareaCore,
  forwardRef,
  TextareaProps as ChakraTextareaProps,
  Box,
  Text,
  Link as ChakraLink,
  FormErrorMessage as FormErrorMessageCore,
  FormErrorMessageProps,
  useToast as useToastCore,
  UseToastOptions,
} from '@chakra-ui/react'
import { colors, fonts } from '@bounty/theme'
import { useState, ComponentProps } from 'react'
import { Link as RouterLink } from 'react-router-dom'

export * from '@chakra-ui/react'

interface BountyTheme extends ChakraTheme {
  colors: typeof colors
  fonts: typeof fonts
}

// For inspiration:
// https://github.com/chakra-ui/chakra-ui/tree/%40chakra-ui/radio%401.4.7/packages/theme/src/components
const themeOverrides: Partial<ChakraTheme> = {
  colors,
  fonts,
  // Leaving here in case we want to change global fonts
  styles: {
    global: {
      'html, body': {
        letterSpacing: 'tighter',
      },
    },
  },
  letterSpacings: {
    tighter: '-0.05rem',
    tight: '-0.03rem',
    normal: '0',
    wide: '0.03rem',
    wider: '0.05rem',
    widest: '0.07rem',
  },
  components: {
    Alert: {
      variants: {
        subtle: (props) => {
          const { colorScheme: c } = props
          return {
            container: {
              bg: `${c}.50`,
            },
          }
        },
        'left-accent': (props) => {
          const { colorScheme: c } = props
          return {
            container: {
              bg: `${c}.50`,
            },
          }
        },
        'right-accent': (props) => {
          const { colorScheme: c } = props
          return {
            container: {
              bg: `${c}.50`,
            },
          }
        },
        solid: (props) => {
          const { colorScheme: c } = props
          return {
            container: {
              bg: `${c}.50`,
            },
          }
        },
      },
    },
    Switch: {
      defaultProps: {
        colorScheme: 'darkBlack',
      },
    },
    Link: {
      defaultProps: {
        colorScheme: 'gray',
      },
      baseStyle: (props) => {
        const { colorScheme: c } = props
        return {
          color: `${c}.800`,
        }
      },
    },
    Checkbox: {
      defaultProps: {
        colorScheme: 'darkBlack',
      },
      baseStyle: () => ({
        control: {
          backgroundColor: 'white',
        },
      }),
    },
    Tabs: {
      defaultProps: {
        colorScheme: 'darkBlack',
      },
      baseStyle: () => {
        return {
          tab: {
            fontWeight: 'semibold',
          },
        }
      },
    },
    Button: {
      baseStyle: () => {
        return {
          _hover: {
            textDecoration: 'none',
          },
        }
      },
    },
    Menu: {
      baseStyle: () => {
        return {
          list: {
            maxHeight: '250px',
            overflowY: 'auto',
          },
        }
      },
    },
    Progress: {
      variants: {
        gradient: (_props) => ({
          filledTrack: {
            bgGradient: 'linear(to right, blue.700,purple.500)',
          },
        }),
      },
    },
    Drawer: {
      baseStyle: () => {
        return {
          header: {
            fontSize: '3xl',
            pt: 8,
            px: 10,
          },
          closeButton: {
            top: 8,
            insetEnd: 6,
          },
          body: {
            px: 10,
          },
          footer: {
            px: 10,
          },
        }
      },
    },
  },
}

export const mergedTheme = extendTheme(themeOverrides)

export const useTheme = () => useThemeCore<BountyTheme>()

export type TextareaProps = ChakraTextareaProps & { maxCharacterLimit?: number }
export const Textarea = forwardRef<TextareaProps, any>(
  (
    {
      onChange,
      maxCharacterLimit,
      isInvalid = false,
      marginBottom,
      marginLeft,
      marginRight,
      marginTop,
      mt,
      my,
      mr,
      mx,
      ml,
      mb,
      ...rest
    },
    ref,
  ) => {
    const [value, setValue] = useState('')

    return (
      <Box
        marginBottom={marginBottom}
        marginLeft={marginLeft}
        marginRight={marginRight}
        marginTop={marginTop}
        mt={mt}
        my={my}
        mr={mr}
        mb={mb}
        mx={mx}
        ml={ml}
      >
        <Box position="relative">
          <TextareaCore
            ref={(innerRef) => {
              if (typeof ref === 'function') {
                ref(innerRef)
              } else if (ref) {
                ref.current = innerRef
              }

              setValue(innerRef?.value ?? '')
            }}
            onChange={(e) => {
              setValue(e.target.value)
              onChange?.(e)
            }}
            isInvalid={
              isInvalid ||
              (maxCharacterLimit != null && value.length > maxCharacterLimit)
            }
            {...rest}
          />
          {maxCharacterLimit != null && maxCharacterLimit > 0 && (
            <Text
              position="absolute"
              bottom={'4px'}
              right="6px"
              fontSize="xs"
              color="gray.500"
            >
              {value.length}/{maxCharacterLimit}
            </Text>
          )}
        </Box>
      </Box>
    )
  },
)

export type LinkPropsExternal = { isExternal: true } & ComponentProps<
  typeof ChakraLink
>
export type LinkPropsInternal = { isExternal: false } & ComponentProps<
  typeof ChakraLink
> &
  ComponentProps<typeof RouterLink>

export type LinkProps = LinkPropsExternal | LinkPropsInternal

export const Link = forwardRef<LinkProps, any>((props, ref) => {
  return props.isExternal ? (
    <ChakraLink data-element-name="link" ref={ref} {...props} />
  ) : (
    <ChakraLink data-element-name="link" ref={ref} as={RouterLink} {...props} />
  )
})

export const FormErrorMessage = forwardRef<FormErrorMessageProps, any>(
  ({ children, ...rest }, ref) => {
    return (
      <FormErrorMessageCore
        data-element-name="form-error-message"
        ref={ref}
        {...rest}
      >
        {children}
      </FormErrorMessageCore>
    )
  },
)

export const useToast = (options?: UseToastOptions) =>
  useToastCore({ position: 'top', ...options })
