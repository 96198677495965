import { GetStarted } from './pages/GetStarted/GetStarted'
import { TikTok } from './pages/TikTok/TikTok'
import { Budget } from './pages/Budget/Budget'
import { Billing } from './pages/Billing/Billing'
import { ProductsInfo } from './pages/ProductsInfo/ProductsInfo'

export const WELCOME_ROUTES = [
  {
    component: GetStarted,
    isStep: false,
    path: 'get-started',
  },
  {
    component: TikTok,
    isStep: true,
    path: 'tik-tok',
  },
  {
    component: ProductsInfo,
    isStep: true,
    path: 'products',
  },
  {
    component: Budget,
    isStep: true,
    path: 'budget',
  },
  {
    component: Billing,
    isStep: true,
    path: 'billing',
  },
]

export const WELCOME_ROUTE_STEPS = WELCOME_ROUTES.filter(
  (route) => route.isStep,
)
