import { GetBlogResponse, GetBlogsResponse } from '@bounty/common'
import {
  BlogList,
  PageHeader,
  PageSkeleton,
  PortableTextPage,
} from '@bounty/web-components'
import { FC, useEffect, useState } from 'react'
import { Page } from '../../components/Page'
import { getSanity } from '../../lib/sanity'
import { Link, Route, Routes, useNavigate, useParams } from 'react-router-dom'

export type AnnouncementsProps = unknown

export const AnnouncementsOverview: FC<AnnouncementsProps> = () => {
  const [announcementsData, setAnnouncementsData] = useState<GetBlogsResponse>()

  useEffect(() => {
    const setAnnouncements = async () => {
      const announcements = await getSanity().getBlogs()
      setAnnouncementsData(announcements)
    }

    setAnnouncements()
  }, [])

  if (!announcementsData) return <PageSkeleton />

  return (
    <Page data-testid="announcements-page" name="Announcements">
      <PageHeader>Announcements</PageHeader>
      <BlogList link={Link} blogs={announcementsData.blogs} />
    </Page>
  )
}

export const AnnouncementsDetail = () => {
  const [announcementData, setBlogData] = useState<GetBlogResponse>()
  const { announcementSlug } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (!announcementSlug) {
      navigate('../')
      return
    }
    const setBlog = async () => {
      const announcement = await getSanity().getBlog(announcementSlug)
      setBlogData(announcement)
    }

    setBlog()
  }, [announcementSlug, navigate])

  if (!announcementData) return <PageSkeleton />

  return (
    <Page data-testid="announcement-detail-page" name="Announcements">
      <PortableTextPage item={announcementData} />
    </Page>
  )
}

export const Announcements: FC = () => {
  return (
    <Routes>
      <Route index element={<AnnouncementsOverview />} />
      <Route path=":announcementSlug" element={<AnnouncementsDetail />} />
    </Routes>
  )
}
