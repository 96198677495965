export const colors = {
  transparent: 'transparent',
  current: 'currentColor',
  black: '#000000',
  white: '#FFFFFF',
  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
  },
  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.01)',
    100: 'rgba(0, 0, 0, 0.04)',
    200: 'rgba(0, 0, 0, 0.10)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.28)',
    500: 'rgba(0, 0, 0, 0.40)',
    600: 'rgba(0, 0, 0, 0.60)',
    700: 'rgba(0, 0, 0, 0.75)',
    800: 'rgba(0, 0, 0, 0.80)',
    900: 'rgba(0, 0, 0, 0.90)',
  },
  darkBlack: {
    50: '#CCCCCC',
    100: '#AAAAAA',
    200: '#888888',
    300: '#666667',
    400: '#444444',
    500: '#2B2B2B',
    600: '#222222',
    700: '#000000',
    800: '#000000',
    900: '#000000',
  },
  gray: {
    50: '#FBFBFB',
    100: '#F2F2F2',
    200: '#ECECEC',
    300: '#DEDEDE',
    400: '#D1D1D1',
    500: '#AEAEAE',
    600: '#848484',
    700: '#535353',
    800: '#242424',
    900: '#0E0E0E',
  },
  coolGray: {
    50: '#EFF4F5',
    100: '#D2DADD',
    200: '#B6BFC3',
    300: '#A0AAAF',
    400: '#8B969C',
    500: '#788389',
    600: '#687379',
    700: '#545E64',
    800: '#3F474B',
    900: '#283137',
  },
  red: {
    50: '#FFB6B6',
    100: '#FF8A8B',
    200: '#FD7172',
    300: '#F45758',
    400: '#F14143',
    500: '#EA2E30',
    600: '#D41F21',
    700: '#B41416',
    800: '#940A0C',
    900: '#640809',
  },
  orange: {
    50: '#F9DDD1',
    100: '#FDBEA3',
    200: '#F5956A',
    300: '#FA7D45',
    400: '#E9682E',
    500: '#E1571F',
    600: '#C84A17',
    700: '#943612',
    800: '#742809',
    900: '#581C03',
  },
  deepOrange: {
    50: '#F8EAE7',
    100: '#F7CEBF',
    200: '#F3AF96',
    300: '#F0906D',
    400: '#EE7950',
    500: '#EC6337',
    600: '#E25D33',
    700: '#D5552E',
    800: '#C84E29',
    900: '#B04121',
  },
  yellow: {
    50: '#FFF7CF',
    100: '#FFF1AA',
    200: '#FAE475',
    300: '#F8DC53',
    400: '#F1D132',
    500: '#E2C42F',
    600: '#C7AA1E',
    700: '#B29813',
    800: '#947E0D',
    900: '#645506',
  },
  cactus: {
    50: '#F5FFE1',
    100: '#E5FDB5',
    200: '#D8F799',
    300: '#C3E281',
    400: '#AECC6F',
    500: '#96B458',
    600: '#7B9641',
    700: '#65773E',
    800: '#4E5A34',
    900: '#323923',
  },
  green: {
    50: '#EAFFE0',
    100: '#D4FBC1',
    200: '#B9F29C',
    300: '#A6EC84',
    400: '#8FDA6A',
    500: '#73BD4E',
    600: '#5FA93A',
    700: '#50942E',
    800: '#3F7723',
    900: '#32611B',
  },
  teal: {
    50: '#DEFFFB',
    100: '#C4FDF6',
    200: '#9CF0E6',
    300: '#6BEDDD',
    400: '#3FE4D1',
    500: '#27D8C2',
    600: '#1BC7B2',
    700: '#0EB29F',
    800: '#099C8B',
    900: '#037B6D',
  },
  blue: {
    50: '#D0EBFF',
    100: '#ADDAFB',
    200: '#8ECAF7',
    300: '#69B8F2',
    400: '#3FA4EE',
    500: '#2A99EB',
    600: '#1187DD',
    700: '#0878CA',
    800: '#0869B0',
    900: '#02538D',
  },
  cyan: {
    50: '#DAFBFF',
    100: '#B9F7FF',
    200: '#91EFFB',
    300: '#77ECFC',
    400: '#4FE1F4',
    500: '#2ED4EB',
    600: '#1AC9E1',
    700: '#0EB4CB',
    800: '#0AA1B6',
    900: '#048193',
  },
  deepPurple: {
    50: '#DED3FF',
    100: '#CAB7FF',
    200: '#A98AFF',
    300: '#936DFF',
    400: '#825AF3',
    500: '#734AE8',
    600: '#663CDE',
    700: '#5127C8',
    800: '#421EA8',
    900: '#32138B',
  },
  purple: {
    50: '#DED3FF',
    100: '#CAB7FF',
    200: '#A98AFF',
    300: '#936DFF',
    400: '#825AF3',
    500: '#734AE8',
    600: '#663CDE',
    700: '#5127C8',
    800: '#421EA8',
    900: '#32138B',
  },
  pink: {
    50: '#FFDAE1',
    100: '#FFC7D0',
    200: '#FF9BAB',
    300: '#FA778E',
    400: '#F45975',
    500: '#EF4261',
    600: '#E22648',
    700: '#D11637',
    800: '#BB0B2A',
    900: '#9E041F',
  },
  // Bounty specific start!
  brown: {
    50: '#FFF1E1',
    100: '#F3DFC7',
    200: '#EBCDAA',
    300: '#DEBB92',
    400: '#D0AA7E',
    500: '#BE9462',
    600: '#AD7F47',
    700: '#A06C2E',
    800: '#8B591E',
    900: '#734611',
  },
  warmGray: {
    50: '#FFFEFD',
    100: '#F8F5F3',
    200: '#E8E3E1',
    300: '#DCD6D4',
    400: '#CCC7C4',
    500: '#BEB8B5',
    600: '#8F8986',
    700: '#5F5C5A',
    800: '#413F3E',
    900: '#1F1E1E',
  },
  // Bounty specific end!
  linkedin: {
    50: '#E8F4F9',
    100: '#CFEDFB',
    200: '#9BDAF3',
    300: '#68C7EC',
    400: '#34B3E4',
    500: '#00A0DC',
    600: '#008CC9',
    700: '#0077B5',
    800: '#005E93',
    900: '#004471',
  },

  facebook: {
    50: '#E8F4F9',
    100: '#D9DEE9',
    200: '#B7C2DA',
    300: '#6482C0',
    400: '#4267B2',
    500: '#385898',
    600: '#314E89',
    700: '#29487D',
    800: '#223B67',
    900: '#1E355B',
  },

  messenger: {
    50: '#D0E6FF',
    100: '#B9DAFF',
    200: '#A2CDFF',
    300: '#7AB8FF',
    400: '#2E90FF',
    500: '#0078FF',
    600: '#0063D1',
    700: '#0052AC',
    800: '#003C7E',
    900: '#002C5C',
  },

  whatsapp: {
    50: '#dffeec',
    100: '#b9f5d0',
    200: '#90edb3',
    300: '#65e495',
    400: '#3cdd78',
    500: '#22c35e',
    600: '#179848',
    700: '#0c6c33',
    800: '#01421c',
    900: '#001803',
  },

  twitter: {
    50: '#E5F4FD',
    100: '#C8E9FB',
    200: '#A8DCFA',
    300: '#83CDF7',
    400: '#57BBF5',
    500: '#1DA1F2',
    600: '#1A94DA',
    700: '#1681BF',
    800: '#136B9E',
    900: '#0D4D71',
  },

  telegram: {
    50: '#E3F2F9',
    100: '#C5E4F3',
    200: '#A2D4EC',
    300: '#7AC1E4',
    400: '#47A9DA',
    500: '#0088CC',
    600: '#007AB8',
    700: '#006BA1',
    800: '#005885',
    900: '#003F5E',
  },
}
