import {
  ContentBox,
  Heading,
  PageHeader,
  useBounty,
  Text,
  Link,
  Box,
  Alert,
} from '@bounty/web-components'
import { FC } from 'react'
import { Page } from '../../components/Page'
import { TikTokForm } from './TikTokForm'
import { useAuthState } from '../../hooks/useAuth'
import { useAnalytics } from '@bounty/hooks'
import { SnippetExperimentsToggler } from '../../components/SnippetsExperimentsToggler'
import { BrandedHashtagForm } from './BrandedHashtagForm'
import { useFeatureForStore } from '../../hooks/useFeatureForStore'
import { BountyTogglerSwitch } from './BountyToggler'

export type SettingsProps = unknown

export const Settings: FC<SettingsProps> = () => {
  const { supportEmail, appName } = useBounty()
  const { shopifyStoreUrl } = useAuthState()
  const { isAvailable } = useFeatureForStore('CUSTOM_BRANDED_HASHTAG')
  const { track } = useAnalytics()

  return (
    <Page alignItems="center" data-testid="settings-page" name="Settings">
      <PageHeader>Settings</PageHeader>
      <ContentBox mb="6">
        <Heading as={'h2'} size="md" mb="2">
          Update TikTok
        </Heading>
        <TikTokForm
          variant="regular"
          onSubmitSuccess={({ tikTokUsername }) => {
            track('TikTok Username Updated', { tikTokUsername })
          }}
        />
      </ContentBox>
      {isAvailable && (
        <ContentBox mb="6">
          <Heading as={'h2'} size="md" mb="2">
            Update Branded Hashtag
          </Heading>
          <BrandedHashtagForm
            variant="regular"
            onSubmitSuccess={({ brandedHashtag }) => {
              track('Branded Hashtag Updated', { brandedHashtag })
            }}
          />
        </ContentBox>
      )}

      <SnippetExperimentsToggler />

      <Alert
        colorScheme="yellow"
        flexDirection="column"
        alignItems="flex-start"
        variant="left-accent"
      >
        <Heading as={'h2'} size="md" mb="2">
          Danger Zone
        </Heading>
        <Box py="4">
          <BountyTogglerSwitch />
        </Box>
        <Text>
          If you would like to delete your account please{' '}
          <Link
            fontWeight="bold"
            color="gray.800"
            href={`mailto:${supportEmail}?subject=${appName} | ${shopifyStoreUrl} Billing Update`}
            isExternal
          >
            email us
          </Link>{' '}
          so we can start the deletion process.
        </Text>
      </Alert>
    </Page>
  )
}
