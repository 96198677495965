import { getBackendClient } from './backend/client'
import { getShopifyAdminClient } from './shopify-admin/client'
import { mergedTheme, createStandaloneToast } from '@bounty/web-components'

export const apolloErrorToast = createStandaloneToast({
  theme: mergedTheme,
  defaultOptions: { position: 'top', isClosable: true },
})

export const logout = () => {
  return Promise.all([
    getBackendClient().clearStore(),
    getShopifyAdminClient().clearStore(),
  ]).then(() => {
    localStorage.removeItem('authToken')
    window.location.assign('/login')
  })
}
