import { useFlags } from 'launchdarkly-react-client-sdk'

export type FeatureFlags = {
  showLogoutButton: boolean
  showBrandNotificationPreferences: boolean
  somethingElse: string
}

export type FeatureFlag = keyof FeatureFlags

export const useFlag = <T extends FeatureFlag>(key: T) => {
  const flags = useFlags()

  return flags[key] as FeatureFlags[T]
}
