import { createContext, FC, useContext, useState } from 'react'
import { LDProvider } from 'launchdarkly-react-client-sdk'
// eslint-disable-next-line import/no-extraneous-dependencies
import { LDClient } from 'launchdarkly-js-client-sdk'
import { PropsOf } from '@emotion/react'
import { createLogger } from '@bounty/utils'

const logger = createLogger()

export type LaunchDarklyState = {
  client: LDClient | undefined
}
const LaunchDarklyStateContext = createContext<LaunchDarklyState | undefined>(
  undefined,
)

export type LaunchDarklyProviderProps = PropsOf<typeof LDProvider> & {
  isEnabled: boolean
}

let hasLaunchDarklyTurnedOffWarningBeenTriggered = false
export const LaunchDarklyProvider: FC<LaunchDarklyProviderProps> = ({
  children,
  isEnabled,
  ...rest
}) => {
  const [launchDarkly, setLaunchDarkly] = useState<LDClient | undefined>(
    undefined,
  )
  if (isEnabled === false) {
    if (hasLaunchDarklyTurnedOffWarningBeenTriggered === false) {
      logger.warn('Launch Darkly is not enabled!')
      hasLaunchDarklyTurnedOffWarningBeenTriggered = true
    }
    return <>{children}</>
  }

  return (
    <LDProvider
      ref={(ref) => {
        // Takes a little bit for it to get put on state
        setTimeout(() => {
          setLaunchDarkly((x) => x ?? ref?.state.ldClient)
        }, 100)
      }}
      {...rest}
    >
      <LaunchDarklyStateContext.Provider value={{ client: launchDarkly }}>
        {children}
      </LaunchDarklyStateContext.Provider>
    </LDProvider>
  )
}

export const useLaunchDarkly = () => {
  const context = useContext(LaunchDarklyStateContext)

  if (!context) {
    throw new Error(
      'useLaunchDarkly must be used within a LaunchDarklyProvider.',
    )
  }

  return context
}
