import { useState, FC } from 'react'
import { Page } from '../../components/Page'
import {
  PageHeader,
  Box,
  VStack,
  Text,
  ErrorText,
  PageSkeleton,
} from '@bounty/web-components'
import {
  GetAllNotificationEmailsDocument,
  AddNotificationEmailDocument,
  RemoveNotificationEmailDocument,
} from '../../generated/backendGraphql'
import { useQueryBackend, useMutationBackend } from '../../apollo/backend/hooks'
import { z } from 'zod'
import {
  ActionMeta,
  CreatableSelect,
  MultiValue,
  OptionBase,
} from 'chakra-react-select'
import { uniqWith } from '@bounty/utils'

const categories = [
  {
    key: 'ACCOUNT_UPDATES',
    name: 'Account Updates',
    description:
      'Updates pertaining to changes to your account or billing problems.',
  },
  {
    key: 'WEEKLY_UPDATES',
    name: 'Weekly Updates',
    description:
      'Weekly snapshots of your top Bounties and weekly performance metrics.',
  },
  {
    key: 'SPARK_CODE_REQUESTS',
    name: 'Spark Code Requests',
    description: 'Emails containing spark code information from creators.',
  },
  {
    key: 'PRODUCT_UPDATES',
    name: 'Product Updates',
    description: 'Product release updates and new feature emails.',
  },
] as const

export const NotificationPreferences = () => {
  const { data, loading } = useQueryBackend(GetAllNotificationEmailsDocument)

  const [addEmail] = useMutationBackend(AddNotificationEmailDocument, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          getAllNotificationEmails(previous, { toReference }) {
            if (!data) return
            return [...previous, toReference(data.addNotificationEmail)]
          },
        },
      })
    },
  })

  const [removeEmail] = useMutationBackend(RemoveNotificationEmailDocument, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          getAllNotificationEmails(previous, { readField }) {
            if (!data) return
            const { email, list } = data.removeNotificationEmail
            return previous.filter(
              (ref: any) =>
                readField('email', ref) !== email ||
                readField('list', ref) !== list,
            )
          },
        },
      })
    },
  })

  if (loading || !data?.getAllNotificationEmails) return <PageSkeleton />

  const allEmails = data.getAllNotificationEmails
  return (
    <Page
      data-testid="notification-preferences-page"
      name="Notifications"
      addBottomSpacing
    >
      <PageHeader>Notification Preferences</PageHeader>

      <VStack spacing={10}>
        {categories.map(({ key, name, description }) => {
          const add = (email: string) =>
            addEmail({
              variables: { list: key, email },
              optimisticResponse: {
                __typename: 'Mutation',
                addNotificationEmail: {
                  __typename: 'BrandNotificationContact',
                  id: `${email}-${key}`,
                  email,
                  list: key,
                },
              },
            })
          const remove = (email: string) =>
            removeEmail({
              variables: { list: key, email },
              optimisticResponse: {
                __typename: 'Mutation',
                removeNotificationEmail: {
                  __typename: 'BrandNotificationContact',
                  id: `${email}-${key}`,
                  email,
                  list: key,
                },
              },
            })
          return (
            <NotificationCategory
              key={key}
              category={key}
              name={name}
              description={description}
              emailsForCategory={allEmails
                .filter((e) => e.list === key)
                .map((e) => e.email)}
              allEmails={allEmails.map((e) => e.email)}
              addEmail={add}
              removeEmail={remove}
            />
          )
        })}
      </VStack>
    </Page>
  )
}

type NotificationCategoryProps = {
  category: string
  name: string
  description: string
  allEmails: string[]
  emailsForCategory: string[]
  addEmail: (email: string) => void
  removeEmail: (email: string) => void
}
const NotificationCategory: FC<NotificationCategoryProps> = ({
  category,
  name,
  description,
  allEmails,
  emailsForCategory,
  addEmail,
  removeEmail,
}) => {
  const [error, setError] = useState<string | null>(null)

  interface EmailOption extends OptionBase {
    label: string
    value: string
  }
  const oneEmailForCategory =
    category === 'ACCOUNT_UPDATES' && emailsForCategory.length === 1
  const suggestions: EmailOption[] = uniqWith(allEmails, (a, b) => a === b).map(
    (s) => ({
      label: s,
      value: s,
      isFixed: oneEmailForCategory && s === emailsForCategory[0],
    }),
  )
  const isEmail = z.string().email()
  const maybeAddEmail = (email?: string) => {
    const result = isEmail.safeParse(email)
    if (!result.success) {
      setError('Please input a valid email address.')
      return
    }

    addEmail(result.data)
  }

  const maybeRemoveEmail = (email?: string) => {
    const categoryIsRequired =
      category === 'ACCOUNT_UPDATES' || category === 'SPARK_CODE_REQUESTS'
    if (!email) return
    if (emailsForCategory.length === 1 && categoryIsRequired) {
      setError('Must have at least one email for this category.')
      return
    }
    removeEmail(email)
  }

  const onChange = (
    _values: MultiValue<EmailOption>,
    meta: ActionMeta<EmailOption>,
  ) => {
    switch (meta.action) {
      case 'create-option':
      case 'select-option':
        maybeAddEmail(meta?.option?.value)
        return
      case 'remove-value':
      case 'pop-value':
        maybeRemoveEmail(meta.removedValue.value)
        return
      default:
        return
    }
  }

  return (
    <VStack alignItems="flex-start" width="100%">
      <Text fontSize="xl" fontWeight="bold">
        {name}
      </Text>
      <Text fontSize="lg">{description}</Text>

      <Box width="container.sm">
        <CreatableSelect<EmailOption, true>
          size="lg"
          data-testid={`${category}-input`}
          isMulti
          isClearable={false}
          options={suggestions}
          value={emailsForCategory.map((e) => ({ label: e, value: e }))}
          closeMenuOnSelect={true}
          colorScheme="purple"
          isInvalid={!!error}
          errorBorderColor="red.500"
          placeholder="Add an email"
          onChange={onChange}
          onKeyDown={() => setError(null)}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
        />
      </Box>
      {!!error && <ErrorText>{error}</ErrorText>}
    </VStack>
  )
}
