import { FC } from 'react'
import { useQueryBackend } from '../../apollo/backend/hooks'
import { CombinedChargesLedgerDocument } from '../../generated/backendGraphql'
import {
  Text,
  Box,
  Flex,
  Heading,
  Skeleton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Link,
  Image,
} from '@bounty/web-components'
import { format } from 'date-fns'
import { prettyCurrency, isNil, prettyViews } from '@bounty/utils'
import { CLOUDINARY_URL } from '../../config/env'
import { EyeIcon } from '@bounty/icons'

const extractUsernameLink = (link: string) => {
  const re = /\/(@\w+)\//i
  const m = link.match(re)
  if (m && m.length !== 0 && m.length >= 2) {
    return m[1]
  }
  return null
}

export const ContentCharges: FC = () => {
  const { data, loading } = useQueryBackend(CombinedChargesLedgerDocument)

  return (
    <Flex flexDir="column">
      <Heading mb="4" size="lg" marginTop="8">
        Content Charges
      </Heading>

      {loading || isNil(data) ? (
        <Box flex="1" px="4">
          <Skeleton height="16px" mb="4" />
          <Skeleton height="16px" />
        </Box>
      ) : (
        <Flex>
          {data.combinedChargesLedger.length === 0 ? (
            <Box py="4">There have been no payments made to creators yet.</Box>
          ) : (
            <Table overflowX="auto" variant="simple">
              <Thead>
                <Tr>
                  <Th>Date</Th>
                  <Th>Status</Th>
                  <Th>Category</Th>
                  <Th>Description</Th>
                  <Th>Service Fee</Th>
                  <Th>Total</Th>
                </Tr>
              </Thead>

              <Tbody>
                {data.combinedChargesLedger.map((charge) => {
                  return (
                    <Tr key={charge.id}>
                      <Td>
                        <Text fontWeight="bold" color="gray.600">
                          {format(new Date(charge.createdAt), 'LLL d')}
                        </Text>
                      </Td>
                      <Td>{charge.paymentStatus}</Td>
                      <Td>
                        {/**
                         * we dont have all the payment types yet, this needs to be updated when spark code types
                         * are done
                         */}
                        {charge.userBounty?.tikTokVideo?.id ? (
                          <Text>Views</Text>
                        ) : (
                          <Text>Misc</Text>
                        )}
                      </Td>
                      <Td>
                        {charge.userBounty?.tikTokVideo?.videoUrl ? (
                          <Box>
                            <Link
                              isExternal
                              href={charge.userBounty?.tikTokVideo?.videoUrl}
                              fontSize="sm"
                            >
                              <Flex alignItems={'center'}>
                                <Image
                                  src={`${CLOUDINARY_URL}/video_id_${charge.userBounty?.tikTokVideo?.videoId}.jpg`}
                                  width={'40px'}
                                  p={2}
                                  borderRadius={3}
                                ></Image>
                                <Box>
                                  <Text fontWeight={'bold'}>
                                    <EyeIcon />{' '}
                                    {prettyViews(
                                      charge.userBounty.tikTokVideo.viewCount,
                                    )}
                                  </Text>
                                  {extractUsernameLink(
                                    charge.userBounty?.tikTokVideo?.videoUrl,
                                  ) && (
                                    <Text color={'gray.600'}>
                                      {extractUsernameLink(
                                        charge.userBounty?.tikTokVideo
                                          ?.videoUrl,
                                      )}
                                    </Text>
                                  )}

                                  <Text color={'gray.600'}>
                                    {charge.description}
                                  </Text>
                                </Box>
                              </Flex>
                            </Link>
                          </Box>
                        ) : (
                          <Text fontSize="sm">{charge.description}</Text>
                        )}
                      </Td>
                      <Td>
                        {prettyCurrency(charge.bountyServiceFee ?? 0, {
                          precision: 2,
                        })}
                      </Td>
                      <Td>{prettyCurrency(charge.price, { precision: 2 })}</Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          )}
        </Flex>
      )}
    </Flex>
  )
}
