import { FC, ReactNode } from 'react'
import { Flex, FlexProps } from './Chakra'

export type TopNavProps = FlexProps & {
  leftComponent?: ReactNode
  centerComponent?: ReactNode
  rightComponent?: ReactNode
  topNavHeight: string
}

export const TopNav: FC<TopNavProps> = ({
  leftComponent = null,
  centerComponent = null,
  rightComponent = null,
  topNavHeight,
  ...rest
}) => {
  return (
    <Flex
      paddingX="8"
      alignItems="center"
      justifyContent="space-between"
      height={topNavHeight}
      {...rest}
    >
      <Flex justifyContent="flex-start" flex="1">
        {leftComponent}
      </Flex>
      <Flex justifyContent="center" flex="1">
        {centerComponent}
      </Flex>
      <Flex justifyContent="flex-end" flex="1">
        {rightComponent}
      </Flex>
    </Flex>
  )
}
