import { FC, SetStateAction, useEffect } from 'react'
import {
  Box,
  Form,
  Heading,
  Flex,
  Input,
  Button,
  Text,
} from '@bounty/web-components'
import { useLazyQueryBackend } from '../apollo/backend/hooks'
import { TestDiscountCodeDocument } from '../generated/backendGraphql'
import { DiscountDetails } from '../pages/Welcome/pages/Billing/Billing'

export type DiscountCodeProps = {
  discountDetails: DiscountDetails
  setDiscountDetails: React.Dispatch<SetStateAction<DiscountDetails>>
  discountCode: string
  setDiscountCode: React.Dispatch<SetStateAction<string>>
}

export const DiscountCodeForm: FC<DiscountCodeProps> = ({
  discountDetails,
  setDiscountDetails,
  discountCode,
  setDiscountCode,
}) => {
  const successMessage = 'Discount Code Applied'
  const errorMessage = 'Invalid Discount Code'

  const [query, { data, loading }] = useLazyQueryBackend(
    TestDiscountCodeDocument,
  )

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    query({ variables: { discountCode } })
  }
  useEffect(() => {
    if (data?.checkDiscountCode.valid) {
      setDiscountDetails({
        status: data.checkDiscountCode.valid,
        price: data.checkDiscountCode.price,
      })
    } else {
      setDiscountDetails({
        status: false,
        price: data?.checkDiscountCode.price,
      })
    }
  }, [data, setDiscountDetails])

  return (
    <Box mb="4" pt="10" maxWidth="410px" data-testid="discount-code-container">
      <Form
        data-testid="discount-code-form"
        onSubmit={(e) => {
          handleSubmit(e)
        }}
      >
        <Heading as={'h2'} size="md" mb="4">
          Enter Discount Code
        </Heading>
        <Flex alignItems="flex-start">
          <Input
            name="discount-code"
            type={'string'}
            size="md"
            mr="2"
            onChange={(e) => {
              setDiscountCode(e.target.value)
            }}
            value={discountCode}
          />

          <Button
            isLoading={loading}
            type="submit"
            variant="outline"
            p="4"
            data-testid="submit-discount-code-button"
          >
            Apply
          </Button>
        </Flex>
        <Box mb="4" pt="2" maxWidth="410px">
          {data && (
            <Text
              color="red"
              fontWeight="semi-bold"
              data-testid="discount-status"
            >
              {discountDetails.status ? successMessage : errorMessage}
            </Text>
          )}
        </Box>
      </Form>
    </Box>
  )
}
