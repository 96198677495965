import { Global } from '@emotion/react'

export const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Marfa';
        src: url('/fonts/ABCMarfa-Thin.woff2') format('woff2'),
          url('/fonts/ABCMarfa-Thin.woff') format('woff');
        font-weight: 200;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'Marfa';
        src: url('/fonts/ABCMarfa-Regular.woff2') format('woff2'),
          url('/fonts/ABCMarfa-Regular.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'Marfa';
        src: url('/fonts/ABCMarfa-Semibold.woff2') format('woff2'),
          url('/fonts/ABCMarfa-Semibold.woff') format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'Marfa';
        src: url('/fonts/ABCMarfa-Black.woff2') format('woff2'),
          url('/fonts/ABCMarfa-Black.woff') format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
      }
      `}
  />
)
