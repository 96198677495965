import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerProps,
} from '@bounty/web-components'
import { Route, Routes } from 'react-router-dom'
import { ProductInstructionCreate } from './ProductInstructionCreate'
import { ProductInstructionCreateDefault } from './ProductInstructionCreateDefault'
import { ProductInstructionEdit } from './ProductInstructionEdit'
import { ProductInstructionEditDefault } from './ProductInstructionEditDefault'
import { ProductInstructionsList } from './ProductInstructionsList'
import { useNavigate } from 'react-router-dom'

export type ProductInstructionsDrawerProps = Omit<
  DrawerProps,
  'children' | 'isOpen' | 'onClose'
> & {
  rootPath: string
}

export const ProductInstructionsDrawer = ({
  rootPath,
  ...rest
}: ProductInstructionsDrawerProps) => {
  const navigate = useNavigate()

  return (
    <Drawer size="lg" isOpen onClose={() => navigate(rootPath)} {...rest}>
      <DrawerOverlay />
      <DrawerContent>
        <Routes>
          <Route path="/" element={<ProductInstructionsList />} />
          <Route
            path=":productInstructionId/edit"
            element={<ProductInstructionEdit />}
          />
          <Route path="new" element={<ProductInstructionCreate />} />
          <Route
            path="default/edit"
            element={<ProductInstructionEditDefault />}
          />
          <Route
            path="default/new"
            element={<ProductInstructionCreateDefault />}
          />
        </Routes>
      </DrawerContent>
    </Drawer>
  )
}
