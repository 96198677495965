import { useAnalytics } from '@bounty/hooks'
import { Box, Button, Heading, Image } from '@bounty/web-components'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Page } from '../../../../components/Page'
import GetStartedImage from '../../../../images/get-started.png'

export const GetStarted: FC = () => {
  const { track } = useAnalytics()
  return (
    <Page data-testid="get-started-page" name="Get Started">
      <Box marginX="auto" maxWidth="504px" paddingTop={['0', '10']}>
        <Image
          display="block"
          marginBottom="12"
          marginX="auto"
          maxWidth="485px"
          objectFit="contain"
          src={GetStartedImage}
          width="100%"
        />
        <Heading as="h1" marginBottom="8" size="xl">
          Automate influencer marketing and unlock the power of TikTok™ for your
          brand.
        </Heading>
        <Button
          as={Link}
          data-testid="get-started-next-button"
          colorScheme="blackAlpha"
          to="../tik-tok"
          variant="outline"
          onClick={() => {
            track('Welcome Get Started Completed')
          }}
        >
          Let's Get Started
        </Button>
      </Box>
    </Page>
  )
}
