import { FC } from 'react'
import { Box, FlexProps, Flex } from '@bounty/web-components'
import { useScript } from '@bounty/hooks'
import { SNIPPET_LANDING_PAGE_URL } from '../../../config/env'

export type LandingPagePreviewProps = FlexProps & {
  configProps?: { [x: string]: any }
}

export const LandingPagePreview: FC<LandingPagePreviewProps> = ({
  configProps,
  ...props
}) => {
  useScript({ src: SNIPPET_LANDING_PAGE_URL, id: 'snippet-landing-page' })

  return (
    <Flex
      position="relative"
      height="100%"
      px="10"
      py="4"
      flexDirection="column"
      minHeight="150px"
      {...props}
    >
      <Flex flex={1} justifyContent="center" alignItems="center">
        <Box>
          {/* 
          // @ts-ignore */}
          <bounty-landing-page></bounty-landing-page>
          <script type="text/props" data-island-props="landing-page">
            {JSON.stringify(
              configProps
                ? { ...configProps, isDemoMode: true }
                : { isDemoMode: true },
            )}
          </script>
        </Box>
      </Flex>
    </Flex>
  )
}
