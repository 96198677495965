import { z } from 'zod'

export const ShopifySnippetAnalyticsIdentifySchema = z
  .object({
    userId: z.string().optional(),
    traits: z.object({
      firstSeenSnippetLocation: z.enum([
        'PRODUCT',
        'THANKYOU',
        'LANDINGPAGE',
        'product',
        'thankYou',
        'landingPage',
      ]),
      firstSeenPage: z.string().optional(),
      firstSeenAppVersion: z.string().optional(),
      firstSeenBranchName: z.string().optional(),
      firstSeenShop: z.string().optional(),
      firstSeenProductId: z.string().optional(),
      firstSeenProductVariant: z.string().optional(),
      firstSeenProductVariantId: z.string().optional(),
      tikTokUsername: z.string().optional(),
      source: z
        .enum([
          'PRODUCT',
          'THANKYOU',
          'LANDINGPAGE',
          'product',
          'thankYou',
          'landingPage',
        ])
        .optional(),
    }),
    context: z.object({
      userAgent: z.string(),
    }),
  })
  .strict()

export type ShopifySnippetAnalyticsIdentifyType = z.infer<
  typeof ShopifySnippetAnalyticsIdentifySchema
>

export const ShopifySnippetAnalyticsAliasSchema = z
  .object({
    previousId: z.string(),
    userId: z.string(),
    tikTokUsername: z.string().optional(),
    timestamp: z.string().optional(),
  })
  .strict()

export type ShopifySnippetAnalyticsAliasType = z.infer<
  typeof ShopifySnippetAnalyticsAliasSchema
>

export const ShopifySnippetAnalyticsEventSchema = z
  .object({
    userId: z.string().optional(),
    event: z.enum([
      'Snippet Call To Action Clicked',
      'Snippet Call To Action Sign up Button Clicked',
      'Snippet Call To Action Closed',
      'Snippet Call To Action Phone Complete',
      'Snippet Call To Action TikTok Complete',
      'Snippet Call To Action Submitted',
      'Snippet Page Load',
    ]),
    properties: z.object({
      appVersion: z.string().optional(),
      branchName: z.string().optional(),
      productId: z.string().optional(),
      productVariant: z.string().optional(),
      productVariantId: z.string().optional(),
      // can be string or number
      productPrice: z.any().optional(),
      productVendor: z.string().optional(),
      productNumberOfVariants: z.number().optional(),
      shop: z.string().optional(),
      experimentName: z.string().optional(),
      experimentVariant: z.string().optional(),
      experimentsVersion: z.string().optional(),
      theme: z.string().optional(),
      themeId: z.string().optional(),
      currency: z.string().optional(),
      country: z.string().optional(),
      tikTokPixelId: z.string().optional(),
      facebookPixelId: z.string().optional(),
      tikTokUsername: z.string().optional(),
      orderId: z.string().optional(),
      discountCode: z.string().optional(),
      isUsingAppBlocks: z.boolean(),
      snippetLocation: z.enum([
        'PRODUCT',
        'THANKYOU',
        'LANDINGPAGE',
        'product',
        'thankYou',
        'landingPage',
      ]),
      hasCompletedSignUp: z.boolean(),
    }),
    context: z
      .object({
        userAgent: z.string().optional(),
        locale: z.string().optional(),
        page: z.object({
          path: z.string().optional(),
          referrer: z.string().optional(),
          search: z.string().optional(),
          title: z.string().optional(),
          url: z.string().optional(),
        }),
      })
      .strict(),
  })
  .strict()

export type ShopifySnippetAnalyticsEventType = z.infer<
  typeof ShopifySnippetAnalyticsEventSchema
>
