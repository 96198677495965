import { FC } from 'react'
import {
  Box,
  Flex,
  Skeleton,
  FastFormTextarea,
  FastFormInput,
  FastForm,
  FastFormProps,
  FastFormButton,
} from '@bounty/web-components'
import * as z from 'zod'
import { useMutationBackend } from '../../../apollo/backend/hooks'
import {
  CreateProductInstructionDocument,
  ProductInstructionsListDocument,
  UpdateProductInstructionDocument,
} from '../../../generated/backendGraphql'

export const productInstructionSchema = z
  .object({
    id: z.string().optional(),
    name: z.string().min(1).max(50),
    headline: z.string().min(1).max(280),
    features: z.array(z.string().max(280)).max(3).nullable(),
  })
  .strict()

export type ProductInstructionSchemaType = z.infer<
  typeof productInstructionSchema
>

export type ProductInstructionFormProps = Omit<
  FastFormProps<ProductInstructionSchemaType>,
  'schema' | 'children' | 'onSubmit'
> & {
  variant: 'create' | 'update'
  isDefault: boolean
  onSubmitSuccess: () => void
}

export const ProductInstructionFormLoading: FC = () => {
  return (
    <Box>
      <Skeleton height="60px" mb="14" />
      <Skeleton height="80px" mb="4" />
      <Box width="90%">
        <Skeleton height="60px" mb="4" />
        <Skeleton height="60px" mb="4" />
        <Skeleton height="60px" mb="4" />
      </Box>
    </Box>
  )
}

export const ProductInstructionForm: FC<ProductInstructionFormProps> = ({
  variant,
  isDefault,
  onSubmitSuccess,
  ...rest
}) => {
  const [create, { loading: createLoading }] = useMutationBackend(
    CreateProductInstructionDocument,
  )
  const [update, { loading: updateLoading }] = useMutationBackend(
    UpdateProductInstructionDocument,
  )

  return (
    <FastForm<ProductInstructionSchemaType>
      data-testid="configure-product-form-item"
      marginBottom="4"
      schema={productInstructionSchema}
      onSubmit={async (v) => {
        if (variant === 'create') {
          await create({
            variables: {
              isDefault,
              ...v,
            },
            refetchQueries: [{ query: ProductInstructionsListDocument }],
          })
        } else {
          if (v.id == null) {
            throw new Error('Cannot update without and id')
          }

          await update({
            // @ts-expect-error - Check for above
            variables: {
              ...v,
            },
          })
        }

        onSubmitSuccess()
      }}
      {...rest}
    >
      <Box mb="14">
        <FastFormInput<ProductInstructionSchemaType>
          name="name"
          size="lg"
          resize={'none'}
          isDisabled={isDefault}
          label="Name"
        />
      </Box>
      <FastFormInput<ProductInstructionSchemaType>
        name="headline"
        mb="4"
        size="lg"
        label="Headline"
        resize={'none'}
        placeholder={'The one-liner for your product goes here'}
      />
      <Box width="90%">
        <FastFormTextarea<ProductInstructionSchemaType>
          name="features.0"
          label="Instruction 1"
          placeholder="Primary feature"
          maxCharacterLimit={280}
          resize={'none'}
          rows={2}
          marginBottom="4"
        />
        <FastFormTextarea<ProductInstructionSchemaType>
          name="features.1"
          label="Instruction 2"
          placeholder="Secondary feature"
          maxCharacterLimit={280}
          resize={'none'}
          rows={2}
          marginBottom="4"
        />
        <FastFormTextarea<ProductInstructionSchemaType>
          name="features.2"
          label="Instruction 3"
          placeholder="Third feature"
          maxCharacterLimit={280}
          resize={'none'}
          rows={2}
          marginBottom="4"
        />
      </Box>
      <Flex justifyContent="flex-end">
        <FastFormButton
          data-testid="productInstructionFormSubmitButton"
          colorScheme="darkBlack"
          isLoading={createLoading || updateLoading}
        >
          {variant === 'create' ? 'Create' : 'Update'}
        </FastFormButton>
      </Flex>
    </FastForm>
  )
}
