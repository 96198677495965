import * as z from 'zod'

export const sparkCodeRequestSchema = z.object({
  tikTokVideoId: z.string(),
  offerPrice: z
    .string()
    .refine((v) => Number(v) >= 10, {
      message: 'Cannot be less than $10',
    })
    .refine((v) => Number(v) <= 100000, {
      message: 'Cannot be more than $100,000',
    }),
  message: z.string().max(250).optional(),
})

export type SparkCodeRequest = z.infer<typeof sparkCodeRequestSchema>
